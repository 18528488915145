import React, { useState } from 'react'
import classNames from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars'

import { Icon } from 'ui/atoms'
import { Datepicker } from 'ui/molecules'
import { RightPanelBox } from './rightPanel.style'
import { Rubrics } from '../MaterialOptions/Rubrics'
import { Tags } from '../MaterialOptions/Tags'
import { BottomControls } from '../MaterialOptions/BottomControls'
import { Authors } from '../MaterialOptions/Authors'
import { TextField } from '../MaterialOptions/TextField'

export const RightPanel = ({
  onShowPreview,
  previewDisabled,
  publishDisabled,
  lettersCount,
  currentRubric,
  setCurrentRubric,
  hot,
  setHot,
  // inGrid,
  // setInGrid,
  selectedTags,
  setSelectedTags,
  date,
  setDate,
  currentAuthors,
  setAuthor,
  setPseudonym,
  addCoAuthor,
  removeCoAuthor,
  published,
  publish,
  url,
  setDescription,
  description,
  title,
  setTitle,
  setAccessLinkOnly,
  accessLinkOnly,
  regenerate,
  changed,
  showRequiredNotification,
  type,
  update,
  validationEnabled,
  toggleValidation,
  onUniqCheck,
  uniqPercent,
}) => {
  const [active, toggle] = useState(true)

  const selectTag = (tag) => {
    if (selectedTags.find((t) => tag._id === t._id)) {
      return
    }

    setSelectedTags([...selectedTags, tag])
  }

  const deleteTag = (tag) => {
    const index = selectedTags.indexOf(tag)

    setSelectedTags([...selectedTags.slice(0, index), ...selectedTags.slice(index + 1)])
  }

  return (
    <RightPanelBox className={classNames({ active })}>
      <div className="toggle" onClick={() => toggle(!active)}>
        <Icon type="arrow" />
      </div>

      <Scrollbars autoHeight autoHeightMax="100%" renderTrackHorizontal={() => <div />}>
        <div className="attributes-wrapper">
          <div className="title">Атрибуты материала</div>

          <div className="top-buttons">
            {type === 'news' && (
              <div
                className={classNames('flash', { active: !validationEnabled })}
                onClick={() => toggleValidation(!validationEnabled)}
              >
                <Icon type="flash" />
                <div className="text">Молния</div>
              </div>
            )}

            {type === 'news' && (
            <div
              className={classNames('hot', { active: hot })}
              onClick={() => setHot(!hot)}
            >
              <Icon type="fire" />
              <div className="text">HOT news</div>
            </div>
            )}

            {/* <div
              className={classNames('add-to-grid', { active: inGrid })}
              onClick={() => setInGrid(!inGrid)}
            >
              <Icon type="grid" />
              <div className="text">Добавить в сетку</div>
            </div> */}

            <div
              className={classNames('access-by-link', { active: accessLinkOnly })}
              onClick={() => setAccessLinkOnly(!accessLinkOnly)}
            >
              <Icon type="link" />
              <div className="text">Доступ только по ссылке</div>
            </div>
          </div>

          <div className="divider" />

          <Rubrics currentRubric={currentRubric} setRubric={setCurrentRubric} />

          <div className="divider" />

          <TextField onChange={setTitle} initialDescription={title} title="Title" placeholder="до 80 символов" />

          <div className="divider" />

          <TextField onChange={setDescription} initialDescription={description} title="Описание" placeholder="180 символов" />

          <div className="divider" />

          <Tags selectedTags={selectedTags} onSelect={selectTag} onDelete={deleteTag} />

          <div className="divider" />

          <div className="sub-title">Дата публикации</div>

          <Datepicker initialStartDate={date} onSelect={setDate} withoutRange withTime />

          <div className="divider" />

          <div className="sub-title">Автор</div>

          <Authors authors={currentAuthors} setAuthor={setAuthor} setPseudonym={setPseudonym} addCoAuthor={addCoAuthor} removeCoAuthor={removeCoAuthor} />

          <BottomControls
            lettersCount={lettersCount}
            onShowPreview={onShowPreview}
            previewDisabled={previewDisabled}
            publishDisabled={publishDisabled}
            published={published}
            update={update}
            url={url}
            unpublish={publish}
            regenerate={regenerate}
            changed={changed}
            showRequiredNotification={showRequiredNotification}
            validationEnabled={validationEnabled}
            publish={publish}
            onUniqCheck={onUniqCheck}
            uniqPercent={uniqPercent}
          />
        </div>
      </Scrollbars>
    </RightPanelBox>
  )
}
