import { TOGGLE_PAGE_LAYOUT_BLUR } from '../actions/app'

const initialState = {
  pageLayoutBlured: false,
}

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_PAGE_LAYOUT_BLUR:
      return { ...state, pageLayoutBlured: action.payload }
    default:
      return state
  }
}
