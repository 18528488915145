import { combineReducers } from 'redux'

import user from './user'
import news from './news'
import app from './app'

export const rootReducer = combineReducers({
  user,
  news,
  app,
})
