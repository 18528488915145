import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { isEqual } from 'lodash'

import { NewsFilters, NewsList } from 'features/News'
import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'
import { Pagination } from 'ui/molecules'
import { NewsFromParserPreview } from 'features/Preview'

const newsFromServer = [
  {
    date: new Date(),
    category: 'Политика',
    id: 1,
    title: 'Стариков больше нет: почему мужчины не доживают до 65',
    source: 'РИА',
    text:
      'Лето 2019 года запомнится жителям столицы выборами в Мосгордуму, Лето 2019 года запомнится жителям столицы выборами в Мосгордуму…',
  },
  {
    date: new Date(),
    category: 'Политика',
    id: 2,
    title: 'Стариков больше нет: почему мужчины не доживают до 65',
    source: 'РИА',
    text:
      'Лето 2019 года запомнится жителям столицы выборами в Мосгордуму, Лето 2019 года запомнится жителям столицы выборами в Мосгордуму…',
  },
  {
    date: new Date(),
    category: 'Политика',
    id: 3,
    title: 'Стариков больше нет: почему мужчины не доживают до 65',
    source: 'РИА',
    text:
      'Лето 2019 года запомнится жителям столицы выборами в Мосгордуму, Лето 2019 года запомнится жителям столицы выборами в Мосгордуму…',
  },
  {
    date: new Date(),
    category: 'Политика',
    id: 4,
    title: 'Стариков больше нет: почему мужчины не доживают до 65',
    source: 'РИА',
    text:
      'Лето 2019 года запомнится жителям столицы выборами в Мосгордуму, Лето 2019 года запомнится жителям столицы выборами в Мосгордуму…',
  },
  {
    date: new Date(),
    category: 'Политика',
    id: 5,
    title: 'Стариков больше нет: почему мужчины не доживают до 65',
    source: 'РИА',
    text:
      'Лето 2019 года запомнится жителям столицы выборами в Мосгордуму, Лето 2019 года запомнится жителям столицы выборами в Мосгордуму…',
  },
  {
    date: new Date(),
    category: 'Политика',
    id: 6,
    title: 'Стариков больше нет: почему мужчины не доживают до 65',
    source: 'РИА',
    text:
      'Лето 2019 года запомнится жителям столицы выборами в Мосгордуму, Лето 2019 года запомнится жителям столицы выборами в Мосгордуму…',
  },
  {
    date: new Date(),
    category: 'Политика',
    id: 7,
    title: 'Стариков больше нет: почему мужчины не доживают до 65',
    source: 'РИА',
    text:
      'Лето 2019 года запомнится жителям столицы выборами в Мосгордуму, Лето 2019 года запомнится жителям столицы выборами в Мосгордуму…',
  },
  {
    date: new Date(),
    category: 'Политика',
    id: 8,
    title: 'Стариков больше нет: почему мужчины не доживают до 65',
    source: 'РИА',
    text:
      'Лето 2019 года запомнится жителям столицы выборами в Мосгордуму, Лето 2019 года запомнится жителям столицы выборами в Мосгордуму…',
  },
]

const NewsBox = styled.div`
  display: flex;

  .content {
    padding: 40px;
    max-width: 1300px;
  }

  .search-wrapper {
    width: 292px;
    height: 36px;
    border-radius: 4px;
    position: relative;
    border: solid 1px #1d1f24;

    .icon-zoom {
      position: absolute;
      top: 2px;
      opacity: 0.4;
    }

    .search-input {
      height: 100%;
      width: 100%;
      border-radius: 4px;
      background-color: #e7e8ea;
      outline: none;
      padding-left: 40px;
      padding-right: 60px;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: black;
      box-sizing: border-box;
      border: none;

      &::placeholder {
        font-family: ${FONTS.roboto};
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: black;
        opacity: 0.4;
      }
    }

    .enter {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 40px;
      height: 26px;
      border-radius: 4px;
      background-color: black;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .pagination-wrapper {
    margin-top: 39px;
    display: flex;
    justify-content: center;
  }

  .show-more {
    width: 200px;
    height: 40px;
    background: #ce914a;
    display: flex;
    align-items: center;
    color: white;
    font-family: ${FONTS.roboto};
    justify-content: center;
    cursor: pointer;
  }
`

export const News = () => {
  const [news] = useState(newsFromServer)
  const [activeLocations, setActiveLocations] = useState([2])
  const [activePeriod, setActivePeriod] = useState([1])
  const [activeSources, setActiveSource] = useState([])
  const [date, setDate] = useState(moment().format('DD MMMM YYYY'))
  const [page, setPage] = useState(1)
  const [previewShowed, togglePreview] = useState(false)

  const savedState = useRef({
    activeLocations: [...activeLocations],
    activePeriod: [...activePeriod],
    activeSources: [...activeSources],
  })

  const onSelectLocation = (id) => {
    if (activeLocations.includes(id)) {
      const index = activeLocations.indexOf(id)

      setActiveLocations([...activeLocations.slice(0, index), ...activeLocations.slice(index + 1)])

      return
    }

    setActiveLocations([...activeLocations, id])
  }

  const onSelectSource = (id) => {
    if (activeSources.includes(id)) {
      const index = activeSources.indexOf(id)

      setActiveSource([...activeSources.slice(0, index), ...activeSources.slice(index + 1)])

      return
    }

    setActiveSource([...activeSources, id])
  }

  const onCancel = () => {
    const saved = savedState.current

    setActiveLocations(saved.activeLocations)
    setActivePeriod(saved.activePeriod)
    setActiveSource(saved.activeSources)
  }

  // const showMore = () => {
  //   setNews([...news, ...newsFromServer])
  // }

  const stateChanged = !isEqual(savedState.current.activeLocations, activeLocations)
    || !isEqual(savedState.current.activePeriod, activePeriod)
    || !isEqual(savedState.current.activeSources, activeSources)

  return (
    <NewsBox>
      <NewsFilters
        activeLocations={activeLocations}
        date={date}
        setDate={setDate}
        onSelectLocation={onSelectLocation}
        activeSources={activeSources}
        onSelectSource={onSelectSource}
        activePeriod={activePeriod}
        onSelectPeriod={(id) => setActivePeriod([id])}
        onCancel={onCancel}
        stateChanged={stateChanged}
      />

      <div className="content">
        <div className="search-wrapper">
          <Icon type="zoom" />
          <input placeholder="Поиск" className="search-input" />
          <div className="enter">
            <Icon type="enter" />
          </div>
        </div>

        <NewsList news={news} onSelect={() => togglePreview(true)} />

        <div className="pagination-wrapper">
          <Pagination total={100} perPage={16} page={page} onPageChange={setPage} />

          {/* <div className="show-more" onClick={showMore}>
            Показать еще
          </div> */}
        </div>
      </div>

      {previewShowed && <NewsFromParserPreview onClose={() => togglePreview(false)} />}
    </NewsBox>
  )
}
