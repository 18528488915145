import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'
import { POSTS } from 'api/posts'

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.loading {
    justify-content: center;

    .icon {
      width: 300px;
      height: 300px;

      svg {
        width: 300px;
        height: 300px;
      }
    }
  }

  .title {
    margin-top: 100px;
    font-family: ${FONTS.roboto};
    font-size: 34px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.65;
    letter-spacing: -0.53px;
    text-align: center;
    color: black;
  }

  .options-wrapper {
    margin-top: 39px;
    width: 840px;
    display: flex;
    justify-content: space-between;

    .option {
      width: 240px;
      height: 200px;
      box-sizing: border-box;
      padding: 0 14px 17px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      border-radius: 4px;
      box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.05);
      background-color: #e7e8ea;
      font-family: ${FONTS.roboto};
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.1;
      letter-spacing: -0.31px;
      text-align: center;
      color: black;
      text-transform: uppercase;
      cursor: pointer;

      .icon {
        width: 100px;
        height: 100px;

        &.longread {
          width: 85px;
        }
      }

      .name {
        margin-top: auto;
      }
    }
  }
`

const Main = ({ history }) => {
  const [nowLoading, setLoading] = useState(null)

  const onSelectMaterial = async (type) => {
    try {
      setLoading(type)

      const response = await POSTS.create({ type })

      history.push(`/redactor/${type}/${response.data._id}`, { response })
    } catch (error) {
      console.error(error)
    }
  }

  if (nowLoading) {
    return (
      <MainBox className="loading">
        <Icon type={nowLoading === 'article' ? 'feather' : nowLoading} className="icon" />
      </MainBox>
    )
  }

  return (
    <MainBox>
      <div className="title">СОЗДАТЬ НОВЫЙ МАТЕРИАЛ</div>

      <div className="options-wrapper">
        <div className="option" onClick={() => onSelectMaterial('news')}>
          <Icon type="news" className="icon" />
          <span className="name">Новость</span>
        </div>

        <div className="option" onClick={() => onSelectMaterial('article')}>
          <Icon type="feather" className="icon" />
          <span className="name">Статья</span>
        </div>

        <div className="option" onClick={() => onSelectMaterial('longread')}>
          <Icon type="longread" className="icon longread" />
          <span className="name">Лонгрид</span>
        </div>

        {/* <div className="option" onClick={() => onSelectMaterial('live')} style={{ pointerEvents: 'none', opacity: 0.3 }}>
          <Icon type="live" className="icon" />
          <span className="name">Прямая трансляция</span>
        </div> */}
      </div>
    </MainBox>
  )
}

const enhanced = withRouter(Main)

export { enhanced as Main }
