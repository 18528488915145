import React from 'react'
import styled from 'styled-components/macro'

const IconGridBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }
  .cls-2 {
    fill: #fff;
  }
`

export const IconGrid = ({ className = '', ...props }) => (
  <IconGridBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="_24px"
      width="24"
      height="24"
      data-name="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_566" d="M0 0h24v24H0z" className="cls-1" data-name="Path 566" />
      <path
        id="Path_567"
        d="M17.222 4.778v1.778h-3.555V4.778h3.556m-8.889 0v5.333H4.778V4.778h3.555m8.889 7.111v5.333h-3.555v-5.333h3.556m-8.89 3.555v1.778H4.778v-1.778h3.555M19 3h-7.111v5.333H19zm-8.889 0H3v8.889h7.111zM19 10.111h-7.111V19H19zm-8.889 3.556H3V19h7.111z"
        className="cls-2"
        data-name="Path 567"
        transform="translate(1 1)"
      />
    </svg>
  </IconGridBox>
)
