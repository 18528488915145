import React from 'react'
import styled from 'styled-components/macro'

const IconLiveBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }

  .cls-2 {
    clip-path: url(#clip-path-live);
  }

  .cls-3,
  .cls-4 {
    fill: #3c3c3c;
  }

  .cls-3 {
    opacity: 0;
  }
`

export const IconLive = ({ className = '', ...props }) => (
  <IconLiveBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
      <defs>
        <clipPath id="clip-path-live">
          <path id="Rectangle_1223" d="M0 0h100v100H0z" className="cls-1" data-name="Rectangle 1223" />
        </clipPath>
      </defs>
      <g id="ic_live" className="cls-2">
        <path id="Rectangle_506" d="M0 0h100v100H0z" className="cls-3" data-name="Rectangle 506" />
        <g id="sharp-adjust-24px">
          <path id="Path_419" d="M0 0h100v100H0z" className="cls-1" data-name="Path 419" />
          <path
            id="Path_420"
            d="M36.165 2A34.165 34.165 0 1 0 70.33 36.165 34.165 34.165 0 0 0 36.165 2zm0 61.5A27.332 27.332 0 1 1 63.5 36.165 27.332 27.332 0 0 1 36.165 63.5zm10.25-27.332a10.25 10.25 0 1 1-10.25-10.25 10.25 10.25 0 0 1 10.25 10.247z"
            className="cls-4"
            data-name="Path 420"
            transform="translate(14.333 14.333)"
          />
        </g>
      </g>
    </svg>
  </IconLiveBox>
)
