import React from 'react'
import styled from 'styled-components/macro'

const IconWrappedEyeBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #d5d5d8;
  }
  .cls-2 {
    fill: #fff;
  }
`

export const IconWrappedEye = ({ className = '', ...props }) => (
  <IconWrappedEyeBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Ic_home" width="24" height="24" viewBox="0 0 24 24">
      <rect
        id="Rectangle_1202"
        width="24"
        height="24"
        className="cls-1"
        data-name="Rectangle 1202"
        rx="4"
      />
      <g id="Component" transform="translate(4 7)">
        <path
          id="Path_480"
          d="M9 4.5a8.6 8.6 0 0 0-8 5.2 8.6 8.6 0 0 0 8 5.2 8.6 8.6 0 0 0 8-5.2 8.6 8.6 0 0 0-8-5.2zm0 8.667A3.556 3.556 0 0 1 5.364 9.7 3.556 3.556 0 0 1 9 6.233 3.556 3.556 0 0 1 12.636 9.7 3.556 3.556 0 0 1 9 13.167zM9 7.62A2.13 2.13 0 0 0 6.818 9.7 2.13 2.13 0 0 0 9 11.78a2.13 2.13 0 0 0 2.182-2.08A2.13 2.13 0 0 0 9 7.62z"
          className="cls-2"
          data-name="Path 480"
          transform="translate(-1 -4.5)"
        />
      </g>
    </svg>
  </IconWrappedEyeBox>
)
