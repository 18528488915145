import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { useDispatch } from 'react-redux'

import { ProfileLeftPanel } from 'features/Authorization/ProfileLeftPanel'
import { FONTS } from 'constants/fonts'
import { Input, Button } from 'ui/atoms'

import { setUserInfo } from 'redux/actions/user'
import { USER } from 'api/user'

const LoginBox = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;

  @media screen and (max-width: 1649px) {
    height: calc(100vh * 1.45);
  }
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    margin-bottom: 20px;
    font-family: ${FONTS.roboto};
    font-size: 34px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.68px;
    color: black;
  }

  .form-error {
    margin-bottom: 20px;
    height: 20px;
    color: red;
    font-family: ${FONTS.roboto};
    font-size: 15px;
  }

  .input {
    margin-bottom: 31px;
    width: 360px;
  }

  .welcome {
    font-family: ${FONTS.roboto};
    font-size: 64px;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: 1.28px;
    text-align: center;
    color: black;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`

const Login = ({ history }) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState('')

  const logIn = async () => {
    try {
      const response = await USER.login({ email, password })

      if (response.res !== 'success') {
        setFormError('Неверный логин или пароль')

        return
      }

      setLoggedIn(true)
      const { data } = response
      dispatch(
        setUserInfo({
          name: data.name,
          id: data._id,
          token: data.token,
          role: 'admin',
          email: data.email,
        }),
      )

      setTimeout(() => history.replace('/redactor'), 1000)
    } catch (error) {
      setFormError('Неверный логин или пароль')
      console.error(error)
    }
  }

  return (
    <LoginBox>
      <ProfileLeftPanel />

      <Content>
        {!loggedIn ? (
          <>
            <div className="title">Авторизируйтесь</div>

            <div className="form-error">{formError}</div>

            <Input label="E-mail" className="input" value={email} onChange={(e) => setEmail(e.target.value)} />

            <Input
              label="Пароль"
              className="input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  logIn()
                }
              }}
            />

            <Button text="Войти" onClick={logIn} />
          </>
        ) : (
          <div className="welcome">Добро пожаловать!</div>
        )}
      </Content>
    </LoginBox>
  )
}

const enhanced = compose(withRouter(Login))

export { enhanced as Login }
