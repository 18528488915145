import React from 'react'
import styled from 'styled-components'

import { FONTS } from 'constants/fonts'

const NotSelectedBox = styled.span`
  margin-left: 6px;
  font-family: ${FONTS.roboto};
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.22px;
  color: #ff7878;
`

export const NotSelected = ({ text }) => (
  <NotSelectedBox>
    (
    {text}
    )
  </NotSelectedBox>
)
