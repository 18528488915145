import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'
import arrowImg from 'assets/images/search-arrow-top.png'
import { POSTS } from 'api/posts'
import { Accordion } from './Accordion'
import { FoundNews } from './FoundNews'
// import { Photo } from './Photo'

const SearchBox = styled.div`
  margin-bottom: -1px;
  width: 330px;
  position: relative;
  height: 30px;
  padding: 35px 30px;
  border-bottom: 1px solid #d5d5d8;
  position: relative;
  cursor: pointer;

  .icon-zoom {
    position: absolute;
    cursor: pointer;
  }

  .search-input {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    background-color: #e7e8ea;
    outline: none;
    padding-left: 40px;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: black;
    box-sizing: border-box;
    border: none;

    &::placeholder {
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }
  }
`

const EmptySearchBox = styled.div`
  width: 100%;
  height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .text {
    opacity: 0.3;
    font-family: Roboto;
    font-size: 34px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: 0.68px;
    text-align: center;
    color: #ffffff;
  }

  .arrow-top {
    position: absolute;
    left: 50px;
    top: 24px;
    height: 43%;
  }
`

export const Search = ({ onClose }) => {
  const [value, setValue] = useState('')
  const [news, setNews] = useState([])
  const [searched, setSearchStatus] = useState(false)

  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const onSearch = async () => {
    if (!value) {
      setSearchStatus(false)

      return
    }

    const response = await POSTS.search({ count: 24, page: 1, search: value })

    setNews(response.data)
    setSearchStatus(true)
  }

  return (
    <>
      <div className="toggle" onClick={onClose}>
        <Icon type="arrow" />
      </div>
      <SearchBox>
        <Icon type="zoom" />
        <input
          placeholder="Поиск..."
          className="search-input"
          ref={inputRef}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSearch(value)
            }
          }}
        />
      </SearchBox>

      {searched && (
        <>
          <Accordion title="Статьи и новости">
            <FoundNews news={news} />
          </Accordion>

          {/* <Accordion title="Фотографии">
            <Photo />
          </Accordion> */}
        </>
      )}

      {!searched && (
        <EmptySearchBox>
          <img src={arrowImg} alt="" className="arrow-top" />
          <div className="text">Введите запрос для поиска материалов</div>
        </EmptySearchBox>
      )}
    </>
  )
}
