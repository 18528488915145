import React from 'react'
import styled from 'styled-components/macro'

const IconRightSideBox = styled.div`
  height: 22px;
  width: 22px;
  display: flex;

  .cls-1 {
    fill: white;
  }
`

export const IconRightSide = ({ className = '', ...props }) => (
  <IconRightSideBox className={className} {...props}>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.334 1.83398H20.1673V20.1673H18.334V1.83398ZM1.83398 9.16732H16.5007V6.41732H1.83398V9.16732ZM7.33398 15.584H16.5007V12.834H7.33398V15.584Z"
        className="cls-1"
      />
    </svg>
  </IconRightSideBox>
)
