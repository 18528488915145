import React from 'react'
import styled from 'styled-components/macro'

const IconNewsBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1,
  .cls-2 {
    fill: none;
  }
  .cls-2 {
    stroke: #3c3c3c;
    stroke-width: 3px;
  }
  .cls-3 {
    fill: #3c3c3c;
  }
  .cls-4 {
    stroke: none;
  }
`

export const IconNews = ({ className = '', ...props }) => (
  <IconNewsBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
      <g id="Group_488" data-name="Group 488" transform="translate(-670 -184)">
        <path
          id="Rectangle_1227"
          d="M0 0h100v100H0z"
          className="cls-1"
          data-name="Rectangle 1227"
          transform="translate(670 184)"
        />
        <g id="Group_487" data-name="Group 487" transform="translate(4)">
          <g
            id="Rectangle_1225"
            className="cls-2"
            data-name="Rectangle 1225"
            transform="translate(671 201)"
          >
            <rect width="90" height="70" className="cls-4" rx="8" />
            <rect width="87" height="67" x="1.5" y="1.5" className="cls-1" rx="6.5" />
          </g>
          <rect
            id="Rectangle_1226"
            width="34"
            height="26"
            className="cls-3"
            data-name="Rectangle 1226"
            rx="4"
            transform="translate(678 208)"
          />
          <rect
            id="Rectangle_1228"
            width="26"
            height="3"
            className="cls-3"
            data-name="Rectangle 1228"
            rx="1.5"
            transform="translate(717 212)"
          />
          <rect
            id="Rectangle_1229"
            width="34"
            height="3"
            className="cls-3"
            data-name="Rectangle 1229"
            rx="1.5"
            transform="translate(717 219)"
          />
          <rect
            id="Rectangle_1230"
            width="13"
            height="3"
            className="cls-3"
            data-name="Rectangle 1230"
            rx="1.5"
            transform="translate(717 226)"
          />
          <rect
            id="Rectangle_1231"
            width="55"
            height="2"
            className="cls-3"
            data-name="Rectangle 1231"
            rx="1"
            transform="translate(693 244)"
          />
          <rect
            id="Rectangle_1232"
            width="66"
            height="2"
            className="cls-3"
            data-name="Rectangle 1232"
            rx="1"
            transform="translate(682 250)"
          />
          <rect
            id="Rectangle_1233"
            width="25"
            height="2"
            className="cls-3"
            data-name="Rectangle 1233"
            rx="1"
            transform="translate(682 256)"
          />
        </g>
      </g>
    </svg>
  </IconNewsBox>
)
