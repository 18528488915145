import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components/macro'
import classNames from 'classnames'

import { Icon, Radio } from 'ui/atoms'
import { FONTS } from 'constants/fonts'
import { useOnClickOutside } from 'hooks'

const EditorToolbarBox = styled.div`
  position: absolute;
  top: -37px;
  left: -1px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  background-color: black;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
  padding: 0 1px 0 6px;
  z-index: 2;

  .link-dropdown {
    cursor: initial;
    position: absolute;
    top: 41px;
    left: -15px;
    z-index: 4;
    width: 324px;
    height: 205px;
    border-radius: 10px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding: 12px;
    box-sizing: border-box;

    .input-wrapper {
      position: relative;
      width: 100%;

      input {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: solid 2px #ce914a;
        background-color: #ffffff;
        padding-left: 34px;
        box-sizing: border-box;
        outline: none;
        font-family: ${FONTS.roboto};
        font-size: 16px;
        color: black;
      }

      .icon-link {
        position: absolute;
        top: 8px;
        left: 3px;
        transform: rotate(90deg);

        .cls-2 {
          fill: #ce914a;
        }
      }
    }

    .option-row {
      margin-top: 18px;
      display: flex;
      align-items: center;
      font-family: ${FONTS.roboto};
      font-size: 14px;
      color: black;
      cursor: pointer;

      &.last {
        margin-top: 10px;
      }
    }

    .control-buttons {
      margin-top: 22px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      justify-content: space-between;

      .button {
        width: 146px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 10px;
        font-size: 18px;
        font-family: ${FONTS.roboto};

        &.cancel {
          background-color: #484b50;

          .icon-cross {
            margin-right: 5px;

            .cls-2 {
              stroke: white;
            }
          }
        }

        &.ok {
          background-color: #c3b29b;
          color: black;

          .icon-check {
            margin-right: 5px;

            .cls-2 {
              fill: #1d1f24;
            }
          }
        }
      }
    }
  }

  .option {
    margin-right: 5px;
    width: 28px;
    height: 28px;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.active {
      border-color: white;
    }

    .icon-question {
      height: 18px;
      width: 18px;
    }
  }

  .block-type-icon {
    margin-right: 5px;
    width: 28px;
    height: 28px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    ~ .option:nth-child(2) {
      margin-left: 8px;
    }

    .icon-h2 {
      .cls-2 {
        fill: black;
      }
    }

    .icon-text,
    .icon-quote,
    .icon-main-in-text,
    .icon-previous {
      .cls-1 {
        fill: black;
      }
    }

    .icon-shuffle {
      .cls-1 {
        fill: black;
        stroke: black;
      }
    }

    .icon-question {
      width: 18px;
      height: 18px;

      .cls-1 {
        fill: black;
      }
    }
  }
`

const options = [
  {
    label: 'B',
    type: 'bold',
    style: 'BOLD',
  },
  {
    label: 'I',
    type: 'italic',
    style: 'ITALIC',
  },
]

const LinkDropdown = ({ onDone, onClose }) => {
  const [blank, setBlank] = useState(true)
  const [nofollow, setNofollow] = useState(true)
  const [link, setLink] = useState('')
  const dropdownRef = useRef()
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  useOnClickOutside(dropdownRef, onClose)

  function _onDone(e) {
    let linkType = 'LINK'

    if (blank) {
      linkType += '-BLANK'
    }

    if (nofollow) {
      linkType += '-NOFOLLOW'
    }

    if (!nofollow && !blank) {
      linkType = 'LINK-CLEAR'
    }

    e.stopPropagation()
    onDone(link, linkType)
    onClose()
  }

  function _onClose(e) {
    e.stopPropagation()
    onClose()
  }

  return (
    <div className="link-dropdown" ref={dropdownRef}>
      <div className="input-wrapper">
        <Icon type="link" />
        <input value={link} ref={inputRef} onChange={(e) => setLink(e.target.value)} />
      </div>

      <div className="option-row">
        <Radio single checked={blank} onChange={() => setBlank(!blank)} />
        <span onClick={() => setBlank(!blank)}>Открывать на новой странице</span>
      </div>

      <div className="option-row last">
        <Radio single checked={nofollow} onChange={() => setNofollow(!nofollow)} />
        <span onClick={() => setNofollow(!nofollow)}>Без индексации (rel=nofollow)</span>
      </div>

      <div className="control-buttons">
        <div className="button cancel" onClick={_onClose}>
          <Icon type="cross" />
          Отмена
        </div>
        <div className="button ok" onClick={_onDone}>
          <Icon type="check" />
          Готово
        </div>
      </div>
    </div>
  )
}

const defaultPosition = { top: -37, left: -1 }

export const EditorToolbar = ({
  editorState,
  onToggle,
  blockTypeIcon = 'text',
  availableOptions = ['bold', 'italic'],
  setLink,
  containerRef,
  customContent = null,
  secondToolbarContent = null,
  // checkLink,
}) => {
  const [linkDropdownOpened, toggleLinkDropdown] = useState(false)
  const [position, setPosition] = useState(defaultPosition)

  useEffect(() => {
    const listener = () => {
      if (!containerRef || !containerRef.current) {
        return
      }

      const { y, x, height } = containerRef.current.getBoundingClientRect()

      if (y <= 0 && y * -1 + 100 < height) {
        setPosition({ position: 'fixed', top: 0, left: x })
      } else {
        setPosition(defaultPosition)
      }
    }

    document.addEventListener('scroll', listener)
  }, [])

  if (!editorState) {
    return (
      <EditorToolbarBox style={position}>
        <div className="block-type-icon">
          <Icon type={blockTypeIcon} />
        </div>
      </EditorToolbarBox>
    )
  }

  const currentStyle = editorState.getCurrentInlineStyle()

  return (
    <EditorToolbarBox style={position}>
      <div className="block-type-icon">
        <Icon type={blockTypeIcon} />
      </div>

      {options.map((option) => (availableOptions.includes(option.type) ? (
        <div
          className={classNames('option', { active: currentStyle.has(option.style) })}
          key={option.label}
          onMouseDown={(e) => {
            e.preventDefault()

            onToggle(option.style)
          }}
        >
          <Icon type={option.type} />
        </div>
      ) : null))}

      {availableOptions.includes('link') && (
        <div
          className="option"
          onMouseDown={(e) => {
            e.preventDefault()

            // checkLink()
            toggleLinkDropdown(true)
          }}
        >
          {linkDropdownOpened && (
          <LinkDropdown
            onDone={setLink}
            onClose={() => toggleLinkDropdown(false)}
          />
          )}

          <Icon type="link" />
        </div>
      )}

      {customContent}
    </EditorToolbarBox>
  )
}
