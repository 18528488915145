import React from 'react'
import styled from 'styled-components'

const ArticlesListBox = styled.div`
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;

  .article-placeholder {
    margin-right: 50px;
    margin-bottom: 20px;
    width: 586px;
    height: 80px;
    border-radius: 4px;
    border: solid 1px transparent;
    background-color: white;
    padding: 16px;
    box-sizing: border-box;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loaderData;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
    background-size: 800px 50px;
    position: relative;

    &:nth-child(2n) {
      margin-right: 0;
    }

    @keyframes loaderData {
      0% {
        background-position: -400px 0;
      }

      100% {
        background-position: 400px 0;
      }
    }
  }
`

const articles = Array.from({ length: 12 })

export const ArticlesListPlaceholder = () => (
  <ArticlesListBox>
    {articles.map((_, index) => <div className="article-placeholder" key={index} />)}
  </ArticlesListBox>
)
