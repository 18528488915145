import React from 'react'
import styled from 'styled-components/macro'

const IconEmbedLinkBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1{
    fill:#fff;
    opacity:0
  }

  .cls-2{
    fill:none;
    stroke:#fff;
    stroke-width:2px
  }
`

export const IconEmbedLink = ({ className = '', ...props }) => (
  <IconEmbedLinkBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" id="embed_link" width="24" height="24" data-name="embed link" viewBox="0 0 24 24">
      <rect id="Rectangle_1289" width="24" height="24" className="cls-1" data-name="Rectangle 1289" rx="4" />
      <g id="embed" transform="translate(-.551 .212)">
        <path id="Path_595" d="M.707.707L0 7l6.293-.707" className="cls-2" data-name="Path 595" transform="rotate(45 -4.364 15.243)" />
        <path id="Path_598" d="M.707 6.293L0 0l6.293.707" className="cls-2" data-name="Path 598" transform="rotate(135 7.686 10.506)" />
        <path id="Path_600" d="M.356-2.341l-2.674 15" className="cls-2" data-name="Path 600" transform="translate(13.551 7.427)" />
      </g>
    </svg>

  </IconEmbedLinkBox>
)
