import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { Datetime } from 'ui/atoms'
import { FONTS } from 'constants/fonts'
import { useOnClickOutside } from 'hooks'

const PublicationDateBox = styled.div`
  margin-top: 12px;

  .date {
    height: 24px;
    border-radius: 12px;
    background-color: #484b50;
    padding: 0 8px;
    display: flex;
    align-items: center;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: -0.25px;
    text-align: left;
    color: white;
    cursor: pointer;
    justify-content: center;
  }
`

export const PublicationDate = ({
  date, setDate, onlyFuture, format, timeFormat = true,
}) => {
  const [datepickerOpened, toggleDatepicker] = useState(false)

  const datepickerRef = useRef()

  useOnClickOutside(datepickerRef, () => toggleDatepicker(false))

  const onChangeDate = (date) => {
    setDate(date)
  }

  return (
    <PublicationDateBox>
      <div className="date" onClick={() => toggleDatepicker(!datepickerOpened)}>
        {typeof date === 'string' ? date : moment(date).format(format)}
      </div>

      <Datetime
        viewMode="days"
        value={date}
        onChange={onChangeDate}
        opened={datepickerOpened}
        setRef={datepickerRef}
        timeFormat={timeFormat}
        onlyFuture={onlyFuture}
      />
    </PublicationDateBox>
  )
}
