import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'

const ConfirmOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;

  @media screen and (max-width: 1649px) {
    height: calc(100vh * 1.45);
    width: calc(100vw * 1.45);
  }
`

const ConfirmBox = styled.div`
  width: 395px;
  height: 183px;
  border-radius: 10px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  position: relative;
  padding: 51px 40px 40px 40px;
  box-sizing: border-box;
  

  .icon-cross {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;

    .cls-2 {
      stroke: black;
    }
  }

  .question {
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: black;
  }

  .buttons {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;

    .cancel {
      width: 151px;
      height: 36px;
      border-radius: 21px;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: ${FONTS.roboto};
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: black;
      cursor: pointer;
    }

    .ok {
      width: 151px;
      height: 36px;
      border-radius: 14px;
      background-color: #de4e4e;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: ${FONTS.roboto};
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff;
      cursor: pointer;
    }
  }
`

export const Confirm = ({
  open = false,
  className = '',
  question,
  okText,
  cancelText,
  onOk,
  onCancel,
}) => {
  if (!open) {
    return null
  }

  return ReactDOM.createPortal(
    <ConfirmOverlay>
      <ConfirmBox className={className}>
        <Icon type="cross" onClick={onCancel} />

        <div className="question">{question}</div>

        <div className="buttons">
          <div className="cancel" onClick={onCancel}>
            {cancelText}
          </div>
          <div className="ok" onClick={onOk}>
            {okText}
          </div>
        </div>
      </ConfirmBox>
    </ConfirmOverlay>,
    document.querySelector('body'),
  )
}
