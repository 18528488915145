import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'

const OptionsBox = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;

  .option {
    margin-right: 12px;
    margin-bottom: 12px;
    height: 24px;
    border-radius: 12px;
    padding: 0 8px;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: -0.25px;
    text-align: left;
    color: white;
    cursor: pointer;
    background-color: #484b50;

    &.active {
      background-color: #ce914a;
    }
  }
`

export const Options = ({ activeOptions = [], options = [], onSelect }) => (
  <OptionsBox>
    {options.map((option) => (
      <div
        className={classNames('option', { active: activeOptions.includes(option.id) })}
        key={option.id}
        onClick={() => onSelect(option.id)}
      >
        {option.name}
      </div>
    ))}
  </OptionsBox>
)
