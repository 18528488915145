import React, {
  useState, useCallback, useEffect, useRef,
} from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'

import dropzonePreviewImg from 'assets/images/dropzone-preview.png'
import { NotSelected } from 'ui/atoms'
import { POSTS } from 'api/posts'
import { withEditorContainer, Save } from './EditorContainer'
import { CropModal } from './CropModal'
import { PhotoSource } from './PhotoSource'

const MainPhotoEditorBox = styled.div`
  width: 100%;
  height: 100%;

  &.active {
    .dropzone {
      border-radius: 8px;
      border: dashed 1px #897b6a;
      border-top-right-radius: 0;
    }
  }

  .dropzone {
    width: 100%;
    height: 441px;
    background-color: #ebe2d5;
    box-sizing: border-box;
    outline: none;
    padding-top: 57px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 8px;

    &.hidden {
      display: none;
    }

    input {
      display: none;
    }

    .title {
      font-family: ${FONTS.lato};
      font-size: 22px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.45;
      letter-spacing: -0.11px;
      text-align: center;
      color: black;
      opacity: 0.45;
    }

    .dropzone-preview {
      margin-top: 50px;
      width: 174px;
      height: 174px;
    }

    .upload {
      margin-top: 20px;
      width: 221px;
      height: 36px;
      border-radius: 4px;
      background-color: #d5d5d8;
      font-family: ${FONTS.roboto};
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .photo-source {
    margin-top: 12px;
    margin-left: 10px;
    opacity: 0.4;
    font-family: ${FONTS.lato};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: black;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
  }

  .photo-source-input {
    box-sizing: border-box;
    margin-top: 8px;
    display: block;
    width: 300px;
    height: 36px;
    outline: none;
    border-radius: 4px;
    border: solid 2px #d5d5d8;
    background-color: white;
    font-family: ${FONTS.lato};
    font-size: 14px;

    &.second {
      margin-left: 8px;
      width: 200px;
    }

    &::placeholder {
      opacity: 0.2;
      font-family: ${FONTS.lato};
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }
  }

  .photo {
    margin-top: 0;
    border-top-left-radius: 8px;
    width: 100%;
    height: 441px;
    object-fit: cover;
    display: block;
  }

  .change-photo {
    position: absolute;
    top: 375px;
    left: 223px;
    width: 214px;
    height: 36px;
    border-radius: 4px;
    background-color: #939395;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: ${FONTS.roboto};
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    &.crop {
      left: 460px;
    }
  }
`

const StyledPhotoSource = styled(PhotoSource)`
  margin-top: 8px;
  margin-left: 20px;
  width: 300px;
`

const MainPhotoEditor = ({
  active, onSave, deactivate, initialState, withoutCrop,
}) => {
  const [source, setSource] = useState(initialState?.source || '')
  const [alt, setAlt] = useState(initialState?.alt || '')
  const [photoUrl, setPhotoUrl] = useState(initialState?.photoUrl || '')
  const [photoUrls, setPhotoUrls] = useState({
    small: initialState?.photoUrl || initialState?.photoUrls?.small || '',
    medium: initialState?.photoUrl || initialState?.photoUrls?.medium || '',
    large: initialState?.photoUrl || initialState?.photoUrls?.large || '',
    origin: initialState?.photoUrl || initialState?.photoUrls?.origin || '',
  })

  const [cropApproved, approveCrop] = useState(!!initialState?.photoUrl || !!initialState?.photoUrls?.origin)

  const fileType = useRef('')
  const file = useRef(null)

  useEffect(() => {
    const body = document.querySelector('body')

    if (!!photoUrls.large && !cropApproved && !withoutCrop) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = 'auto'
    }
  }, [cropApproved, photoUrls, withoutCrop])

  const onDropPhoto = useCallback(async (acceptedFiles) => {
    try {
      fileType.current = acceptedFiles[0].type

      if (withoutCrop) {
        const response = await POSTS.uploadImage({ image: { blob: acceptedFiles[0], fileName: 'upload.jpeg' } })

        setPhotoUrls({ ...photoUrls, origin: response.data.url, large: response.data.url })
        setPhotoUrl(response.data.url)
      } else {
        // eslint-disable-next-line
        file.current = acceptedFiles[0]
        setPhotoUrls({ ...photoUrls, origin: URL.createObjectURL(acceptedFiles[0]) })
      }
    } catch (error) {
      console.error(error)
    }
  }, [withoutCrop])

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropPhoto, accept: 'image/jpeg, image/png, image/jpg' })

  // const onDropPhotoFromSite = (e) => {
  //   if (!active) {
  //     return
  //   }

  //   const data = e.dataTransfer.getData('text/html')

  //   if (!data.includes(DRAGGABLE_IMAGE_CLASS)) {
  //     return
  //   }

  //   const url = data.split('src="')[1].split('"')[0]

  //   setPhotoUrls(url)
  // }

  async function onStartCropChange() {
    const response = await window.fetch(photoUrls.origin)
    const blob = await response.blob()

    file.current = blob

    setPhotoUrls({ ...photoUrls, origin: URL.createObjectURL(blob) })
    approveCrop(false)
  }

  const _onSave = () => {
    const html = photoUrls.origin
      ? `<img className="image-full-width" src="${photoUrls.large}"><div className="image-description">${source}</div>`
      : ''

    if (photoUrl) {
      onSave({ html, params: { photoUrls, photoUrl, source, alt } })
    } else {
      onSave({ html, params: { photoUrls, source, alt } })
    }
    deactivate()
  }

  const handleSourceBlur = () => {
    if (!alt) {
      setAlt(source)
    }
  }

  const removeImg = () => {
    setPhotoUrls({
      small: '', medium: '', large: '', origin: '', url: '',
    })
    approveCrop(false)
    if (photoUrl) {
      setPhotoUrl('')
    }
  }

  const saveCropeedImage = async (data) => {
    const response = await POSTS.uploadImage({ image: { blob: file.current, fileName: 'upload.jpeg' } })
    const response1 = await POSTS.uploadImage({ image: { blob: data[0].blob, fileName: 'upload0.jpeg' } })
    const response2 = await POSTS.uploadImage({ image: { blob: data[1].blob, fileName: 'upload1.jpeg' } })
    const response3 = await POSTS.uploadImage({ image: { blob: data[2].blob, fileName: 'upload2.jpeg' } })

    setPhotoUrls({
      origin: response.data.url, small: response3.data.url, medium: response2.data.url, large: response1.data.url, url: response1.data.url,
    })

    approveCrop(true)
  }

  return (
    <>
      <MainPhotoEditorBox className={classNames({ active })}>
        <div className={classNames('dropzone', { hidden: !!photoUrls.origin })} {...(active ? getRootProps() : {})}>
          <input {...getInputProps()} />

          <div className="title">Перетащите фото из панели поиска или загрузите с компьютера</div>
          <img src={dropzonePreviewImg} alt="" className="dropzone-preview" />
          <div className="upload">Загрузить с компьютера</div>
        </div>

        {!!photoUrls.origin && <img src={photoUrls.large} className="photo" alt="" />}
        {!!photoUrls.origin && active && (
          <div className="change-photo" onClick={removeImg}>
            Удалить изображение
          </div>
        )}

        {!!photoUrls.origin && active && (
        <div className="change-photo crop" onClick={onStartCropChange}>
          Изменить кроп
        </div>
        )}

        {active ? (
          <>
            <div className="input-wrapper">
              <input
                className="photo-source-input"
                placeholder="Источник изображения"
                onChange={(e) => setSource(e.target.value)}
                value={source}
                onBlur={handleSourceBlur}
              />

              <input
                className="photo-source-input"
                placeholder="Название изображения"
                onChange={(e) => setAlt(e.target.value)}
                value={alt}
              />

              {(!alt && source) && <NotSelected text="не указано название" />}
              {(alt && !source) && <NotSelected text="не указан источник" />}
              {(!alt && !source) && <NotSelected text="не указаны название и источник" />}
            </div>
          </>
        ) : (
          <div className="photo-source">{source || 'Источник изображения'}</div>
        )}
      </MainPhotoEditorBox>

      <Save onSave={_onSave} active={active} />

      {!!photoUrls.origin && !cropApproved && !withoutCrop && (
        <CropModal
          imageSrc={photoUrls.origin}
          onSave={saveCropeedImage}
          onClose={() => setPhotoUrls({
            small: '', medium: '', large: '', origin: '', url: '',
          })}
        />
      )}
    </>
  )
}

const enhanced = withEditorContainer(MainPhotoEditor)

export { enhanced as MainPhotoEditor }
