import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Editor, EditorState, RichUtils } from 'draft-js'
import 'draft-js/dist/Draft.css'
import classNames from 'classnames'

import { NotSelected } from 'ui/atoms'
import { Icon } from 'ui/atoms/Icon/index'
import questionImg from 'assets/images/question-in-circle.png'
import { EditorToolbar } from './EditorToolbar'
import { withEditorContainer, Save } from './EditorContainer'
import { convertToHtml, convertFromHtml } from './convert'

const QuestionEditor = ({
  placeholder, active, editorRef, onSave, deactivate, initialState,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromHtml(initialState.html)))

  const onEditorChange = (state) => {
    setEditorState(state)
  }

  const toggleInlineStyle = (inlineStyle) => {
    onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  const _onSave = () => {
    const html = convertToHtml(editorState.getCurrentContent())

    onSave({ html, params: { text: editorState.getCurrentContent().getPlainText('') } })

    deactivate()
  }

  return (
    <>
      <QuestionEditorBox className={classNames({ active })}>
        <Editor
          editorState={editorState}
          onChange={onEditorChange}
          readOnly={!active}
          placeholder={!active ? placeholder : null}
          ref={editorRef}
          spellCheck
        />

        {!active && <QuestionImg src={questionImg} />}

        {active && (
          <EditorToolbar
            editorState={editorState}
            onToggle={toggleInlineStyle}
            availableOptions={[]}
            blockTypeIcon="question"
          />
        )}
      </QuestionEditorBox>

      <Save onSave={_onSave} active={active} />
    </>
  )
}

const QuestionEditorBox = styled.div`
  background: #EBE2D5;
  min-height: 100px;
  padding: 0 0 0 100px;
  display: flex;
  border-radius: 4px;
  position: relative;

  .DraftEditor-root {
    background: white;
    border-radius: 4px;
    padding: 15px;
  }

  &.active {
    padding: 0;
  }
`

const QuestionImg = styled.img`
  position: absolute;
  left: 19px;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
`

const enhanced = withEditorContainer(QuestionEditor, 'question')

export { enhanced as QuestionEditor }
