import React from 'react'
import styled from 'styled-components/macro'

const IconHomeBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }
  .cls-2 {
    fill: #fff;
  }
`

export const IconHome = ({ className = '', ...props }) => (
  <IconHomeBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="_24px_3_"
      width="24"
      height="24"
      data-name="24px (3)"
      viewBox="0 0 24 24"
    >
      <path id="Path_562" d="M0 0h24v24H0z" className="cls-1" data-name="Path 562" />
      <path
        id="Path_563"
        d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3z"
        className="cls-2"
        data-name="Path 563"
      />
    </svg>
  </IconHomeBox>
)
