import React from 'react'
import styled from 'styled-components'
import ReactDOM from 'react-dom'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'

import editImg from 'assets/images/icon-edit.png'
// import classNames from 'classnames'

const article = {
  date: new Date(),
  category: 'Политика',
  id: 3,
  title: 'Стариков больше нет: почему мужчины не доживают до 65',
  source: 'РИА',
  text: `
  На 100 старушек в России приходится 45 старичков. Объясняем, почему это не плохо, а очень плохо. Начиная читать этот текст, вы тем самым подтверждаете, что не придерживаетесь феминистических взглядов.
Росстат опубликовал прогноз гендерного или, если угодно, полового соотношения россиян до 2036 года. Основной посыл исследования, разошедшийся по СМИ, — к этому времени лишь на Чукотке и Камчатке будет больше мужчин, чем женщин. Но ничего нового в этом нет, сейчас примерно такая же ситуация. И понятно, что в края с суровым климатом, где бо́льшая часть населения — временные рабочие, едут в основном мужчины, что и создает соответствующую статистику.

То есть ничего особенного не произойдет.

 Даже в Советском Союзе ходила шутка о том, что половина детей растет в однополых семьях — мама + бабушка. Сейчас на тысячу мужчин в России приходится 1154 женщины, так что пресловутый прогноз как раз оптимистичный — за 17 лет соотношение планируется довести до 1:1128. Надеемся, не ростом женской смертности, хотя с учетом нынешней рождаемости это наиболее реалистичный путь.
Получается, что нет смысла бить тревогу? Демографии ведь все равно — один бык может осеменить целое стадо коров, а в человеческом обществе матерям-одиночкам еще и отдельная государственная помощь полагается, не говоря уж о материнском капитале за второго ребенка.
Электорат будущего
Генетики знают, что природа экспериментирует на мужчинах как менее ценном с точки зрения демографии материале. Среди мужчин больше очень умных и очень глупых, гораздо сильнее распространены генетические заболевания и гениальность. Но все же повышенная смертность на Руси — явление куда более социальное, нежели генетическое. У нас одно из худших в мире соотношений мужчин и женщин после 65 лет — по разным оценкам, 0,44 — 0,46, то есть на 100 женщин остается менее 50 мужчин. Рядом с нами только Белоруссия, Эстония, Латвия, Украина и несколько старушек с архипелага Палау; чуть выше еще один образец для подражания, КНДР.
Посмотрим Всемирную книгу фактов ЦРУ — местами упоительное чтение. В возрастной категории старше 65 лет находится 14,66%, то есть одна седьмая населения России. Это 6,56 млн мужчин и 14,27 млн женщин — тут вам уже не «девять ребят на десять девчонок», тут своеобразный гендерный геноцид.
Мальчиков во всех без исключения странах мира стабильно рождается больше, чем девочек, и в молодости это соотношение сохраняется. Так, в возрасте до 24 лет у нас 19,4 млн мужчин и лишь 18,4 млн женщин. «Лишний» миллион — это много в масштабах нашей не слишком густонаселенной страны.
А учитывая, что это возраст максимальной фертильности, «брачных игр», женщины не слишком дальновидно ощущают себя в выигрышном положении, становятся «принцессами» и очень придирчиво выбирают себе партнеров.
Но далее — горькое разочарование: мужчины умирают один за другим, а постаревшие «принцессы» оказываются не готовы к суровой реальности. К 54 годам женщины постепенно обходят числом мужчин, а после достижения дореформенного пенсионного возраста, кажется, только и делают, что бегают на похороны ровесников. Одинокие, озлобленные, ненавидящие весь мир, имеющие большинство на выборах — это современные русские женщины за пятьдесят. И в единый день голосования 8 сентября 2019 года определять будущее своих городов и весей будут именно они. А им не надо, чтобы было хорошо в будущем, они хотят, чтобы всем было плохо здесь и сейчас.
Зачем нужны старики
А как там у врагов и, не побоимся этого слова, друзей? США — 0,77 мужчин в возрасте 65+ на одну ровесницу, Китай — 0,92 (но это последствия массовых убийств новорожденных девочек во время действия программы «одна семья — один ребенок»), Евросоюз в целом — 0,74, Великобритания — 0,80, Иран — 0,89, Объединенные Арабские Эмираты — 1,77 (тоже особенности национальной культуры). Об этом сообщает Рамблер. Далее: https://woman.rambler.ru/love/42721394/?utm_content=rwoman&utm_medium=read_more&utm_source=copylink
  `,
}

const NewsFromParserPreviewBox = styled.div`
  width: 1016px;
  padding: 29px 28px 28px 58px;
  box-sizing: border-box;
  position: fixed;
  right: 198px;
  top: 25px;
  height: calc(100vh - 50px);
  border-radius: 8px;
  -webkit-backdrop-filter: blur(22.8px);
  backdrop-filter: blur(22.8px);
  box-shadow: 0 0 56px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 2;

  .icon-cross {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 30px;
    height: 30px;
    background: #897b6a;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .cls-2 {
      stroke: white;
    }
  }

  .scrollbar-content {
    padding-right: 28px;
  }

  .top-row {
    margin-bottom: 20px;
    font-family: ${FONTS.lato};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #606265;
    display: flex;
    align-items: center;

    .source {
      font-weight: bold;
    }

    .controls {
      margin-left: 24px;
      display: flex;
      align-items: center;

      .take {
        width: 109px;
        height: 24px;
        border-radius: 2px;
        background-color: #efefef;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.78;
        font-family: ${FONTS.roboto};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: -0.25px;
        text-align: left;
        color: #b2b2b2;
        cursor: pointer;

        &:hover {
          color: #606265;
        }

        .icon-edit {
          margin-right: 8px;
          width: 22px;
          height: 12px;
        }
      }

      .icon-open {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  .title {
    margin-top: 16px;
    font-family: ${FONTS.roboto};
    font-size: 42px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: 0.71px;
    text-align: left;
    color: black;
  }

  .text {
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    word-wrap: break-word;
    white-space: pre-line;
  }
`

export const NewsFromParserPreview = ({ onClose }) => ReactDOM.createPortal(
  <NewsFromParserPreviewBox>
    <Icon type="cross" onClick={onClose} />

    <div className="top-row">
      {moment(article.date).format('DD MMMM YY - HH:mm')}
      {' '}
      -
      <span className="source">{article.source}</span>
      <div className="controls">
        <div className="take">
          <img src={editImg} alt="" className="icon-edit" />
          {' '}
          В работу
        </div>

        {/* <Icon type="open" /> */}
      </div>
    </div>

    <Scrollbars
      autoHeight
      autoHeightMax="calc(100vh - 160px)"
      renderTrackHorizontal={() => <div />}
      hideTracksWhenNotNeeded
      autoHide
    >
      <div className="scrollbar-content">
        <div className="title">{article.title}</div>

        <pre className="text">{article.text}</pre>
      </div>
    </Scrollbars>
  </NewsFromParserPreviewBox>,
  document.querySelector('body'),
)
