import React from 'react'
import { useMonth } from 'assets/datepicker/index.cjs'
import { Day } from './Day'

export function Month({
  year, month, firstDayOfWeek,
}) {
  const { days, weekdayLabels } = useMonth({
    year,
    month,
    firstDayOfWeek,
  })

  return (
    <>
      <div
        className="week-days"
      >
        {weekdayLabels.map((dayLabel) => (
          <div className="day" key={dayLabel}>
            {dayLabel}
          </div>
        ))}
      </div>
      <div
        className="days-numbers-wrapper"
      >
        {days.map((day) => (
          <Day date={day.date} key={day.dayLabel} day={day.dayLabel} />
        ))}
      </div>
    </>
  )
}
