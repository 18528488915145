import React from 'react'
import styled from 'styled-components/macro'

import { FONTS } from 'constants/fonts'

const InputBox = styled.div`
  .label {
    margin-bottom: 7px;
    font-family: ${FONTS.roboto};
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #3c3c3c;
  }

  input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: solid 2px #897b6a;
    background-color: #c3b29b;
    box-sizing: border-box;
    outline: none;
    padding: 0 20px;
    font-family: ${FONTS.roboto};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: black;
  }
`

export const Input = ({
  onChange, label = '', className = '', type = 'text',
}) => (
  <InputBox className={className}>
    {!!label && <div className="label">{label}</div>}

    <input type={type} onChange={onChange} />
  </InputBox>
)
