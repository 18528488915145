import { Http } from './Http'

export const POSTS = {
  create: (data) => Http.post(Http.HOSTS.platform, '/api/v1/posts', data),
  getInfo: (id) => Http.get(Http.HOSTS.platform, '/api/v1/posts', { id, admin: true }),
  getByUrl: (url) => Http.get(Http.HOSTS.platform, '/api/v1/posts', { url }),
  update: (data) => Http.put(Http.HOSTS.platform, '/api/v1/posts', data),
  uploadImage: (data) => Http.post(Http.HOSTS.platform, '/api/v1/posts/image', data, 'file'),
  search: (params) => Http.get(Http.HOSTS.platform, '/api/v1/posts/search/all', params),
  getAuthors: () => Http.get(Http.HOSTS.platform, '/api/v1/authors'),
  getPesudonyms: () => Http.get(Http.HOSTS.platform, '/api/v1/authors/aliases'),
  getRubrics: () => Http.get(Http.HOSTS.platform, '/api/v1/rubrics'),
  getPosts: (params) => Http.get(Http.HOSTS.platform, '/api/v1/posts/all', params),
  searchTags: (params) => Http.get(Http.HOSTS.platform, '/api/v1/tags/find', params),
  getTags: () => Http.get(Http.HOSTS.platform, '/api/v1/tags/find', { name: '' }),
  publish: (id) => Http.post(Http.HOSTS.platform, '/api/v1/posts/publish', { id }),
  regenerate: (id) => Http.post(Http.HOSTS.platform, '/api/v1/posts/update', { id }),
  getGrid: (date) => Http.get(Http.HOSTS.platform, '/api/v1/posts/grid', { date }),
  updateGrid: (data) => Http.put(Http.HOSTS.platform, '/api/v1/posts/grid', { data }),
  getPhotoSources: (data) => Http.get(Http.HOSTS.platform, '/api/v1/sources', { data }),
  checkUniq: (id) => Http.get(Http.HOSTS.platform, '/api/v1/posts/plagiarism', { id }),
}
