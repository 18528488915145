import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { EditorState, RichUtils } from 'draft-js'
import classNames from 'classnames'
import 'draft-js/dist/Draft.css'

import { FONTS } from 'constants/fonts'
import { POSTS } from 'api/posts'
import { EditorToolbar } from './EditorToolbar'
import { withEditorContainer, Save } from './EditorContainer'
import { convertToHtml, convertFromHtml } from './convert'

const PreviouslyByThemeBox = styled.div`
  padding: 17px 0;

  &.active {
    padding: 0;
  }

  .previously-by-theme-title {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.11px;
    color: black;
  }

  .previously-by-theme-source {
    margin-top: 12px;
    margin-bottom: 12px;
    font-family: ${FONTS.lato};
    font-size: 18px;
  }

  .source-input-wrapper {
    display: flex;
    align-items: center;
  }

  .previously-by-theme-source-input {
    margin-bottom: 8px;
    box-sizing: border-box;
    margin-top: 8px;
    padding-left: 8px;
    display: block;
    width: 800px;
    height: 36px;
    outline: none;
    border-radius: 4px;
    border: solid 2px #d5d5d8;
    background-color: white;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: black;

    &::placeholder {
      opacity: 0.2;
      font-family: ${FONTS.lato};
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }
  }

  .previously-by-theme-post-name {
    margin-left: 20px;
    font-family: Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.1px;
    color: black;
  }

  .previously-by-theme-post {
    padding: 16px;
    width: 800px;
    margin-top: 16px;
    background: #EBE2D5;
    border: 1px dashed #897B6A;
    box-shadow: -3.06162e-16px 5px 15px rgba(0, 0, 0, 0.0509804);
    border-radius: 8px;

    .previously-by-theme-post-category {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: black;
      mix-blend-mode: normal;
      opacity: 0.7;
    }

    .previously-by-theme-post-title {
      margin-top: 9px;
      font-family: Oswald;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.1px;
      color: black;
    }
  }

  .empty {
    color: #ff9a99;
    font-size: 16px;
  }
`

const PreviouslyByThemeEditor = ({
  active, onSave, deactivate, initialState,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromHtml(initialState.html)))
  const [url, setUrl] = useState(initialState?.params?.url || '')
  const [postData, setPostData] = useState(null)

  const onEditorChange = (state) => {
    setEditorState(state)
  }

  const toggleInlineStyle = (inlineStyle) => {
    onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  const _onSave = () => {
    const html = convertToHtml(editorState.getCurrentContent())

    onSave({ html, params: { url, postData } })

    deactivate()
  }

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (!url) {
        setPostData(null)

        return
      }

      const response = await POSTS.getByUrl(url)

      const data = {
        title: response.data.title,
        category: response.data.rubric.name,
        date: response.data.date,
      }

      setPostData(data)
    }, 500)

    return () => clearTimeout(timeout)
  }, [url])

  return (
    <>
      <PreviouslyByThemeBox className={classNames({ active })}>
        <div className="previously-by-theme-title">
          Ранее по теме
          {!!postData && !active
            && (
            <>
              :
              <span className="previously-by-theme-post-name">{postData.title}</span>
            </>
            )}
        </div>

        {active && (
          <EditorToolbar
            editorState={editorState}
            onToggle={toggleInlineStyle}
            availableOptions={[]}
            blockTypeIcon="previous"
          />
        )}

        {active && (
          <div className="source-input-wrapper">
            <input
              className="previously-by-theme-source-input"
              placeholder="Ссылка на статью"
              onChange={(e) => setUrl(e.target.value)}
              value={url}
            />
          </div>
        )}

        {!postData && active
        && <div className="empty">Не заполнено</div>}

        {!!postData && active
        && (
        <div className="previously-by-theme-post">
          <div className="previously-by-theme-post-category">
            {postData.date}
            -
            {postData.category}
          </div>
          <div className="previously-by-theme-post-title">
            {postData.title}
          </div>
        </div>
        )}

        {/* {!active && withSource && (
          <div className="quote-source">
            {source ? `Источник: ${source}` : 'Источник'}
          </div>
        )} */}
      </PreviouslyByThemeBox>

      <Save onSave={_onSave} active={active} />
    </>
  )
}

const enhanced = withEditorContainer(PreviouslyByThemeEditor, 'previously-by-theme')

export { enhanced as PreviouslyByThemeEditor }
