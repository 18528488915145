import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import classNames from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars'

import { FONTS } from 'constants/fonts'
import { Icon, Checkbox } from 'ui/atoms'
import { useOnClickOutside } from 'hooks'

const AuthorBox = styled.div`
  margin-top: 11px;
  position: relative;

  .author-wrapper {
    height: 28px;
    width: 100%;
    border-radius: 14px;
    border: solid 1px #484b50;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    &.opened {
      border-color: transparent;

      .icon-arrow {
        transform: rotate(180deg)
      }
    }

    .author {
      height: 100%;
      width: 100%;
      padding-left: 8px;
      display: flex;
      align-items: center;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: left;
      color: black;
      cursor: pointer;
    }

    .author-input {
      border-radius: 14px;
      background-color: #f5f5f5;
      width: 100%;
      height: 100%;
      padding: 0 8px;
      box-sizing: border-box;
      border: none;
      outline: none;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }

    .icon-arrow {
      position: absolute;
      right: 4px;
      cursor: pointer;
    }
  }

  .authors-dropdown {
    position: absolute;
    min-width: 210px;
    width: calc(100% + 2px);
    border-radius: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    border: solid 2px #c3b29b;
    background-color: white;
    padding-top: 35px;
    top: -2px;
    left: -2px;
    overflow: hidden;
    display: none;

    &.opened {
      display: block;
    }

    .option {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      padding: 0 8px 0 6px;
      cursor: pointer;

      &:hover {
        background-color: #e1dad1;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .white-zone {
      padding-bottom: 5px;
    }

    .gray-zone {
      padding-top: 5px;
      background: #d5d5d8;

      .dot {
        background: #484b50;
      }

      .option {
        &:hover {
          background: #ebebed;
        }

        .checkbox {
          .checkbox-empty {
            border-color: white;
          }
        }
      }
    }

    .dot {
      margin-right: 6px;
      min-width: 6px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #c13b3b;
    }

    .author {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      box-sizing: border-box;

      .name {
        margin-top: -2px;
        font-family: ${FONTS.roboto};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: black;
      }
    }
  }

  .selected-author {
    margin-top: 12px;
    height: 28px;
    border-radius: 14px;
    border: solid 1px #484b50;
    background-color: #f5f5f5;
    width: max-content;
    display: flex;
    align-items: center;
    padding: 0 8px 0 2px;

    .name {
      margin-top: -2px;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }
  }
`

export const Author = ({ currentAuthorsIds = [], setAuthors, onlyOne = false }) => {
  const [opened, toggleDropdown] = useState(false)
  const [search, setSearch] = useState('')
  const authors = useSelector((state) => state.news.authors)
  const dropdownRef = useRef()
  const togglerRef = useRef()

  const currentAuthors = currentAuthorsIds.map((id) => authors.find((a) => a._id === id))

  useOnClickOutside(dropdownRef, () => toggleDropdown(false), [togglerRef])

  const addAuthor = (author) => {
    if (onlyOne) {
      setAuthors([author])
    }

    setAuthors([...currentAuthors, author])
  }

  const removeAuthor = (author) => {
    if (onlyOne) {
      return
    }

    const newAuthors = currentAuthors.filter((a) => a._id !== author._id)

    setAuthors(newAuthors)
  }

  function toggleAuthor(e, author) {
    e.preventDefault()

    if (currentAuthorsIds.includes(author._id)) {
      removeAuthor(author)

      return
    }

    addAuthor(author)
  }

  if (!authors.length) {
    return null
  }

  return (
    <AuthorBox>
      <div className={classNames('author-wrapper', { opened })} onClick={() => toggleDropdown(true)} ref={togglerRef}>
        {!opened ? (
          <div className="author">
            {
            currentAuthors[0] ? (
              <span>
                {currentAuthors[0].name}
                {' '}
                {currentAuthors.length > 1 && `+${currentAuthors.length - 1}`}
              </span>
            ) : 'Все авторы'
          }
          </div>
        ) : <input className="author-input" onChange={(e) => setSearch(e.target.value)} placeholder="Поиск" />}
        <Icon type="arrow" />
      </div>

      <div className={classNames('authors-dropdown', { opened })} ref={dropdownRef}>
        <Scrollbars autoHeight autoHeightMax="400px" renderTrackHorizontal={() => <div />}>
          <div className="white-zone">
            {authors.filter((author) => author.relevant).filter((author) => author.name.toLowerCase().startsWith(search.toLowerCase())).map((author) => (
              <div className="option" key={author._id} onClick={(e) => toggleAuthor(e, author)}>
                <div className="dot" />
                <div className="author">
                  <div className="name">{author.name}</div>
                </div>
                <Checkbox checked={currentAuthorsIds.includes(author._id)} />
              </div>
            ))}
          </div>
          <div className="gray-zone">
            {authors.filter((author) => !author.relevant).filter((author) => author.name.toLowerCase().startsWith(search.toLowerCase())).map((author) => (
              <div className="option" key={author._id} onClick={(e) => toggleAuthor(e, author)}>
                <div className="dot" />
                <div className="author">
                  <div className="name">{author.name}</div>
                </div>
                <Checkbox checked={currentAuthorsIds.includes(author._id)} className="checkbox" />
              </div>
            ))}
          </div>
        </Scrollbars>
      </div>

    </AuthorBox>
  )
}
