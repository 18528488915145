import React from 'react'
import styled from 'styled-components/macro'

const IconLeftSideBox = styled.div`
  height: 22px;
  width: 22px;
  display: flex;

  .cls-1 {
    fill: white;
  }
`

export const IconLeftSide = ({ className = '', ...props }) => (
  <IconLeftSideBox className={className} {...props}>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.66732 20.1673H1.83398V1.83398H3.66732V20.1673ZM20.1673 6.41732H5.50065V9.16732H20.1673V6.41732ZM14.6673 12.834H5.50065V15.584H14.6673V12.834Z"
        className="cls-1"
      />
    </svg>
  </IconLeftSideBox>
)
