import { IconSearch } from './icons/IconSearch'
import { IconCreateArticle } from './icons/IconCreateArticle'
import { IconArrow } from './icons/IconArrow'
import { IconSearchBig } from './icons/IconSearchBig'
import { IconEdit } from './icons/IconEdit'
import { IconCheck } from './icons/IconCheck'
import { IconBold } from './icons/IconBold'
import { IconItalic } from './icons/IconItalic'
import { IconHome } from './icons/IconHome'
import { IconNews } from './icons/IconNews'
import { IconFeather } from './icons/IconFeather'
import { IconLive } from './icons/IconLive'
import { IconH2 } from './icons/IconH2'
import { IconText } from './icons/IconText'
import { IconImage } from './icons/IconImage'
import { IconQuote } from './icons/IconQuote'
import { IconShuffle } from './icons/IconShuffle'
import { IconCross } from './icons/IconCross'
import { IconLink } from './icons/IconLink'
import { IconPlay } from './icons/IconPlay'
import { IconTimer } from './icons/IconTimer'
import { IconFire } from './icons/IconFire'
import { IconGrid } from './icons/IconGrid'
import { IconEnter } from './icons/IconEnter'
import { IconPlus } from './icons/IconPlus'
import { IconLibrary } from './icons/IconLibrary'
import { IconZoom } from './icons/IconZoom'
import { IconWrappedHome } from './icons/IconWrappedHome'
import { IconWrappedEye } from './icons/IconWrappedEye'
import { IconCalendar } from './icons/IconCalendar'
import { IconMove } from './icons/IconMove'
import { IconEllipsis } from './icons/IconEllipsis'
import { IconEyeCrossed } from './icons/IconEyeCrossed'
import { IconArticlePreview } from './icons/IconArticlePreview'
import { IconOpen } from './icons/IconOpen'
import { IconEmbedLink } from './icons/IconEmbedLink'
import { IconSlider } from './icons/IconSlider'
import { IconUndo } from './icons/IconUndo'
import { IconPause } from './icons/IconPause'
import { IconFlash } from './icons/IconFlash'
import { IconAuthor } from './icons/IconAuthor'
import { IconAddImg } from './icons/IconAddImg'
import { IconMainInText } from './icons/IconMainInText'
import { IconPrevious } from './icons/IconPrevious'
import { IconLayout } from './icons/IconLayout'
import { IconLeftSide } from './icons/IconLeftSide'
import { IconRightSide } from './icons/IconRightSide'
import { IconLongread } from './icons/IconLongread'
import { IconQuestion } from './icons/IconQuestion'
import { IconUniq } from './icons/IconUniq'

export const ICONS = {
  search: IconSearch,
  'search-big': IconSearchBig,
  'create-article': IconCreateArticle,
  arrow: IconArrow,
  edit: IconEdit,
  check: IconCheck,
  bold: IconBold,
  italic: IconItalic,
  home: IconHome,
  news: IconNews,
  feather: IconFeather,
  live: IconLive,
  h2: IconH2,
  text: IconText,
  image: IconImage,
  quote: IconQuote,
  shuffle: IconShuffle,
  cross: IconCross,
  link: IconLink,
  play: IconPlay,
  timer: IconTimer,
  fire: IconFire,
  grid: IconGrid,
  enter: IconEnter,
  plus: IconPlus,
  library: IconLibrary,
  zoom: IconZoom,
  'wrapped-home': IconWrappedHome,
  'wrapped-eye': IconWrappedEye,
  calendar: IconCalendar,
  move: IconMove,
  ellipsis: IconEllipsis,
  'eye-crossed': IconEyeCrossed,
  'article-preview': IconArticlePreview,
  open: IconOpen,
  'embed-link': IconEmbedLink,
  slider: IconSlider,
  undo: IconUndo,
  pause: IconPause,
  flash: IconFlash,
  author: IconAuthor,
  'add-image': IconAddImg,
  'main-in-text': IconMainInText,
  previous: IconPrevious,
  layout: IconLayout,
  'left-side': IconLeftSide,
  'right-side': IconRightSide,
  longread: IconLongread,
  question: IconQuestion,
  uniq: IconUniq,
}
