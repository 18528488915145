import React from 'react'
import styled from 'styled-components/macro'

const IconUniqBox = styled.div`
  height: 14px;
  width: 16px;
  display: flex;

  .cls-1 {
    fill: white;
  }
`

export const IconUniq = ({ className = '', ...props }) => (
  <IconUniqBox className={className} {...props}>
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7575 8.5H11.1575L10.955 8.2975C11.69 7.4425 12.1325 6.34 12.1325 5.125C12.1325 2.4325 9.95 0.25 7.2575 0.25C4.565 0.25 2.3825 2.5 2.3825 5.125H0.5L3.38 8.125L6.5 5.125H3.8825C3.8825 3.25 5.3975 1.75 7.2575 1.75C9.1175 1.75 10.6325 3.2575 10.6325 5.125C10.6325 6.985 9.1175 8.5 7.2575 8.5C6.77 8.5 6.3125 8.395 5.8925 8.215L4.7825 9.325C5.51 9.7525 6.35 10 7.2575 10C8.465 10 9.5675 9.5575 10.4225 8.8225L10.625 9.025V9.6175L14.3825 13.36L15.5 12.25L11.7575 8.5Z"
        className="cls-1"
      />
    </svg>
  </IconUniqBox>
)
