import React from 'react'
import { renderRoutes } from 'react-router-config'

import { Redactor } from 'pages/App/Redactor'
import { News } from 'pages/App/News'
import { Main } from 'pages/App/Main'
import { Library } from 'pages/App/Library'
import { withAccess } from 'features/Authorization'
import { MaterialGrid } from 'pages/App/MaterialGrid'

const routes = [
  {
    path: '/redactor/article/:id',
    component: (props) => <Redactor {...props} type="article" />,
  },
  {
    path: '/redactor/news/:id',
    component: (props) => <Redactor {...props} type="news" />,
  },
  {
    path: '/redactor/live/:id',
    component: (props) => <Redactor {...props} type="live" />,
  },
  {
    path: '/redactor/longread/:id',
    component: (props) => <Redactor {...props} type="longread" />,
  },
  {
    path: '/library',
    component: Library,
  },
  {
    path: '/news',
    component: News,
  },
  {
    path: '/grid',
    component: MaterialGrid,
  },
  {
    path: '/',
    component: Main,
  },
]

export const appRoutes = withAccess(['redactor', 'admin'])(() => (
  <>{renderRoutes(routes)}</>
))
