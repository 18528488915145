import React from 'react'
import styled from 'styled-components/macro'

const IconEyeCrossedBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #7c8784;
  }
  .cls-2 {
    fill: none;
  }
  .cls-3 {
    fill: #fff;
  }
`

export const IconEyeCrossed = ({ className = '', ...props }) => (
  <IconEyeCrossedBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Ic_home" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_1202" width="24" height="24" className="cls-1" data-name="Rectangle 1202" rx="4" />
      <g id="Component" transform="translate(3 3)">
        <g id="sharp-visibility_off-24px">
          <path
            id="Path_481"
            d="M0 0h18v18H0zm0 0h18v18H0zm0 0h18v18H0zm0 0h18v18H0z"
            className="cls-2"
            data-name="Path 481"
          />
          <path
            id="Path_482"
            d="M9 5.232a3.541 3.541 0 0 1 3.636 3.434 3.271 3.271 0 0 1-.175 1l2.225 2.1A8.124 8.124 0 0 0 17 8.659a8.611 8.611 0 0 0-8-5.144 9.084 9.084 0 0 0-2.647.392L7.931 5.4A3.94 3.94 0 0 1 9 5.232zM2.76 2.45l-1.025.975 1.949 1.841A8.118 8.118 0 0 0 1 8.666a8.606 8.606 0 0 0 8 5.151 9.091 9.091 0 0 0 3.135-.563l2.495 2.356 1.025-.968zM9 12.1a3.541 3.541 0 0 1-3.636-3.434A3.219 3.219 0 0 1 5.72 7.2l1.142 1.074a2.232 2.232 0 0 0-.044.392A2.121 2.121 0 0 0 9 10.727a2.069 2.069 0 0 0 .415-.048l1.142 1.078A3.67 3.67 0 0 1 9 12.1zm2.16-3.661a2.1 2.1 0 0 0-1.92-1.813z"
            className="cls-3"
            data-name="Path 482"
            transform="translate(0 .55)"
          />
        </g>
      </g>
    </svg>
  </IconEyeCrossedBox>
)
