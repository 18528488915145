import React, { useRef } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { FONTS } from 'constants/fonts'
import { useOnClickOutside } from 'hooks'
import logoImg from 'assets/images/logo.png'
import profileImg from 'assets/images/profile-preview.png'
import { Button } from 'ui/atoms'
import { setUserInfo } from 'redux/actions/user'

const LeftPanelBox = styled.div`
  width: 500px;
  border-radius: 2px;
  box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.25);
  background-color: #b96d3e;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  .logo {
    width: 60px;
    height: 60px;
    background: black;
    border-radius: 50%;
  }

  .title {
    margin-top: 14px;
    width: 188px;
    width: 188px;
    height: 80px;
    font-family: ${FONTS.roboto};
    font-size: 40px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.8;
    letter-spacing: 1.44px;
    text-align: center;
    color: black;
    text-transform: uppercase;

    span {
      font-size: 26px;
      font-weight: 300;
      letter-spacing: 9.36px;
    }
  }

  .info {
    margin-top: 9px;
    font-family: ${FONTS.roboto};
    font-size: 26px;
    font-weight: 100;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: 7.8px;
    text-align: center;
    color: black;
  }

  .login {
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: ${FONTS.roboto};
    font-size: 34px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.68px;
    text-align: center;
    color: black;
    transform: translate(-50%, -50%);
  }

  .user-info {
    margin-top: 30px;
    font-family: ${FONTS.roboto};
    display: flex;
    flex-direction: column;
    align-items: center;

    .photo {
      width: 170px;
      height: 170px;
      background: pink;
      border-radius: 50%;
    }

    .user-name {
      margin-top: 26px;
      width: 262px;
      font-family: ${FONTS.roboto};
      font-size: 34px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: 0.68px;
      text-align: center;
      color: black;
    }

    .log-out-button {
      margin-top: 145px;
    }
  }
`

export const ProfileLeftPanel = ({ className, onClose }) => {
  const userInfo = useSelector((state) => state.user.info)
  const loggedIn = !!userInfo?.name
  const panelRef = useRef()

  const dispatch = useDispatch()
  const history = useHistory()

  useOnClickOutside(panelRef, onClose || (() => true))

  function logout() {
    dispatch(
      setUserInfo({}),
    )

    localStorage.clear()
    history.replace('login')
  }

  return (
    <LeftPanelBox className={className} ref={panelRef}>
      <img className="logo" src={logoImg} alt="logo" />

      <div className="title">
        Русская
        {' '}
        <span>планета</span>
      </div>

      <div className="info">редакция</div>

      {!loggedIn ? (
        <div className="login">вход</div>
      ) : (
        <div className="user-info">
          <img className="photo" src={profileImg} alt="profile" />
          <div className="user-name">{userInfo?.name}</div>

          <Button text="Выйти" color="black" className="log-out-button" onClick={logout} />
        </div>
      )}
    </LeftPanelBox>
  )
}
