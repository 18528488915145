import React from 'react'
import styled from 'styled-components/macro'

const IconQuoteBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #fff;
  }

  .cls-2 {
    fill: none;
  }
`

export const IconQuote = ({ className = '', ...props }) => (
  <IconQuoteBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="baseline-format_quote-24px"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_516"
        d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z"
        className="cls-1"
        data-name="Path 516"
      />
      <path id="Path_517" d="M0 0h24v24H0z" className="cls-2" data-name="Path 517" />
    </svg>
  </IconQuoteBox>
)
