import React from 'react'
import styled from 'styled-components/macro'

const IconCreateArticleBox = styled.div`
  .cls-1 {
    fill: none;
  }
  .cls-2 {
    fill: #fff;
  }
`

export const IconCreateArticle = ({ className = '', ...props }) => (
  <IconCreateArticleBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g id="_24px" data-name="24" transform="translate(7 8)">
        <rect
          id="Rectangle_1155"
          width="40"
          height="40"
          data-name="Rectangle 1155"
          rx="20"
          transform="translate(-7 -8)"
          className="cls-3"
        />
        <path id="Path_523" d="M0 0h24v24H0z" className="cls-1" data-name="Path 523" />
        <path
          id="Path_522"
          d="M3 13.29V16h2.708l7.987-7.987L10.987 5.3zm12.789-7.373a.719.719 0 0 0 0-1.018L14.1 3.209a.719.719 0 0 0-1.018 0L11.759 4.53l2.708 2.708 1.321-1.321z"
          className="cls-2"
          data-name="Path 522"
          transform="translate(8 3.002)"
        />
        <path
          id="Path_526"
          d="M10 8H8v4H4v2h4v4h2v-4h4v-2h-4z"
          className="cls-2"
          data-name="Path 526"
          transform="translate(-4)"
        />
      </g>
    </svg>
  </IconCreateArticleBox>
)
