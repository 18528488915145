import React from 'react'
import styled from 'styled-components'

import { FONTS } from 'constants/fonts'
import { Datepicker } from 'ui/molecules'
import { Author } from './Author'
import { Rubrics } from './Rubrics'
import { PublicationTypes } from './PublicationTypes'
import { Statuses } from './Statuses'

const LibraryFiltersBox = styled.div`
  width: 400px;
  min-width: 400px;
  background: #e7e8ea;
  padding: 40px 54px;
  box-sizing: border-box;

  .title {
    margin-bottom: 50px;
    font-family: ${FONTS.roboto};
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.92;
    letter-spacing: -0.38px;
    color: black;
    text-transform: uppercase;
  }

  .sub-title {
    margin-top: 12px;
    font-family: ${FONTS.roboto};
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    text-transform: uppercase;
    color: black;
    opacity: 0.6;
  }

  .divider {
    position: relative;
    left: -30px;
    margin-top: 16px;
    width: calc(100% + 60px);
    height: 1px;
    background-color: #d7d9da;
  }
`

export const LibraryFilters = ({
  activeRubrics, onSelectRubric, date, setDate, currentAuthorsIds, setAuthors,
  dateDisabled, onSelectStatus,
  activeStatuses, onSelectType, activeTypes,
  initialStartDate, initialEndDate,
}) => (
  <LibraryFiltersBox>
    <div className="title">Все материалы</div>

    <div className="sub-title">Тип публикации</div>

    <PublicationTypes onSelectType={onSelectType} currentTypes={activeTypes} />

    <div className="divider" />

    <div className="sub-title">Статус</div>

    <Statuses setStatus={onSelectStatus} activeStatuses={activeStatuses} />

    <div className="divider" />

    <div className="sub-title">Рубрика</div>

    <Rubrics activeRubrics={activeRubrics} setRubric={onSelectRubric} />

    <div className="divider" />

    {!dateDisabled && <div className="sub-title">Дата публикации</div>}

    {!dateDisabled && <Datepicker date={date} onSelect={setDate} initialStartDate={initialStartDate} initialEndDate={initialEndDate} withRange />}

    <div className="sub-title">Автор</div>

    <Author currentAuthorsIds={currentAuthorsIds} setAuthors={setAuthors} />
  </LibraryFiltersBox>
)
