import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { NotSelected, Icon } from 'ui/atoms'

const DescriptionBox = styled.div`
  margin-top: 12px;

  .title-row {
    margin-bottom: 8px;
    min-height: 16px;
    display: flex;
    align-items:center;
  }

  .title {
    font-family: ${FONTS.roboto};
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    opacity: 0.6;
    text-align: left;
    color: black;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .textarea-wrapper {
    width: 100%;
    position: relative;

    textarea {
      width: 274px;
      display: block;
      outline: none;
      border-radius: 4px;
      border: solid 2px #c3b29b;
      min-height: 21px;
      height: 21px;
      /* box-sizing: border-box; */
      padding: 7px;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      resize: none;

      &::placeholder {
        opacity: 0.4;
        font-family: ${FONTS.roboto};
        font-size: 16px;
      }

      &:hover {
        border-color: #ce914a;
      }

      &.active {
        padding-bottom: 36px;
        border-color: #ce914a;
      }
    }
  }

  .button-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 52px;
    height: 36px;
    background-color: #ce924a;
    border-top-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      cursor: pointer;
      width: 40px;
      height: 26px;
      background: white;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.close {
      right: 56px;
      border-top-right-radius: 4px;

      .cls-2 {
        stroke: #de4e4e;
      }
    }
  }
`

export const TextField = ({
  initialDescription = '', title, placeholder, onChange,
}) => {
  const textareaRef = useRef()
  const [description, setDescription] = useState(initialDescription)
  const [active, toggleActive] = useState(false)

  function onInput(e) {
    const textarea = textareaRef.current

    textarea.style.cssText = 'height:auto; padding:0'

    textarea.style.cssText = `height:${textarea.scrollHeight}px`

    setDescription(e.target.value)
  }

  function onAccept() {
    textareaRef.current.blur()
    toggleActive(false)
    onChange(description)
  }

  function onCancel() {
    toggleActive(false)
    setDescription(initialDescription)
    onChange(initialDescription)
  }

  useEffect(() => {
    setDescription(initialDescription)
  }, [initialDescription])

  return (
    <DescriptionBox>
      <div className="title-row">
        <div className="title">{title}</div>
        {!description && <NotSelected text="не добавлено" />}
      </div>

      <div className="textarea-wrapper">
        <textarea
          value={description}
          ref={textareaRef}
          rows={1}
          maxLength={180}
          placeholder={placeholder}
          onChange={onInput}
          className={classNames({ active })}
          onClick={() => toggleActive(true)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              onAccept()
            }
          }}
        />

        {active && (
        <div className="button-wrapper close" onClick={() => onCancel()}>
          <div className="button"><Icon type="cross" /></div>
        </div>
        )}

        {active && (
        <div className="button-wrapper" onClick={onAccept}>
          <div className="button"><Icon type="check" /></div>
        </div>
        )}

      </div>
    </DescriptionBox>
  )
}
