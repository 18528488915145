import React from 'react'
import styled from 'styled-components/macro'

const IconTextBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    clip-path: url(#clip-path);
  }
`

export const IconText = ({ className = '', ...props }) => (
  <IconTextBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <path id="Path_513" d="M24 24H0V0h24z" className="cls-1" data-name="Path 513" />
        </clipPath>
      </defs>
      <g id="baseline-text_fields-24px" className="cls-2">
        <path
          id="Path_512"
          d="M2.5 4v3h5v12h3V7h5V4zm19 5h-9v3h3v7h3v-7h3z"
          className="cls-1"
          data-name="Path 512"
        />
      </g>
    </svg>
  </IconTextBox>
)
