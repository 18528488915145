import queryString from 'query-string'

const createFormData = (data) => {
  const formData = new FormData()

  Object.keys(data).forEach((key) => {
    if (data[key].blob) {
      formData.append(key, data[key].blob, data[key].fileName)

      return
    }

    formData.append(key, data[key])
  })

  return formData
}

const headers = {
  'Access-Control-Allow-Origin': '*',
  Accept: 'application/json',
}

export const Http = {
  HOSTS: {
    auth: process.env.REACT_APP_IS_PROD ? 'https://auth.ruplt.ru' : 'https://auth.staging.ruplt.ru',
    platform: process.env.REACT_APP_IS_PROD ? 'https://platform.ruplt.ru' : 'https://platform.staging.ruplt.ru',
  },

  get: (HOST, url, data) => {
    let path = HOST + url

    if (data && Object.keys(data).length) {
      path += `?${queryString.stringify(data)}`
    }

    const config = {
      headers: {
        ...headers,
      },
      method: 'get',
    }

    const token = localStorage.getItem('token')

    if (token) {
      config.headers['X-auth-token'] = token
    }

    return window.fetch(path, config)
      .then((response) => response.json())
      .then((response) => {
        if (response.res === 'success') {
          return response
        }

        throw response || 'Unexpected error'
      })
  },

  post: (HOST, url, data, type) => {
    const path = HOST + url

    const config = {
      headers: {
        ...headers,
      },
      method: 'post',
    }

    const token = localStorage.getItem('token')

    if (token) {
      config.headers['X-auth-token'] = token
    }

    if (type === 'file') {
      config.body = createFormData(data)
    } else {
      config.body = JSON.stringify(data)
      config.headers['Content-Type'] = 'application/json'
    }

    return window.fetch(path, config)
      .then((response) => response.json())
      .then((response) => {
        if (response.res === 'success') {
          return response
        }

        throw response || 'Unexpected error'
      })
  },

  put: (HOST, url, data, type) => {
    const path = HOST + url

    const config = {
      headers: {
        ...headers,
      },
      method: 'put',
    }

    const token = localStorage.getItem('token')

    if (token) {
      config.headers['X-auth-token'] = token
    }

    if (type === 'file') {
      config.body = createFormData(data)
    } else {
      config.body = JSON.stringify(data)
      config.headers['Content-Type'] = 'application/json'
    }

    return window.fetch(path, config)
      .then((response) => response.json())
      .then((response) => {
        if (response.res === 'success') {
          return response
        }

        throw response || 'Unexpected error'
      })
  },

  delete: (HOST, url, data) => {
    let path = HOST + url

    if (data && Object.keys(data).length) {
      path += `?${queryString.stringify(data)}`
    }

    const config = {
      headers: {
        ...headers,
      },
      method: 'delete',
    }

    const token = localStorage.getItem('token')

    if (token) {
      config.headers['X-auth-token'] = token
    }

    return window.fetch(path, config)
      .then((response) => response.json())
      .then((response) => {
        if (response.res === 'success') {
          return response
        }

        throw response || 'Unexpected error'
      })
  },
}
