import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { Draggable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'
import { useOnClickOutside } from 'hooks'
import { IconTooltip } from './IconTooltip'

const CardBox = styled.div`
  margin-bottom: 30px;
  width: 300px;
  min-height: 201px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  padding: 16px 0 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid transparent;

  &.hot {
    background-color: #f8f3e5;

    .card-controls {
      background-color: #f8f3e5;

      .icon-fire {
        .cls-3 {
          fill: #c13b3b;
        }

        .cls-2 {
          opacity: 1;
        }
      }

      .icon-wrapped-eye {
        display: none;
      }
    }
  }

  &.detected {
    border: solid 1px #ce914a;
  }

  .category {
    padding: 0 12px;
    font-family: ${FONTS.roboto};
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.83;
    letter-spacing: 0.9px;
    text-align: left;
    color: #c13b3b;
    text-transform: uppercase;

    .waiting {
      margin-left: 16px;
      font-family: ${FONTS.roboto};
      font-size: 12px;
      font-weight: 500;
      line-height: 0.83;
      letter-spacing: 0.9px;
      color: #7c8783;
    }

    .icon-ellipsis {
      margin-left: auto;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      margin-right: -12px;
    }

    .icon-live {
      margin-left: -5px;
      margin-right: 5px;

      svg {
        width: 24px;
        height: 24px;

        .cls-3,
        .cls-4 {
          fill: #c13b3a;
        }
      }
    }

    .icon-feather {
      margin-left: -5px;
      margin-right: 5px;

      svg {
        width: 24px;
        height: 24px;

        .cls-3 {
          fill: #c13b3a;
        }
      }
    }
  }

  .options-dropdown {
    width: 153px;
    position: absolute;
    right: 11px;
    top: 8px;
    background-color: rgba(72, 75, 80, 0.95);
    border-radius: 4px;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.16);
    padding: 8px 0;
    box-sizing: border-box;

    .option {
      height: 26px;
      display: flex;
      align-items: center;
      font-family: Roboto;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.23;
      letter-spacing: -0.12px;
      text-align: left;
      color: #ffffff;
      padding: 0 12px;

      &:hover {
        background: #6d6f73;
      }
    }
  }

  .news-title {
    padding: 0 12px;
    margin-top: 6px;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: -0.14px;
    text-align: left;
    color: black;
  }

  .news-lead {
    padding: 0 12px;
    margin-top: 4px;
    font-family: ${FONTS.roboto};
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: -0.12px;
    text-align: left;
    color: rgba(29, 31, 36, 0.4);
  }

  .card-controls {
    margin-top: auto;
    width: 100%;
    height: 48px;
    background-color: #f5f5f5;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .icon-fire {
      margin-right: 16px;
      cursor: pointer;

      .cls-3 {
        fill: #d5d5d8;
      }

      .cls-2 {
        opacity: 0.7;
      }

      &:hover {
        .cls-2 {
          opacity: 1;
        }
      }
    }

    .icon-slider {
      cursor: pointer;

      &:hover {
        .cls-1 {
          fill: #c0c0c1;
        }
      }

      &.active {
        .cls-1 {
          fill: #484b50;
        }
      }
    }

    .icon-link {
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d5d5d8;

      svg {
        transform: rotate(90deg);
        width: 20px;
        height: 20px;
      }

      &:hover {
        background: #c0c0c1;
      }

      &.active {
        background: #484b50;
      }
    }

    .icon-wrapped-home {
      margin-left: auto;
      cursor: pointer;

      &:hover {
        .cls-1 {
          fill: #c0c0c1;
        }
      }

      &.active {
        .cls-1 {
          fill: #484b50;
        }
      }
    }
    
    .icon-home-tooltip {
      margin-left: auto;

      &.disabled {
        pointer-events: none;
        opacity: 0.7;
      }
    }

    .m-l-16 {
      margin-left: 16px;
    }

    .m-l-auto {
      margin-left: auto;
    }

    .move {
      cursor: pointer;

      svg {
        border-radius: 50%;
      }

      &:hover {
        .cls-1 {
          fill: #c0c0c1;
        }
      }

      &.down {
        transform: rotate(180deg);
      }
    }

    .time-control {
      margin-left: 16px;
    }
  }
`

export const Card = ({
  news,
  hot = false,
  index,
  type,
  draggable = true,
  addToMainNews,
  columnId,
  setHotNews,
  inMainNews = false,
  fromMainNews = false,
  hideHot = false,
  moveUp,
  moveDown,
  hideMoveUp,
  hideMoveDown,
  detectNews,
  detected = false,
  addToSlider,
  inSlider,
  setAccessByLink,
  hideMain,
}) => {
  const [dropdownOpened, setDropdownStatus] = useState(false)

  const dropdownRef = useRef()

  useOnClickOutside(dropdownRef, () => setDropdownStatus(false))

  if (!draggable) {
    return (
      <CardBox className={classNames({ hot })}>
        {dropdownOpened && (
        <div className="options-dropdown" ref={dropdownRef}>
          <Link to={`/redactor/${news.type}/${news._id}`}>
            <div className="option">Открыть в редакторе</div>
          </Link>
          {inMainNews && (
          <div className="option" onClick={() => detectNews() || setDropdownStatus(false)}>
            {fromMainNews ? 'Найти в сетке' : 'Найти на главной'}
          </div>
          )}
        </div>
        )}

        <div className="category">
          {news.rubric.name}
          <Icon type="ellipsis" onClick={() => setDropdownStatus(true)} />
        </div>

        <div className="news-title" dangerouslySetInnerHTML={{ __html: news.title }} />

        <div className="news-lead" dangerouslySetInnerHTML={{ __html: news.lead }} />

        <div className="card-controls">
          {!hideHot && <Icon type="fire" onClick={() => setHotNews(news)} />}

          <IconTooltip text={inSlider ? 'В слайдере' : 'Не в слайдере'}>
            <Icon
              type="slider"
              className={classNames({ active: inSlider })}
              onClick={() => addToSlider(news)}
            />
          </IconTooltip>

          <IconTooltip text={inMainNews ? 'На главной' : 'Только в рубрике'} className={classNames('icon-home-tooltip', { disabled: hideMain })}>
            <Icon
              type="wrapped-home"
              className={classNames({ active: inMainNews })}
              onClick={() => addToMainNews(news)}
            />
          </IconTooltip>
        </div>
      </CardBox>
    )
  }

  return (
    <Draggable key={news._id} draggableId={`${columnId}-${news._id}`} index={index} type={type}>
      {(provided) => (
        <CardBox
          className={classNames({ hot, detected })}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {dropdownOpened && (
            <div className="options-dropdown" ref={dropdownRef}>
              <Link to={`/redactor/${news.type}/${news._id}`}>
                <div className="option">Открыть в редакторе</div>
              </Link>
              {inMainNews && (
                <div className="option" onClick={() => detectNews() || setDropdownStatus(false)}>
                  {fromMainNews ? 'Найти в сетке' : 'Найти на главной'}
                </div>
              )}
            </div>
          )}

          <div className="category">
            {news.type === 'live' && <Icon type="live" />}
            {news.type === 'article' && <Icon type="feather" />}
            {news.rubric?.name}

            {(new Date(news.date)).valueOf() > (new Date()).valueOf() && (
            <div className="waiting">
              {moment(new Date(news.date)).format('HH:mm')}
            </div>
            )}

            <Icon type="ellipsis" onClick={() => setDropdownStatus(true)} />
          </div>

          <div className="news-title" dangerouslySetInnerHTML={{ __html: news.title }} />

          <div className="news-lead" dangerouslySetInnerHTML={{ __html: news.lead }} />

          <div className="card-controls">
            {!hideHot && !news.accessLinkOnly && (
            <IconTooltip text="Обычная статья">
              <Icon type="fire" onClick={() => setHotNews(news)} />
            </IconTooltip>
            )}

            {!news.accessLinkOnly && (
            <IconTooltip text={inSlider ? 'В слайдере' : 'Не в слайдере'}>
              <Icon
                type="slider"
                className={classNames({ active: inSlider })}
                onClick={() => addToSlider(news)}
              />
            </IconTooltip>
            )}

            {!news.accessLinkOnly && (
            <IconTooltip text={inMainNews ? 'На главной' : 'Только в рубрике'} className={classNames('icon-home-tooltip', { disabled: hideMain })}>
              <Icon
                type="wrapped-home"
                className={classNames({ active: inMainNews })}
                onClick={() => addToMainNews(news)}
              />
            </IconTooltip>
            )}

            {!inMainNews && !inSlider && !hot && (
            <IconTooltip
              text={news.accessLinkOnly ? 'Доступ по ссылке' : 'Открытый доступ'}
              className={classNames({ 'm-l-16': !news.accessLinkOnly, 'm-l-auto': news.accessLinkOnly })}
            >
              <Icon
                type="link"
                className={classNames({ active: news.accessLinkOnly })}
                onClick={() => setAccessByLink(news)}
              />
            </IconTooltip>
            )}

            {(new Date(news.date)).valueOf() > (new Date()).valueOf() && (
            <IconTooltip text="В ожидании" className="m-l-16">
              <Icon type="pause" />
            </IconTooltip>
            ) }

            {!hideMoveUp && (
            <IconTooltip text="В начало ленты" className="m-l-16">
              <Icon type="move" className="move up" onClick={moveUp} />
            </IconTooltip>
            )}

            {!hideMoveDown && (
            <IconTooltip text="В конец ленты" className="m-l-16">
              <Icon type="move" className="move down" onClick={moveDown} />
            </IconTooltip>
            )}
          </div>
        </CardBox>
      )}
    </Draggable>
  )
}
