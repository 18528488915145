import React from 'react'
import styled from 'styled-components/macro'

const IconSliderBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  .cls-1{
    fill:#d5d5d8
  }
    
  .cls-2,.cls-5{
    fill:#fff
  }
      
  .cls-3{
    fill:none;
    stroke:#fff
  }
    
  .cls-4,.cls-5{
    stroke:none
  }
`

export const IconSlider = ({ className = '', ...props }) => (
  <IconSliderBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Slider" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_1190" width="24" height="24" className="cls-1" data-name="Rectangle 1190" rx="12" />
      <g id="Subtraction_3" className="cls-2" data-name="Subtraction 3">
        <path d="M-10415 16866.5h-8a1.502 1.502 0 0 1-1.498-1.502v-9a1.5 1.5 0 0 1 1.498-1.498h8a1.5 1.5 0 0 1 1.498 1.498v9c0 .828-.672 1.502-1.498 1.502zm-8.5-4.998v1.998h9v-1.998h-9zm0-2.002v1.998h9V16857.502h-9v1.998z" className="cls-4" transform="translate(10431 -16848)" />
        <path d="M-10415 16866a1 1 0 0 0 .998-1.002v-.998H-10423.998v.998a1 1 0 0 0 .998 1.002h8m.998-8.998v-1.004a.999.999 0 0 0-.998-.998h-8a.999.999 0 0 0-.998.998v1.004H-10414.002m-.998 9.998h-8a2.003 2.003 0 0 1-1.998-2.002v-9a2 2 0 0 1 1.998-1.998h8a2 2 0 0 1 1.998 1.998v9a2.003 2.003 0 0 1-1.998 2.002zm-8-4.998v.998h8v-.998h-8zm0-2.002v.998h8v-.998h-8zm0-1.998v.998h8v-.998h-8z" className="cls-5" transform="translate(10431 -16848)" />
      </g>
      <path id="Path_608" d="M17.177 12l1.9 1.848-1.9 1.77" className="cls-3" data-name="Path 608" transform="translate(3 -1.5)" />
      <path id="Path_609" d="M19.074 12l-1.9 1.848 1.9 1.77" className="cls-3" data-name="Path 609" transform="translate(-15 -1.5)" />
    </svg>

  </IconSliderBox>
)
