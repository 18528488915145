import React from 'react'
import styled from 'styled-components/macro'

const IconLayoutBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: white;
  }
`

export const IconLayout = ({ className = '', ...props }) => (
  <IconLayoutBox className={className} {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM15 18H4V14H15V18ZM15 13H4V9H15V13ZM20 18H16V9H20V18Z" className="cls-1" />
    </svg>
  </IconLayoutBox>
)
