import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import ReactDOM from 'react-dom'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Scrollbars } from 'react-custom-scrollbars'
import classNames from 'classnames'

import { Icon } from 'ui/atoms'
import { FONTS } from 'constants/fonts'

const CropModalBox = styled.div`
  position: fixed;
  overflow: visible;
  max-height: 90vh;
  width: 1016px;
  left: 521px;
  top: 30px;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(22.8px);
  backdrop-filter: blur(22.8px);
  box-shadow: 0 0 56px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  z-index: 3;

  .modal-header {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    width: 100%;
    padding-right: 16px;
    box-sizing: border-box;
    justify-content: flex-end;

    .icon-cross {
      width: 30px;
      height: 30px;
      background: #897b6a;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .cls-2 {
        stroke: white;
      }
    }
  }

  .crop-modal-title {
    margin-top: 4px;
    margin-bottom: 50px;
    opacity: 0.45;
    font-family: ${FONTS.lato};
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: -0.11px;
    text-align: center;
    color: black;
  }

  .crop-modal-content {
    padding: 0 58px 50px;

    .ReactCrop {
      width: 100%;
    }
  }

  .previews-wrapper {
    margin-top: 45px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #efefef;
    display: flex;
    justify-content: space-between;

    .preview-wrapper {
      cursor: pointer;

      &.active {
        .description {
          color: #ce914a;
        }

        .preview {
          border-color: #ce914a;
        }
      }

      .description {
        margin-bottom: 8px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: -0.25px;
        text-align: left;
        color: black;
      }

      .preview {
        object-fit: cover;
        border: 6px solid transparent;

        &.first {
          width: 326px;
          height: 160px;
        }

        &.second {
          width: 301px;
          height: 119px;
        }

        &.third {
          width: 196px;
          height: 161px;
        }
      }
    }
  }

  .done-wrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;

    .done {
      width: 200px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: #d56c45;
      font-family: ${FONTS.roboto};
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
`

export const CropModal = ({ imageSrc, onClose, onSave }) => {
  const [crop, setCrop] = useState({
    0: {
      unit: '%',
      width: 100,
      aspect: 9 / 5,
    },
    1: {
      unit: '%',
      width: 100,
      aspect: 93 / 35,
    },
    2: {
      unit: '%',
      width: 100,
      aspect: 344 / 240,
    },
  })

  const [currentPreviewIndex, setCurrentPreview] = useState(0)

  const [croppedImageData, setCroppedImageData] = useState({
    0: {},
    1: {},
    2: {},
  })

  const [changesCount, setChangesCount] = useState([0])

  const fileUrl = useRef(null)
  const imageRef = useRef(null)

  function changeActiveCrop(index) {
    setCurrentPreview(index)

    setChangesCount([...(new Set([...changesCount, index]))])
  }

  function getCroppedImg(crop, fileName) {
    const image = imageRef.current

    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    canvas.width = crop.width
    canvas.height = crop.height

    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return
        }

        // eslint-disable-next-line
        blob.name = fileName
        window.URL.revokeObjectURL(fileUrl.current)
        fileUrl.current = window.URL.createObjectURL(blob)

        resolve({ blobUrl: window.URL.createObjectURL(blob), blob })
      }, 'image/jpeg', 1)
    })
  }

  function _onSave(croppedImageData) {
    setChangesCount([])
    onSave(croppedImageData)
  }

  async function makeClientCrop(crop, index) {
    if (crop.width && crop.height) {
      const data = await getCroppedImg(crop, `newFile${index}.jpeg`)

      setCroppedImageData({ ...croppedImageData, [index]: data })
    }
  }

  return ReactDOM.createPortal(
    <CropModalBox>
      <div className="modal-header">
        <Icon type="cross" onClick={onClose} />
      </div>

      <div className="crop-modal-title">Поместите ключевой фрагмент в рамку</div>

      <Scrollbars autoHeight autoHeightMax="76vh" renderTrackHorizontal={() => <div />}>
        <div className="crop-modal-content">
          <ReactCrop
            src={imageSrc}
            crop={crop[currentPreviewIndex]}
            onComplete={(data) => makeClientCrop(data, currentPreviewIndex)}
            onChange={(data) => setCrop({ ...crop, [currentPreviewIndex]: data })}
            onImageLoaded={(image) => { imageRef.current = image }}
            imageStyle={{ width: '100%' }}
          />

          <div className="previews-wrapper">
            <div className={classNames('preview-wrapper', { active: currentPreviewIndex === 0 })} onClick={() => changeActiveCrop(0)}>
              <div className="description">Публикация 900x440</div>
              <img src={croppedImageData[0].blobUrl} className="preview first" alt="" />
            </div>

            <div className={classNames('preview-wrapper', { active: currentPreviewIndex === 1 })} onClick={() => changeActiveCrop(1)}>
              <div className="description">Публикация 930x350</div>
              <img src={croppedImageData[1].blobUrl} className="preview second" alt="" />
            </div>

            <div className={classNames('preview-wrapper', { active: currentPreviewIndex === 2 })} onClick={() => changeActiveCrop(2)}>
              <div className="description">Публикация 344x240</div>
              <img src={croppedImageData[2].blobUrl} className="preview third" alt="" />
            </div>
          </div>

          <div className="done-wrapper">
            <div className={classNames('done', { disabled: changesCount.length !== 3 })} onClick={() => _onSave(croppedImageData)}>
              Готово
            </div>
          </div>
        </div>
      </Scrollbars>
    </CropModalBox>,
    document.querySelector('body'),
  )
}
