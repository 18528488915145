import React from 'react'
import styled from 'styled-components/macro'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'

const StyledButton = styled.button`
  height: 48px;
  border-radius: 4px;
  background-color: #d56c45;
  outline: none;
  display: flex;
  align-items: center;
  font-family: ${FONTS.roboto};
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  cursor: pointer;
  padding: 0 65px;
  border: none;

  &.black {
    background: black;
  }
`

export const Button = ({
  text, onClick, type = '', size = '', className = '', color = '',
}) => (
  <StyledButton
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
    className={classNames(className, type, size, color)}
  >
    {text}
  </StyledButton>
)
