import React from 'react'
import styled from 'styled-components/macro'

const IconSearchBigBox = styled.div`
  .cls-1,
  .cls-2,
  .cls-3 {
    fill: none;
  }
  .cls-1,
  .cls-2 {
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 2px;
  }
  .cls-2 {
    stroke-linecap: round;
  }
`

export const IconSearchBig = ({ className = '', ...props }) => (
  <IconSearchBigBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Icon" width="30" height="30" viewBox="0 0 30 30">
      <defs>
        <style></style>
      </defs>
      <g id="Group" transform="rotate(180 12.246 12.148)">
        <circle
          id="Oval"
          cx="8"
          cy="8"
          r="8"
          className="cls-1"
          transform="rotate(180 8.739 9.148)"
        />
        <path
          id="Line"
          d="M0 0l3.536 3.536"
          className="cls-2"
          transform="rotate(180 1.768 1.768)"
        />
      </g>
      <path id="Bounds" d="M0 0h30v30H0z" className="cls-3" />
    </svg>
  </IconSearchBigBox>
)
