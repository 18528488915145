import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { Icon } from 'ui/atoms'
import { FONTS } from 'constants/fonts'

const PaginationBox = styled.div`
  height: 24px;
  display: flex;
  align-items: center;

  .circle {
    margin-right: 9px;
    min-width: 25px;
    height: 24px;
    border-radius: 12px;
    background-color: #d5d5d8;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: -0.25px;
    text-align: left;
    color: black;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }

    &:hover,
    &.active {
      background-color: black;
      color: white;

      .icon-arrow {
        .cls-1 {
          fill: white;
        }
      }
    }

    .icon-arrow {
      &.left {
        transform: rotate(90deg);
      }

      &.right {
        transform: rotate(-90deg);
      }
    }
  }

  .divider {
    margin-right: 9px;
    width: 21px;
    border-bottom: 1px solid #d5d5d8;
  }
`

export const Pagination = ({
  page, perPage, total, onPageChange,
}) => {
  const pagesCount = Math.ceil(total / perPage)
  const pages = new Array(pagesCount).fill(undefined).map((e, index) => index + 1)

  const leftPartPages = page < 4 ? pages.slice(0, 4) : pages.slice(page - 3, page + 1)

  if (total <= perPage) {
    return null
  }

  return (
    <PaginationBox>
      <div className={classNames('circle', { disabled: page === 1 })} onClick={() => onPageChange(page - 1)}>
        <Icon type="arrow" className="left" />
      </div>

      {leftPartPages.map((e) => (
        <div className={classNames('circle', { active: e === page })} key={e} onClick={() => onPageChange(e)}>
          {e}
        </div>
      ))}

      {pagesCount > page && (
        <>
          {pagesCount - page > 1 && <div className="divider" />}

          {pagesCount - 1 !== page && (
            <div className="circle" onClick={() => onPageChange(pagesCount)}>
              {pagesCount}
            </div>
          )}
        </>
      )}

      <div className={classNames('circle', { disabled: page === pagesCount })} onClick={() => onPageChange(page + 1)}>
        <Icon type="arrow" className="right" />
      </div>
    </PaginationBox>
  )
}
