import React from 'react'
import styled from 'styled-components/macro'

const IconH2Box = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }
  .cls-2 {
    fill: #fff;
    font-size: 20px;
    font-family: Roboto-Bold, Roboto;
    font-weight: 700;
    letter-spacing: -0.016em;
  }
`

export const IconH2 = ({ className = '', ...props }) => (
  <IconH2Box className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
      <g id="baseline-format_bold-24px" transform="translate(1)">
        <path id="Path_513" d="M0 0h24v24H0z" className="cls-1" data-name="Path 513" />
        <text id="H2" className="cls-2" transform="translate(12 19)">
          <tspan x="-12.646" y="0">
            H2
          </tspan>
        </text>
      </g>
    </svg>
  </IconH2Box>
)
