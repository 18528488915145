import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components/macro'
import classNames from 'classnames'
import { compose } from 'recompose'
import { DragDropContext } from 'react-beautiful-dnd'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'

import { withPageLayout } from 'ui/templates'
import {
  DateSelect, Tabs, ControlButtons, Column, Card, NewsSlider,
} from 'features/MaterialGrid'
import hotNewsPlaceholder from 'assets/images/hot-news-placeholder.png'
import { FONTS } from 'constants/fonts'
import { POSTS } from 'api/posts'

const MaterialGridBox = styled.div`
  padding: 46px 60px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f9fafc;

  .page-controls {
    margin-bottom: -60px;
    position: relative;
    left: -59px;
    width: calc(100% + 120px);
    box-sizing: border-box;
    padding-right: 75px;
    padding-left: 59px;
    padding-top: 50px;
    padding-bottom: 22px;
    top: -45px;
    display: flex;
    align-items: center;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.16);
    z-index: 1000;
    background: #f9fafc;

    @media screen and (max-width: 1649px) {
      width: 96%;
    }
  }

  .grid-wrapper {
    width: calc(100vw - 168px);
    height: calc(100vh - 100px);

    @media screen and (max-width: 1649px) {
      height: calc(100vh * 1.45);
      width: calc(100vw * 1.25 - 108px);

      .page-controls {
        width: 100%;
      }
    }

    @media screen and (max-width: 1440px) {
      height: calc(100vw * 1.45 - 900px);
      width: calc(100vw * 1.45 - 108px);
    }
  }

  .grid {
    margin-top: 46px;
    display: flex;
  }

  .special-project-unavailabel {
    margin-left: 30px;
    width: 300px;
    height: 50px;
    border-radius: 4px;
    border: dashed 1px #707070;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${FONTS.oswald};
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.69;
    letter-spacing: normal;
    text-align: center;
    color: #d5d5d8;
    text-transform: uppercase;
  }

  .placeholder-grid {
    margin-top: 145px;
    width: calc(100vw - 168px);
    height: calc(100vh - 100px);
    display: flex;

    .placeholder-column {
      margin-right: 30px;
      width: 300px;

      &:last-of-type {
        margin-right: 0;
      }

      &.main {
        margin-right: 90px;
      }

      @keyframes loaderData {
        0% {
          background-position: -400px 0;
        }

        100% {
          background-position: 400px 0;
        }
      }

      .grid-news-placeholder {
        border-radius: 4px;
        margin-bottom: 30px;
        width: 300px;
        height: 200px;
        color: transparent;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: loaderData;
        animation-timing-function: linear;
        background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
        background-size: 800px 50px;
        position: relative;
      }
    }
  }
`


const unavailableSpecialProjects = ['Мнения', 'Авторы', 'опросы']

const MaterialGrid = () => {
  const [date, setDate] = useState(new Date())
  const [activeTab, setActiveTab] = useState('rubrics')
  const [detectedNews, setDetectedNews] = useState(null)
  const [loading, setLoading] = useState(true)

  const [data, setData] = useState({
    slider: [],
    mainNews: [],
    categoriesNews: [],
  })

  const savedState = useRef([
    {
      ...data,
    },
  ])

  const windowWidth = useRef(document.documentElement.clientWidth)

  const { slider } = data
  const { mainNews } = data
  const { categoriesNews } = data

  const saveState = () => {
    savedState.current.push({
      mainNews: [...mainNews],
      categoriesNews: [...categoriesNews.map((category) => ({ ...category, posts: [...category.posts] }))],
      slider: [...slider],
    })
  }

  function setSliderItems(slider) {
    saveState()

    setData({
      ...data,
      slider,
    })
  }

  function setMainNews(mainNews) {
    setData({
      ...data,
      mainNews,
    })
  }

  function setCategoriesNews(categoriesNews) {
    setData({
      ...data,
      categoriesNews,
    })
  }

  useEffect(() => {
    const body = document.querySelector('body')

    body.style.overflow = 'hidden'

    return () => {
      body.style.overflow = 'auto'
    }
  }, [])

  useEffect((
  ) => {
    setLoading(true)

    async function getGrid() {
      const response = await POSTS.getGrid(moment(date).format('YYYY-MM-DD'))

      setData({
        slider: response.data.slider,
        categoriesNews: response.data.rubrics,
        mainNews: response.data.main,
      })

      setLoading(false)
    }

    getGrid()
  }, [date])

  async function applyGrid() {
    try {
      const newData = {
        date: moment(date).format('YYYY-MM-DD'),
        slider,
        main: mainNews,
        rubrics: categoriesNews,
      }

      await POSTS.updateGrid(newData)

      savedState.current = [{
        ...data,
      }]

      setData({ ...data })
    } catch (error) {
      console.error(error)
    }
  }

  const revertState = () => {
    const { mainNews, categoriesNews, slider } = savedState.current.pop()

    setData({ mainNews, categoriesNews, slider })
  }

  async function setAccessByLink(news) {
    try {
      const _categoriesNews = [...categoriesNews]
      const _mainNews = [...mainNews]

      const newsInCategories = _categoriesNews.find((c) => c._id === news.rubric._id).posts.find((n) => news._id === n._id)
      newsInCategories.accessLinkOnly = !newsInCategories.accessLinkOnly

      const newsInMainNews = _mainNews.find((n) => news._id === n._id)

      if (newsInMainNews) {
        newsInMainNews.accessLinkOnly = !newsInMainNews.accessLinkOnly
      }

      await POSTS.update({ ...newsInCategories, regenerate: true })

      setCategoriesNews(_categoriesNews)
      setMainNews(_mainNews)
    } catch (error) {
      console.error(error)
    }
  }

  const addToMainNews = async (news) => {
    const newsAlreadyOnMainNews = mainNews.find((n) => n._id === news._id)

    if (newsAlreadyOnMainNews) {
      const index = mainNews.indexOf(newsAlreadyOnMainNews)

      setMainNews([...mainNews.slice(0, index), ...mainNews.slice(index + 1)])

      await POSTS.update({ ...news, mainRejected: true, regenerate: true })

      return
    }

    setMainNews([...mainNews, news])

    await POSTS.update({ ...news, mainRejected: false, regenerate: true })
  }

  const setHotNews = (categoryIndex, news) => {
    saveState()

    const category = categoriesNews[categoryIndex]

    categoriesNews[categoryIndex] = { ...category, hot: null, posts: [news, ...category.posts] }

    if (category.hot?.rubric && category.hot._id === news._id) {
      setCategoriesNews([...categoriesNews])

      return
    }

    const currentNews = category.posts.find((n) => n._id === news._id)
    const newsIndex = category.posts.indexOf(currentNews)


    if (category.hot?.rubric && category.hot._id !== news._id) {
      categoriesNews[categoryIndex] = {
        ...category,
        hot: news,
        posts: [category.hot, ...category.posts.slice(0, newsIndex), ...category.posts.slice(newsIndex + 1)],
      }

      setCategoriesNews([...categoriesNews])

      return
    }

    categoriesNews[categoryIndex] = {
      ...category,
      hot: news,
      posts: [...category.posts.slice(0, newsIndex), ...category.posts.slice(newsIndex + 1)],
    }

    setCategoriesNews([...categoriesNews])
  }

  const moveUp = (categoryIndex, newsIndex) => {
    saveState()

    if (categoryIndex === 'main') {
      setMainNews([mainNews[newsIndex], ...mainNews.filter((n) => n._id !== mainNews[newsIndex]._id)])

      return
    }

    const newCategoriesNews = [...categoriesNews]
    const category = newCategoriesNews[categoryIndex]

    category.posts = [category.posts[newsIndex], ...category.posts.filter((n) => n._id !== category.posts[newsIndex]._id)]

    setCategoriesNews(newCategoriesNews)
  }

  const moveDown = (categoryIndex, newsIndex) => {
    saveState()

    if (categoryIndex === 'main') {
      setMainNews([...mainNews.filter((n) => n._id !== mainNews[newsIndex]._id), mainNews[newsIndex]])

      return
    }

    const newCategoriesNews = [...categoriesNews]
    const category = newCategoriesNews[categoryIndex]

    category.posts = [...category.posts.filter((n) => n._id !== category.posts[newsIndex]._id), category.posts[newsIndex]]

    setCategoriesNews(newCategoriesNews)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    saveState()

    const newCategoriesNews = [...categoriesNews]
    const souceIndex = result.source.index
    const destinationIndex = result.destination.index

    const { droppableId } = result.destination

    if (droppableId === 'main') {
      const posts = [...mainNews]

      const [removed] = posts.splice(souceIndex, 1)
      posts.splice(destinationIndex, 0, removed)

      setMainNews(posts)

      return
    }

    const categoryIndex = Number(droppableId)
    const category = newCategoriesNews[categoryIndex]
    const { posts } = category

    const [removed] = posts.splice(souceIndex, 1)
    posts.splice(destinationIndex, 0, removed)

    category.posts = posts

    setCategoriesNews(newCategoriesNews)
  }

  const removeFromGrid = (categoryIndex, newsIndex) => {
    saveState()

    if (categoryIndex === 'main') {
      const news = mainNews[newsIndex]
      const categoryId = news.category._id
      const newCategoriesNews = [...categoriesNews]

      const category = newCategoriesNews.find((c) => c._id === categoryId)
      const newsInCategoryNews = category.posts.find((n) => n._id === news._id)
      const indexInCategoryNews = category.posts.indexOf(newsInCategoryNews)

      category.posts = [...category.posts.slice(0, indexInCategoryNews), ...category.posts.slice(indexInCategoryNews + 1)]

      setMainNews([...mainNews.slice(0, newsIndex), ...mainNews.slice(newsIndex + 1)])
      setCategoriesNews(newCategoriesNews)

      return
    }

    const newCategoriesNews = [...categoriesNews]
    const category = newCategoriesNews[categoryIndex]

    const newsInMainNews = mainNews.find((n) => n._id === category.posts[newsIndex]._id)

    category.posts = [...category.posts.slice(0, newsIndex), ...category.posts.slice(newsIndex + 1)]

    if (newsInMainNews) {
      const indexInMainNews = mainNews.indexOf(newsInMainNews)

      setMainNews([...mainNews.slice(0, indexInMainNews), ...mainNews.slice(indexInMainNews + 1)])
    }

    setCategoriesNews(newCategoriesNews)
  }

  const addToSlider = (news) => {
    const newsAlreadyInSlider = slider.find((n) => n._id === news._id)

    if (newsAlreadyInSlider) {
      const index = slider.indexOf(newsAlreadyInSlider)

      setSliderItems([...slider.slice(0, index), ...slider.slice(index + 1)])

      return
    }

    setSliderItems([...slider, news])
  }

  return (
    <MaterialGridBox>
      <div className="page-controls">

        <DateSelect date={date} setDate={setDate} />

        <Tabs
          tabs={[{ name: 'Рубрики', value: 'rubrics' }, { name: 'Спецпроекты', value: 'special-projects' }]}
          activeTab={activeTab}
          onTabSelect={setActiveTab}
        />

        <ControlButtons onRevert={revertState} hideRevert={savedState.current.length < 2} applyGrid={applyGrid} hideApply={savedState.current.length === 1} />
      </div>

      {loading && (
      <div className="placeholder-grid">
        {Array.from({ length: 5 }).map((_, index) => (
          <div className={classNames('placeholder-column', { main: index === 0 })} key={index}>
            {Array.from({ length: 4 }).map((_, index) => <div className="grid-news-placeholder" key={index} />)}
          </div>
        ))}
      </div>
      )}

      {!loading && (
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="grid-wrapper">

          <Scrollbars autoHeight autoHeightMin={800} autoHeightMax={windowWidth.current > 1649 ? 'calc(100vh - 85px)' : 'calc(100vw * 1.45 - 900px)'}>
            <div className="grid">
              <Column name="Главная" cards={mainNews} main droppableId="main" addToMainNews={addToMainNews}>
                <div className="cards">
                  <NewsSlider setSliderItems={setSliderItems} items={slider} mainNews={mainNews} addToMainNews={addToMainNews} />

                  {mainNews.filter((news) => !slider.find((n) => n._id === news._id)).map((card, cardIndex) => (
                    <Card
                      news={card}
                      key={card._id}
                      index={cardIndex}
                      type="columnmain"
                      columnId="main"
                      hideHot
                      addToMainNews={addToMainNews}
                      inSlider={slider.map((news) => news._id).includes(card._id)}
                      inMainNews
                      fromMainNews
                      hideMoveUp={cardIndex === 0}
                      hideMoveDown={cardIndex === mainNews.length - 1}
                      moveUp={() => moveUp('main', cardIndex)}
                      moveDown={() => moveDown('main', cardIndex)}
                      removeFromGrid={() => removeFromGrid('main', cardIndex)}
                      detectNews={() => setDetectedNews(card._id)}
                      detected={detectedNews === card._id}
                      addToSlider={addToSlider}
                      setAccessByLink={setAccessByLink}
                    />
                  ))}
                </div>
              </Column>

              {categoriesNews
                .filter((c) => (activeTab === 'rubrics' ? !c.special : c.special))
                .map((category) => {
                  const categoryIndex = categoriesNews.indexOf(category)

                  return (
                    <Column name={category.name} key={category._id} droppableId={categoryIndex}>
                      {category.hot?.rubric && (
                        <Card
                          news={category.hot}
                          key={category.hot._id}
                          index={0}
                          hot
                          hideVisibilityTime
                          draggable={false}
                          setHotNews={(news) => setHotNews(categoryIndex, news)}
                          inSlider={slider.map((news) => news._id).includes(category.hot._id)}
                          inMainNews={mainNews.map((news) => news._id).includes(category.hot._id)}
                          addToSlider={addToSlider}
                          addToMainNews={addToMainNews}
                          detectNews={() => setDetectedNews(category.hot._id)}
                          hideMain={slider.find((n) => n._id === category.hot._id)}
                        />
                      )}

                      {!category.hot?.rubric && <img className="hot-news-placeholder" src={hotNewsPlaceholder} alt="hot" />}
                      <div className="cards">
                        {category.posts.map((card, cardIndex) => (
                          <Card
                            news={card}
                            key={card._id}
                            index={cardIndex}
                            type={`column${category._id}`}
                            columnId={categoryIndex}
                            setHotNews={(news) => setHotNews(categoryIndex, news)}
                            addToMainNews={addToMainNews}
                            inMainNews={mainNews.map((news) => news._id).includes(card._id)}
                            inSlider={slider.map((news) => news._id).includes(card._id)}
                            hideMoveUp={cardIndex === 0}
                            hideMoveDown={cardIndex === category.posts.length - 1}
                            hideMain={slider.find((n) => n._id === card._id)}
                            moveUp={() => moveUp(categoryIndex, cardIndex)}
                            moveDown={() => moveDown(categoryIndex, cardIndex)}
                            removeFromGrid={() => removeFromGrid(categoryIndex, cardIndex)}
                            detectNews={() => setDetectedNews(card._id)}
                            detected={detectedNews === card._id}
                            addToSlider={addToSlider}
                            setAccessByLink={setAccessByLink}
                          />
                        ))}
                      </div>
                    </Column>
                  )
                })}

              {activeTab === 'special-projects'
              && unavailableSpecialProjects.map((name) => <div className="special-project-unavailabel">{name}</div>)}
            </div>
          </Scrollbars>
        </div>
      </DragDropContext>
      )}
    </MaterialGridBox>
  )
}

const enhanced = compose(withPageLayout)(MaterialGrid)

export { enhanced as MaterialGrid }
