import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'

export const withAccess = (roles = []) => (Component) => (props) => {
  const user = useSelector((state) => state.user.info)

  if (!user.role) {
    return <Redirect to="/login" />
  }

  if (!roles.includes(user.role)) {
    return <Redirect to="/" />
  }

  return <Component {...props} />
}
