import React from 'react'
import styled from 'styled-components/macro'

const IconShuffleBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  .cls-1 {
    fill: #fff;
    stroke: #fff;
    stroke-width: 0.5px;
  }
`

export const IconShuffle = ({ className = '', ...props }) => (
  <IconShuffleBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.607"
      height="16.718"
      viewBox="0 0 16.607 16.718"
    >
      <g id="shuffle-arrow" transform="translate(.25 -16.757)">
        <g id="Group_398" data-name="Group 398" transform="translate(0 17.11)">
          <g id="Group_397" data-name="Group 397">
            <path
              id="Path_532"
              d="M223.7 21.243h3.133l-1.867 1.867.933.933 3.533-3.467-3.532-3.466-.933.933 1.867 1.867h-3.667l-2.191 2.267.933.933z"
              className="cls-1"
              data-name="Path 532"
              transform="translate(-213.433 -17.11)"
            />
            <path
              id="Path_533"
              d="M11.533 113.613l1.867 2.03h-3.133l-8-8.7H0v1.45h1.733l3.333 3.626-3.333 3.626H0v1.45h2.267L6 113.033l3.733 4.061H13.4l-1.867 2.03.933 1.015 3.534-3.77-3.533-3.769z"
              className="cls-1"
              data-name="Path 533"
              transform="translate(0 -104.142)"
            />
          </g>
        </g>
      </g>
    </svg>
  </IconShuffleBox>
)
