import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import classNames from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'
import { useOnClickOutside } from 'hooks'

const AuthorBox = styled.div`
  margin-top: 11px;
  position: relative;

  .author-wrapper {
    height: 28px;
    width: 100%;
    border-radius: 14px;
    border: solid 1px #484b50;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &.opened {
      border-color: transparent;
      z-index: 3;

      .icon-arrow {
        transform: rotate(180deg)
      }
    }

    &.active {
      z-index: 3;
    }

    .author {
      height: 100%;
      width: 100%;
      padding-left: 8px;
      display: flex;
      align-items: center;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: left;
      color: black;
      cursor: pointer;
    }

    .author-input {
      border-radius: 14px;
      background-color: #f5f5f5;
      width: 100%;
      height: 100%;
      padding: 0 8px;
      box-sizing: border-box;
      border: none;
      outline: none;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }

    .pseudonym-input {
      border: none;
      outline: none;
      width: 250px;
      font-family: ${FONTS.roboto};
      font-size: 16px;
    }

    .enter {
      position: absolute;
      top: 1px;
      right: 1px;
      width: 40px;
      height: 26px;
      border-radius: 14px;
      background-color: black;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon-arrow {
      position: absolute;
      right: 4px;
      cursor: pointer;
    }
  }

  .authors-dropdown {
    position: absolute;
    min-width: 210px;
    width: calc(100% + 2px);
    border-radius: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    border: solid 2px #c3b29b;
    background-color: white;
    padding-top: 35px;
    top: -2px;
    left: -2px;
    overflow: hidden;
    display: none;
    z-index: 2;

    &.opened {
      display: block;
    }

    .option {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      padding: 0 8px 0 6px;
      cursor: pointer;

      &:hover {
        background-color: #e1dad1;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .white-zone {
      padding-bottom: 5px;
    }

    .gray-zone {
      padding-top: 5px;
      background: #d5d5d8;

      .dot {
        background: #484b50;
      }

      .option {
        &:hover {
          background: #ebebed;
        }

        .checkbox {
          .checkbox-empty {
            border-color: white;
          }
        }
      }
    }

    .dot {
      margin-right: 6px;
      min-width: 6px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #c13b3b;
    }

    .author {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      box-sizing: border-box;

      .name {
        margin-top: -2px;
        font-family: ${FONTS.roboto};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: black;
      }
    }
  }

  .selected-author {
    margin-top: 12px;
    height: 28px;
    border-radius: 14px;
    border: solid 1px #484b50;
    background-color: #f5f5f5;
    width: max-content;
    display: flex;
    align-items: center;
    padding: 0 8px 0 2px;

    .name {
      margin-top: -2px;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }
  }
`

export const Author = ({ authorId, setAuthor }) => {
  const [opened, toggleDropdown] = useState(false)
  const [search, setSearch] = useState('')
  const authors = useSelector((state) => state.news.authors)
  const dropdownRef = useRef()
  const togglerRef = useRef()

  const currentAuthor = authorId ? authors.find((a) => a._id === authorId) : { name: '' }

  useOnClickOutside(dropdownRef, () => toggleDropdown(false), [togglerRef])

  function toggleAuthor(e, author) {
    e.preventDefault()

    setAuthor(author._id)
  }

  if (!authors.length) {
    return null
  }

  return (
    <AuthorBox>
      <div className={classNames('author-wrapper', { opened })} onClick={() => toggleDropdown(true)} ref={togglerRef}>
        {!opened ? (
          <div className="author">
            {
            currentAuthor ? (
              <span>
                {currentAuthor.name}
              </span>
            ) : 'Все авторы'
          }
          </div>
        ) : <input className="author-input" onChange={(e) => setSearch(e.target.value)} placeholder="Поиск" />}
        <Icon type="arrow" />
      </div>

      <div className={classNames('authors-dropdown', { opened })} ref={dropdownRef}>
        <Scrollbars autoHeight autoHeightMax="400px" renderTrackHorizontal={() => <div />}>
          <div className="white-zone">
            {authors.filter((author) => author.relevant).filter((author) => author.name.toLowerCase().startsWith(search.toLowerCase())).map((author) => (
              <div className="option" key={author._id} onClick={(e) => toggleAuthor(e, author)}>
                <div className="dot" />
                <div className="author">
                  <div className="name">{author.name}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="gray-zone">
            {authors.filter((author) => !author.relevant).filter((author) => author.name.toLowerCase().startsWith(search.toLowerCase())).map((author) => (
              <div className="option" key={author._id} onClick={(e) => toggleAuthor(e, author)}>
                <div className="dot" />
                <div className="author">
                  <div className="name">{author.name}</div>
                </div>
              </div>
            ))}
          </div>
        </Scrollbars>
      </div>

    </AuthorBox>
  )
}

export const Pseudonym = ({ pseudonym = '', setPseudonym }) => {
  const [opened, toggleDropdown] = useState(false)
  const pseudonyms = useSelector((state) => state.news.pseudonyms)
  const [inputValue, setInputValue] = useState(pseudonym)
  const dropdownRef = useRef()
  const authorRef = useRef()
  const timeout = useRef()
  const inputRef = useRef()

  const onEnterPress = () => {
    setPseudonym(inputValue)
    toggleDropdown(false)
  }

  const onInputChange = (e) => {
    const { value } = e.target
    setInputValue(value)

    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    timeout.current = setTimeout(() => {
      setPseudonym(value)
    }, 500)
  }

  function onPseudonymSelect(p) {
    setInputValue(p)
    setPseudonym(p)
    toggleDropdown(false)
  }

  useOnClickOutside(dropdownRef, () => toggleDropdown(false), [authorRef])

  return (
    <AuthorBox>
      <div className={classNames('author-wrapper', { active: opened })} ref={authorRef}>
        <div className="author">
          <input
            className="pseudonym-input"
            value={inputValue}
            onChange={onInputChange}
            ref={inputRef}
            placeholder="Псевдоним"
            onFocus={() => toggleDropdown(true)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                inputRef.current.blur()
                e.preventDefault()
                onEnterPress()
              }
            }}
          />
          <div className="enter" onClick={onEnterPress}>
            <Icon type="enter" />
          </div>
        </div>
      </div>

      {/* {opened && ( */}
      <div className={classNames('authors-dropdown', { opened })} ref={dropdownRef}>
        <Scrollbars autoHeight autoHeightMax="400px" renderTrackHorizontal={() => <div />}>
          <div className="white-zone">
            {pseudonyms.filter((p) => p.toLowerCase().startsWith(inputValue.toLowerCase())).map((p) => (
              <div className="option" key={p} onClick={() => onPseudonymSelect(p)}>
                <div className="author">
                  <div className="dot" />
                  <div className="name">{p}</div>
                </div>
              </div>
            ))}
          </div>
        </Scrollbars>
      </div>
      {/* )} */}
    </AuthorBox>
  )
}
