import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'

import hotNewsPlaceholder from 'assets/images/hot-news-placeholder.png'

const NewsPlaceholderBox = styled.div`
  margin-bottom: 30px;
  width: 300px;
  height: 201px;

  .hot-news-placeholder {
    width: 100%;
    height: 100%;
  }
`

const NewsSliderBox = styled.div`
  margin-bottom: 30px;
  width: 300px;
  min-height: 201px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f3e5;
  padding: 16px 0 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;

  .category {
    padding: 0 12px;
    font-family: ${FONTS.roboto};
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.83;
    letter-spacing: 0.9px;
    text-align: left;
    color: #c13b3b;
    text-transform: uppercase;
  }

  .news-title {
    padding: 0 12px;
    margin-top: 6px;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: -0.14px;
    text-align: left;
    color: black;
  }

  .news-lead {
    padding: 0 12px;
    margin-top: 4px;
    font-family: ${FONTS.roboto};
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: -0.12px;
    text-align: left;
    color: rgba(29, 31, 36, 0.4);
  }

  .controls {
    margin-top: auto;
    margin-bottom: 12px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .fire-wrapper {
      display: flex;
      align-items: center;
      background-color: #c13b3b;
      padding-right: 10px;
      border-radius: 16px;
      color: white;
      font-family: ${FONTS.oswald};
      font-size: 15px;

      .text {
        margin-top: -2px;
      }

      .icon-fire {
        margin-right: 2px;
      }
    }

    .slider-controls {
      display: flex;
      align-items: center;
      height: 24px;
      border-radius: 34px;
      background-color: #d8d1bd;

      .arrow {
        cursor: pointer;

        &.prev {
          transform: rotate(90deg);
        }

        &.next {
          transform: rotate(-90deg);
        }
      }

      .dots {
        margin: 0 8px;
        display: flex;
        align-items: center;

        .dot {
          margin-right: 6px;
          width: 4px;
          height: 4px;
          border-radius: 6px;
          background-color: #484b50;

          &.active {
            background-color: #ce914a;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .open-slider-panel {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: #484b50;
      transform: rotate(-90deg);
      cursor: pointer;

      .cls-1 {
        fill: white;
      }
    }
  }

  .slider-panel {
    z-index: 32;
    padding: 50px 205px;
    box-sizing: border-box;
    position: absolute;
    width: 1700px;
    top: -20px;
    border-radius: 4px;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: rgba(72, 75, 80, 0.95);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .icon-cross {
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer;

      .cls-2 {
        stroke: white;
      }
    }

    .clear {
      height: 36px;
      padding: 0 16px;
      background: white;
      position: absolute;
      right: 12px;
      bottom: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.25px;
      text-align: left;
      color: #c13b3b;
      text-transform: uppercase;
      border-radius: 21px;
    }

    .slider-news-card {
      margin-bottom: 20px;
      min-width: 300px;
      max-width: 300px;
      height: 201px;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: #ffffff;
      padding-top: 16px;
      display: flex;
      flex-direction: column;

      &:last-of-type {
        margin-right: 0;
      }

      .card-controls {
        margin-top: auto;
        width: 100%;
        height: 48px;
        background-color: #f5f5f5;
        padding: 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .icon-wrapped-home {
          margin-left: auto;
          cursor: pointer;

          &:hover {
            .cls-1 {
              fill: #c0c0c1;
            }
          }

          &.active {
            .cls-1 {
              fill: #484b50;
            }
          }
        }

        .icon-wrapped-eye {
          cursor: pointer;
          margin-left: auto;
        }

        .icon-slider {
          margin-left: 10px;
          cursor: pointer;

          .cls-1 {
            fill: #c13b3b;
          }

          &:hover {
            opacity: 0.9
          }
        }

        .move {
          cursor: pointer;

          &:hover {
            .cls-1 {
              fill: #c0c0c1;
            }
          }

          &.left {
            margin-right: 16px;
            transform: rotate(-90deg);
          }

          &.right {
            transform: rotate(90deg);
          }
        }
      }
    }

    .slide-placeholder {
      margin-bottom: 20px;
      width: 300px;
      height: 201px;
      border-radius: 4px;
      border: dashed 1px #ffffff;
      box-sizing: border-box;
    }
  }
`

export const NewsSlider = ({
  items, setSliderItems, addToMainNews, mainNews,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderPanelOpened, toggleSliderPanel] = useState(false)

  useEffect(() => {
    if (currentSlide > items.length - 1) {
      setCurrentSlide(0)
    }
  }, [items, currentSlide])

  const goNext = () => {
    if (currentSlide === items.length - 1) {
      setCurrentSlide(0)

      return
    }

    setCurrentSlide(currentSlide + 1)
  }

  const goPrev = () => {
    if (currentSlide === 0) {
      setCurrentSlide(items.length - 1)

      return
    }

    setCurrentSlide(currentSlide - 1)
  }

  const moveToPrev = (index) => {
    setSliderItems([
      ...items.slice(0, index - 1),
      items[index],
      items[index - 1],
      ...items.slice(index + 1),
    ])
  }

  const moveToNext = (index) => {
    setSliderItems([
      ...items.filter((_, i) => i < index),
      items[index + 1],
      items[index],
      ...items.slice(index + 2),
    ])
  }

  const removeFormSlider = (index) => {
    setSliderItems([
      ...items.slice(0, index),
      ...items.slice(index + 1),
    ])
  }

  const clear = () => {
    toggleSliderPanel(false)
    setSliderItems([])
  }

  const currentNews = items[currentSlide]

  if (!items.length) {
    return (
      <NewsPlaceholderBox>
        <img className="hot-news-placeholder" src={hotNewsPlaceholder} alt="hot" />
      </NewsPlaceholderBox>
    )
  }

  return (
    <NewsSliderBox>
      <div className="category">{currentNews.rubric.name}</div>

      <div className="news-title">{currentNews.title}</div>

      <div className="news-lead">{currentNews.lead}</div>

      <div className="controls">
        {items.length > 1 ? (
          <div className="fire-wrapper">
            <Icon type="fire" />

            <span className="text">{`+${items.length - 1}`}</span>
          </div>
        ) : (
          <Icon type="fire" />
        )}

        {items.length > 1 && (
          <div className="slider-controls">
            <Icon type="arrow" className="arrow prev" onClick={goPrev} />

            <div className="dots">
              {items.map((n, index) => (
                <div
                  className={classNames('dot', { active: currentSlide === index })}
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </div>

            <Icon type="arrow" className="arrow next" onClick={goNext} />
          </div>
        )}

        <Icon type="arrow" className="open-slider-panel" onClick={() => toggleSliderPanel(true)} />
      </div>

      {sliderPanelOpened && (
        <div className="slider-panel">
          <Icon type="cross" onClick={() => toggleSliderPanel(false)} />

          <div className="clear" onClick={clear}>Очистить</div>

          {items.map((n, index) => {
            const inMainNews = mainNews.find((news) => n._id === news._id)

            return (
              <div className="slider-news-card" key={n.id}>
                <div className="category">{n.rubric.name}</div>

                <div className="news-title">{n.title}</div>

                <div className="news-lead">{n.lead}</div>

                <div className="card-controls">
                  {index !== 0 && <Icon type="move" className="move left" onClick={() => moveToPrev(index)} />}

                  {index !== items.length - 1 && (
                  <Icon type="move" className="move right" onClick={() => moveToNext(index)} />
                  )}

                  <Icon
                    type="wrapped-home"
                    className={classNames({ active: inMainNews })}
                    onClick={() => addToMainNews(n)}
                  />

                  <Icon
                    type="slider"
                    onClick={() => removeFormSlider(index)}
                  />
                </div>
              </div>
            )
          })}

          {
            Array.from({ length: 8 - items.length }).map(() => <div className="slide-placeholder" />)
          }
        </div>
      )}
    </NewsSliderBox>
  )
}
