import React from 'react'
import styled from 'styled-components/macro'

const IconTimerBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }
  .cls-2 {
    fill: #1d1f24;
  }
`

export const IconTimer = ({ className = '', ...props }) => (
  <IconTimerBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="ic_timer"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Rectangle_439" d="M0 0h24v24H0z" className="cls-1" data-name="Rectangle 439" />
      <g id="outline-timer-24px_sharp" data-name="outline-timer-24px sharp">
        <path id="Path_156" d="M0 0h24v24H0z" className="cls-1" data-name="Path 156" />
        <path
          id="Path_157"
          d="M15.07 1.01h-6v2h6zm-4 13h2v-6h-2zm8.03-6.62l1.42-1.42a11.049 11.049 0 0 0-1.41-1.41l-1.42 1.42a9 9 0 1 0 1.41 1.41zm-7.03 12.62a7 7 0 1 1 7-7 6.995 6.995 0 0 1-7 7z"
          className="cls-2"
          data-name="Path 157"
        />
      </g>
    </svg>
  </IconTimerBox>
)
