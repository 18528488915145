import React from 'react'
import styled from 'styled-components/macro'

const IconImageBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #fff;
  }

  .cls-2 {
    fill: none;
  }
`

export const IconImage = ({ className = '', ...props }) => (
  <IconImageBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="baseline-insert_photo-24px"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_520"
        d="M21 19V5a2.006 2.006 0 0 0-2-2H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5z"
        className="cls-1"
        data-name="Path 520"
      />
      <path id="Path_521" d="M0 0h24v24H0z" className="cls-2" data-name="Path 521" />
    </svg>
  </IconImageBox>
)
