import React from 'react'
import styled from 'styled-components/macro'

const IconBoldBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: none;
  }
`

export const IconBold = ({ className = '', ...props }) => (
  <IconBoldBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="baseline-format_bold-24px"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_512"
        d="M15.6 10.79A3.641 3.641 0 0 0 17.25 8a3.943 3.943 0 0 0-4-4H7v14h7.04a3.793 3.793 0 0 0 1.56-7.21zM10 6.5h3a1.5 1.5 0 0 1 0 3h-3zm3.5 9H10v-3h3.5a1.5 1.5 0 0 1 0 3z"
        className="cls-1"
        data-name="Path 512"
        transform="translate(0 1)"
      />
      <path id="Path_513" d="M0 0h24v24H0z" className="cls-2" data-name="Path 513" />
    </svg>
  </IconBoldBox>
)
