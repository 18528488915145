import React, { useState } from 'react'
import styled from 'styled-components/macro'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { isJSON } from 'helpers'
import { DRAGGABLE_NEWS } from 'constants/app'
import { Icon } from 'ui/atoms'
import placeholderImg from 'assets/images/links-dropzone-preview.png'
import { EditorToolbar } from './EditorToolbar'
import { withEditorContainer, Save } from './EditorContainer'

const LinksEditorBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(.active) {
    .links {
      .link {
        .delete-link {
          display: none;
        }
      }
    }
  }

  .placeholder-text {
    position: absolute;
    width: 313px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${FONTS.roboto};
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: black;
  }

  .placeholder-image {
    position: absolute;
    left: 74px;
    top: 14px;
    width: 389px;
    height: 169px;
  }

  .links {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 568px;

    .link {
      margin-bottom: 20px;
      width: 100%;
      background: white;
      padding: 16px 16px 24px;
      position: relative;
      box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.05);

      .link-info {
        opacity: 0.7;
        font-family: ${FONTS.lato};
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: black;
      }

      .link-title {
        margin-top: 9px;
        font-family: ${FONTS.oswald};
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: -0.1px;
        text-align: left;
        color: black;
      }

      .link-text {
        height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 8px;
        text-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        font-family: Lato;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: black;
      }
    }

    .delete-link {
      position: absolute;
      top: 0;
      right: -36px;
      width: 36px;
      height: 100%;
      opacity: 0.4;
      background-color: #d5d5d8;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`

const LinksEditor = ({
  active, onSave, deactivate, initialState,
}) => {
  const [links, setLinks] = useState(initialState)

  const onDropLink = (e) => {
    if (!active) {
      return
    }

    const data = e.dataTransfer.getData('text')

    if (!isJSON(data) && !JSON.parse(data).type !== DRAGGABLE_NEWS) {
      return
    }

    const newLink = JSON.parse(data)

    if (links.find((link) => link._id === newLink._id)) {
      return
    }

    setLinks([...links, newLink])
  }

  const deleteLink = (index) => {
    setLinks([...links.slice(0, index), ...links.slice(index + 1)])
  }

  const _onSave = () => {
    const html = null

    onSave({ html, params: { links } })

    deactivate()
  }

  return (
    <>
      <LinksEditorBox className={classNames({ active })} onDrop={onDropLink}>
        {links.length ? (
          <div className="links">
            {links.map((link, index) => (
              <div className="link" key={index}>
                <div className="link-info">
                  {link.date}
                  {' '}
                  -
                  {' '}
                  {link.rubric.name}
                </div>

                <div className="link-title">{link.title}</div>

                <div className="link-text">{link.lead}</div>

                <div className="delete-link" onClick={() => deleteLink(index)}>
                  <Icon type="cross" />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <img src={placeholderImg} className="placeholder-image" alt="" />
            <div className="placeholder-text">Перетащите статьи связанные с инфоповодом материала, из поиска.</div>
          </>
        )}

        {active && <EditorToolbar blockTypeIcon="shuffle" />}
      </LinksEditorBox>

      <Save onSave={_onSave} active={active} />
    </>
  )
}

const enhanced = withEditorContainer(LinksEditor, 'links')

export { enhanced as LinksEditor }
