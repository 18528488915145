export const getSelectionRange = () => {
  const selection = window.getSelection()

  if (selection.rangeCount === 0) return null

  return selection.getRangeAt(0)
}

export const getSelectionCoords = (selectionRange, container) => {
  const editorBounds = container.getBoundingClientRect()
  const rangeBounds = selectionRange.getBoundingClientRect()

  const rangeWidth = rangeBounds.right - rangeBounds.left
  const offsetLeft = rangeBounds.left - editorBounds.left + rangeWidth / 2 - 107 / 2
  const offsetTop = rangeBounds.top - editorBounds.top - 42

  return { offsetLeft, offsetTop }
}

export function isJSON(str) {
  try {
    return JSON.parse(str) && !!str
  } catch (error) {
    return false
  }
}
