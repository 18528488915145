import React from 'react'
import styled from 'styled-components/macro'

const IconPauseBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1{fill:#484b50}.cls-2{fill:#fff}
`

export const IconPause = ({ className = '', ...props }) => (
  <IconPauseBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Ic_home" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_1202" width="24" height="24" className="cls-1" data-name="Rectangle 1202" rx="4" />
      <path id="Rectangle_1315" d="M0 0h3v12H0z" className="cls-2" data-name="Rectangle 1315" transform="translate(8 6)" />
      <path id="Rectangle_1316" d="M0 0h3v12H0z" className="cls-2" data-name="Rectangle 1316" transform="translate(13 6)" />
    </svg>
  </IconPauseBox>
)
