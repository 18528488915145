import React from 'react'
import styled from 'styled-components/macro'

const IconItalicBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }
  .cls-2 {
    fill: #fff;
  }
`

export const IconItalic = ({ className = '', ...props }) => (
  <IconItalicBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="baseline-format_italic-24px"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_514" d="M0 0h24v24H0z" className="cls-1" data-name="Path 514" />
      <path
        id="Path_515"
        d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z"
        className="cls-2"
        data-name="Path 515"
        transform="translate(0 1)"
      />
    </svg>
  </IconItalicBox>
)
