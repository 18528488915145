import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import iconCheckImg from 'assets/images/icon-check.png'

const RadioBox = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 28px;
  border-radius: 4px;
  background-color: #d5d4d9;
  padding: 0 6px;

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    background: #ceccd5;
  }

  &.checked {
    background: #484b50;

    .label {
      color: white;
    }
  }

  &.single {
    background: transparent;

    .checked-circle {
      display: none;
    }
  }

  .label {
    margin-top: -2px;
    font-family: ${FONTS.roboto};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -0.28px;
    text-align: left;
    color: black;
  }

  .circle {
    margin-right: 6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #1d1f24;
    box-sizing: border-box;
  }

  .check-img {
    margin-right: 6px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .checked-circle {
    margin-right: 6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid white;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .circle-inner {
      background: white;
      border-radius: 50%;
      width: 8px;
      height: 8px;
    }
  }

  .hidden-input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
`

export const Radio = ({
  checked, disabled, onChange = () => false, name, label, className, single,
}) => (
  <RadioBox
    className={classNames(className, { disabled, checked, single })}
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      onChange()
    }}
  >
    <input
      type="radio"
      checked={checked}
      name={name}
      // onChange={() => onChange()}
      className="hidden-input"
      readOnly
    />
    {checked ? !single ? <div className="checked-circle"><div className="circle-inner" /></div> : <img src={iconCheckImg} alt="" className="check-img" /> : <div className="circle" />}

    {!!label && <div className="label">{label}</div>}
  </RadioBox>
)
