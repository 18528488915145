import React from 'react'
import styled from 'styled-components/macro'

const IconLibraryBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }

  .cls-2 {
    fill: #fff;
  }
`

export const IconLibrary = ({ className = '', ...props }) => (
  <IconLibraryBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="_24px_1_"
      width="24"
      height="24"
      data-name="24px (1)"
      viewBox="0 0 24 24"
    >
      <path id="Path_568" d="M0 0h24v24H0z" className="cls-1" data-name="Path 568" />
      <path
        id="Path_569"
        d="M4 6H2v14a2.006 2.006 0 0 0 2 2h14v-2H4zm16-4H8a2.006 2.006 0 0 0-2 2v12a2.006 2.006 0 0 0 2 2h12a2.006 2.006 0 0 0 2-2V4a2.006 2.006 0 0 0-2-2zm0 14H8V4h12zM10 9h8v2h-8zm0 3h4v2h-4zm0-6h8v2h-8z"
        className="cls-2"
        data-name="Path 569"
      />
    </svg>
  </IconLibraryBox>
)
