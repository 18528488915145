export const SET_USER_INFO = 'SET_USER_INFO'

export const setUserInfo = (data) => (dispatch) => {
  localStorage.setItem('userInfo', JSON.stringify(data))
  localStorage.setItem('token', data.token)

  dispatch({
    type: SET_USER_INFO,
    payload: data,
  })
}
