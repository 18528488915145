import React from 'react'
import styled from 'styled-components/macro'

const IconFlashBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
`

export const IconFlash = ({ className = '', ...props }) => (
  <IconFlashBox className={className} {...props}>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="cls-1">
      <path d="M13.8636 2.38086L7.79102 14.0562H12.0284V21.6109L17.9121 9.9355H13.8636V2.38086Z" fill="black" />
    </svg>
  </IconFlashBox>
)
