import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'

const ArticlesListBox = styled.div`
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;

  .article {
    margin-right: 50px;
    margin-bottom: 15px;
    width: 586px;
    border-radius: 4px;
    border: solid 1px #ce914a;
    background-color: white;
    padding: 4px 16px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: #f9f7f7;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    .top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .controls {
        display: flex;
        align-items: center;

        .draft {
          opacity: 0.78;
          font-family: ${FONTS.roboto};
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: -0.25px;
          color: #454545;
          padding: 0 8px;
        }

        .edit {
          margin-left: 6px;
          height: 24px;
          padding: 0 6px 0 2px;
          display: flex;
          align-items: center;
          opacity: 0.78;
          font-family: ${FONTS.roboto};
          font-size: 16px;
          background-color: #efefef;
          line-height: 1.38;
          letter-spacing: -0.25px;
          text-align: left;
          color: #454545;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            background-color: #c0c0c1;
          }

          .icon-edit {
            width: 18px;
            height: 18px;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }

        .icon-flash {
          margin-right: 4px;
          background: #C13B3B;
          border-radius: 50%;

          .cls-1 {
            fill: #C13B3B;
          }

          path {
            fill: white;
          }
        }

        .icon-fire {
          cursor: pointer;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;

          .cls-3 {
            fill: #efefef;
          }

          .cls-4 {
            fill: #6b6b6b;
          }

          &.active {
            .cls-3 {
              fill: #c13b3b;
            }

            .cls-4 {
              fill: white;
            }
          }
        }

        .icon-link {
          margin-left: 6px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #efefef;

          .cls-2 {
            fill: #6b6b6b;
          }

          svg {
            transform: rotate(90deg);
            width: 20px;
            height: 20px;
          }

          &.active {
            background: #484b50;

            .cls-2 {
              fill: white;
            }
          }
        }

        .icon-wrapped-home {
          margin-left: 6px;

          .cls-1 {
            fill: #efefef;
          }

          .cls-2 {
            fill: #6b6b6b;
          }

          &.active {
            .cls-1 {
              fill: #484b50;
            }

            .cls-2 {
              fill: white;
            }
          }
        }

        .icon-open {
          margin-left: 6px;
          cursor: pointer;

          &:hover {
            .cls-1 {
              fill: #c0c0c1;
            }
          }
        }
      }
    }

    .date {
      opacity: 0.7;
      font-family: ${FONTS.lato};
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }

    .title {
      margin-top: 7px;
      font-family: ${FONTS.oswald};
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3;
      letter-spacing: -0.1px;
      text-align: left;
      color: black;

      &.red {
        color: red;
      }
    }

    .text {
      margin-top: 8px;
      text-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
      font-family: ${FONTS.lato};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: black;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.red {
        color: red;
      }
    }
  }
`

export const ArticlesList = ({
  onSelect, articles,
}) => {
  const history = useHistory()

  return (
    <ArticlesListBox>
      {articles.map((article, index) => (
        <div className="article" key={article._id} onClick={() => onSelect(index)}>
          <div className="top-row">
            <div className="date">
              {moment(article.date).format('DD MMMM - HH:mm')}
              {' '}
              -
              {' '}
              {article?.rubric?.name}
            </div>

            <div className="controls">
              {
              article.published ? (
                <>
                  {article.type === 'news' && !article.validationEnabled
                    && <Icon type="flash" />}

                  <Icon
                    type="fire"
                    className={classNames({ active: article.type === 'article' ? article.hotBlock : article.hotNews })}
                  />

                  {article.type === 'article' && (
                    <Icon
                      type="wrapped-home"
                      className={classNames({ active: !article.mainRejected })}
                    />
                  )}

                  <Icon
                    type="link"
                    className={classNames({ active: article.accessLinkOnly })}
                  />
                </>
              ) : <span className="draft">Черновик</span>
            }

              <div
                className="edit"
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push(`/redactor/${article.type}/${article._id}`) }}
              >
                <Icon type="edit" />
                Редактировать
              </div>

              {

                !!article.url
                // eslint-disable-next-line
                && (
                <a href={article.url} target="_blank" onClick={(e) => { e.stopPropagation() }}>
                  <Icon type="open" />
                </a>
                )
}

            </div>
          </div>

          {article.title
            ? <div className="title" dangerouslySetInnerHTML={{ __html: article.title }} />
            : <div className="title red">Заголовок не заполнен</div>}
          {!article.lead && <div className="text" dangerouslySetInnerHTML={{ __html: article.lead }} />}
        </div>
      ))}
    </ArticlesListBox>
  )
}
