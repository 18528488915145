import { useEffect } from 'react'

export function useOnClickOutside(ref, handler, additionalRefs = []) {
  useEffect(() => {
    const listener = (event) => {
      if (
        !ref.current
        || ref.current.contains(event.target)
        || additionalRefs.some((ref) => ref && ref.current && ref.current.contains(event.target))
      ) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, additionalRefs])
}
