import {
  SET_ACTIVE_NEWS, SET_AUTHORS_LIST, SET_RUBRICS_LIST, SET_TAGS_LIST, SET_PSEUDONYMS_LIST,
} from '../actions/news'

const initialState = {
  activeNews: null,
  authors: [],
  pseudonyms: [],
  rubrics: [],
  tags: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_NEWS:
      return { ...state, activeNews: action.payload }
    case SET_AUTHORS_LIST:
      return { ...state, authors: action.payload }
    case SET_PSEUDONYMS_LIST:
      return { ...state, pseudonyms: action.payload }
    case SET_RUBRICS_LIST:
      return { ...state, rubrics: action.payload }
    case SET_TAGS_LIST:
      return { ...state, tags: action.payload }
    default:
      return state
  }
}
