import React, { useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'
import { POSTS } from 'api/posts'
import { useOnClickOutside } from 'hooks'

const PhotoSourceBox = styled.div`
  height: 36px;
  position: relative;

  input {
    z-index: 2;
    width: 100%;
    height: 36px;
    border-radius: 4px;
    border: solid 2px #c3b29b;
    background-color: #ffffff;
    outline: none;
    padding: 0 8px;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: black;
    box-sizing: border-box;
    display: block;

    &:hover,
    &:active,
    &:focus {
      border-color: #ce914a;
    }
  }

  .enter {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 26px;
    border-radius: 4px;
    background-color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .options-dropdown {
    position: absolute;
    left: 0;
    top: 36px;
    width: 100%;
    /* max-height: 200px; */
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #c3b29b;
    padding: 4px 0;
    box-sizing: border-box;
    z-index: 10;

    .option {
      width: 100%;
      min-height: 30px;
      cursor: pointer;
      color: black;
      display: flex;
      align-items: center;
      padding: 0 6px;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: black;
      box-sizing: border-box;

      &.selected {
        background: rgba(255, 255, 255, 0.4);
      }

      &:hover {
        background: rgba(255, 255, 255, 0.6);
      }
    }
  }
`

export const PhotoSource = ({ onChange, className = '', value }) => {
  const [availableSources, setAvailableSources] = useState([])
  const [inputValue, setInputValue] = useState(value)
  const [selectedSourceIdx, setSelectedSourceIdx] = useState(0)

  const dropdownRef = useRef()

  useOnClickOutside(dropdownRef, () => setAvailableSources([]))

  const getSuggestions = useCallback(async (value) => {
    setInputValue(value)

    if (!value) {
      setAvailableSources([])
      return
    }

    const response = await POSTS.getPhotoSources({ name: value })

    setAvailableSources(response.data)
  }, [])

  const onSourceSelect = useCallback(
    (source) => {
      onChange(source)
      setAvailableSources([])
      setInputValue(source)
    },
    [onChange],
  )

  const onEnterPress = useCallback(() => {
    if (!inputValue) {
      return
    }

    if (availableSources.length) {
      onSourceSelect(availableSources[selectedSourceIdx].name)

      return
    }

    onSourceSelect(inputValue)
  }, [inputValue, onSourceSelect, availableSources, selectedSourceIdx])

  const handleInputKeydown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onEnterPress()
    }

    if (event.keyCode === 40 && availableSources.length) {
      event.preventDefault()

      setSelectedSourceIdx((currentIndex) => {
        if (currentIndex === availableSources.length - 1) {
          return 0
        }

        return currentIndex + 1
      })
    }

    if (event.keyCode === 38 && availableSources.length) {
      event.preventDefault()

      setSelectedSourceIdx((currentIndex) => {
        if (currentIndex === 0) {
          return availableSources.length - 1
        }

        return currentIndex - 1
      })
    }
  }

  return (
    <PhotoSourceBox className={className}>
      <input
        placeholder="Источник изображения"
        onChange={(e) => getSuggestions(e.target.value)}
        value={inputValue}
        onKeyDown={handleInputKeydown}
      />

      <div className="enter" onClick={onEnterPress}>
        <Icon type="enter" />
      </div>

      {!!availableSources.length && (
      <div className="options-dropdown" ref={dropdownRef}>
        <Scrollbars autoHeight autoHeightMax="400px" renderTrackHorizontal={() => <div />}>
          {availableSources.map((tag, index) => (
            <div key={tag._id} onClick={() => onSourceSelect(tag.name)} className={classNames('option', { selected: index === selectedSourceIdx })}>
              {tag.name}
            </div>
          ))}
        </Scrollbars>
      </div>
      )}
    </PhotoSourceBox>
  )
}
