import React from 'react'
import styled from 'styled-components/macro'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'

const newsFields = [
  {
    name: 'Заголовок',
    show: (data) => !data.requiredBlocks[0].params.text,
  },
  {
    name: 'Фото',
    show: (data) => !data.requiredBlocks[1].params.photoUrl && !data.requiredBlocks[1].params?.photoUrls?.origin,
  },
  {
    name: 'Источник фото',
    show: (data) => !data.requiredBlocks[1].params.source,
  },
  {
    name: 'Текстовый блок',
    show: (data) => !data.requiredBlocks[2].params.text,
  },
  {
    name: 'Рубрика',
    show: (data) => !data.currentRubric || !Object.keys(data.currentRubric).length,
  },
  {
    name: 'Тэги',
    show: (data) => data.selectedTags.length < 3,
  },
  {
    name: 'Описание',
    show: (data) => !data.description,
  },
  // {
  //   name: 'Открытые блоки редактирования',
  // },
]

const articleFields = [
  {
    name: 'Заголовок',
    show: (data) => !data.requiredBlocks[0].params.text,
  },
  {
    name: 'Лид',
    show: (data) => !data.requiredBlocks[1].params.text,
  },
  {
    name: 'Фото',
    show: (data) => !data.requiredBlocks[2].params.photoUrl && !data.requiredBlocks[2].params?.photoUrls?.large,
  },
  {
    name: 'Источник фото',
    show: (data) => !data.requiredBlocks[2].params.source,
  },
  {
    name: 'Текстовый блок',
    show: (data) => !data.requiredBlocks[3].params.text,
  },
  {
    name: 'Рубрика',
    show: (data) => !data.currentRubric || !Object.keys(data.currentRubric).length,
  },
  {
    name: 'Тэги',
    show: (data) => data.selectedTags.length < 3,
  },
  {
    name: 'Описание',
    show: (data) => !data.description,
  },
  // {
  //   name: 'Открытые блоки редактирования',
  // },
]

const NotificationBox = styled.div`
  height: 100px;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: -100px;
  left: 0;
  z-index: 22;
  backdrop-filter: blur(2.7px);
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #c13b3b;
  transition: bottom 0.3s linear;

  &.active {
    bottom: 0;
  }

  .icon-cross {
    position: absolute;
    right: 20px;
    top: 4px;
    background: white;
    cursor: pointer;
  }

  .required-title {
    text-align: center;
    font-family: ${FONTS.roboto};
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: -0.19px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .items-wrapper {
    margin: 0 auto;
    max-width: 1000px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .required-item {
    margin-right: 8px;
    height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.25px;
    text-align: left;
    color: #ffffff;
  } 
`

export const RequiredFieldsNotification = ({
  active, onClose, data, type, isFastNews,
}) => (
  (
    <NotificationBox className={classNames({ active })}>
      <Icon type="cross" onClick={onClose} />

      <div className="required-title">
        Заполните обязательные поля, чтобы опубликовать материал или открыть превью:
        <br />
        <b>ATTENTION:</b>
        {' '}
        УБЕДИТЕСЬ ЧТО ВСЕ БЛОКИ КОНТЕНТА ЗАКРЫТЫ/СОХРАНЕНЫ!!
      </div>

      <div className="items-wrapper">
        {isFastNews ? <div className="required-item">Рубрика</div>
          : (type === 'news' || type === 'longread' ? newsFields : articleFields).map((item) => item.show(data) && <div className="required-item">{item.name}</div>)}
      </div>
    </NotificationBox>
  )
)
