import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { Icon } from 'ui/atoms'
import { Datepicker } from 'ui/molecules'
import { FONTS } from 'constants/fonts'
import { useOnClickOutside } from 'hooks'

const DateSelectBox = styled.div`
  margin-right: 90px;
  width: 300px;
  height: 30px;
  display: flex;
  align-items: center;

  .arrow {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #897b6a;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #a09587;
    }

    &:active {
      background-color: #7b6f5f;
    }

    svg {
      transform: rotate(90deg);

      .cls-1 {
        fill: white;
      }
    }

    &.next {
      svg {
        transform: rotate(-90deg);

        .cls-1 {
          fill: white;
        }
      }
    }
  }

  .date {
    margin-left: 16px;
    width: 170px;
    height: 36px;
    border-radius: 4px;
    border: solid 2px #c3b29b;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: -0.25px;
    text-align: center;
    color: #484b50;
    text-transform: uppercase;
  }

  .calendar {
    margin-left: 2px;
    margin-right: 16px;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    border: solid 2px #c3b29b;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    .icon-calendar {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      .icon-calendar {
        opacity: 0.9;
      }
    }
  }
`

export const DateSelect = ({ date, setDate }) => {
  const [datepickerOpened, toggleDatepicker] = useState(false)

  const datepickerRef = useRef()

  useOnClickOutside(datepickerRef, () => toggleDatepicker(false))

  const onChangeDate = (date) => {
    setDate(date)
  }

  const goToNextDay = () => {
    setDate(moment(date).add(1, 'days').valueOf())
  }

  const goToPrevDay = () => {
    setDate(moment(date).add(-1, 'days').valueOf())
  }

  return (
    <DateSelectBox>
      <Icon type="arrow" className="arrow prev" onClick={goToPrevDay} />

      <div className="date">{moment(date).format('DD MMMM')}</div>

      <div className="calendar">
        <Icon type="calendar" onClick={() => toggleDatepicker(!datepickerOpened)} />

        <Datepicker initialStartDate={date} onSelect={onChangeDate} withoutRange withoutToggle opened={datepickerOpened} onClose={() => toggleDatepicker(false)} />
      </div>

      <Icon type="arrow" className="arrow next" onClick={goToNextDay} />
    </DateSelectBox>
  )
}
