import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { Radio, NotSelected } from 'ui/atoms'

const RubricsBox = styled.div`
  margin-top: 12px;

  .title-row {
    margin-bottom: 8px;
    display: flex;
    align-items:center;
    min-height: 16px;
  }

  .title {
    font-family: ${FONTS.roboto};
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    opacity: 0.6;
    text-align: left;
    color: black;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .radio {
    width: 142px;
    margin-bottom: 8px;
    margin-right: 8px;
    box-sizing: border-box;

    &.long {
      width: 100%;
      margin-right: 0;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .options-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .rubrics-sub-title {
    margin-bottom: 6px;
    font-family: ${FONTS.roboto};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: -0.22px;
    text-align: left;
    color: black;
  }
`

export const Rubrics = ({ currentRubric = {}, setRubric }) => {
  const rubrics = useSelector((state) => state.news.rubrics)

  return (
    <RubricsBox>
      <div className="title-row">
        <div className="title">Рубрика</div>
        {!currentRubric.name && <NotSelected text="не выбрана" />}
      </div>

      <div className="options-wrapper">
        {rubrics.map((rubric) => (
          <Radio
            key={rubric._id}
            label={rubric.name}
            checked={currentRubric._id === rubric._id}
            className={classNames('radio', { long: rubric.name.split(' ').length > 1 })}
            onChange={() => setRubric(rubric)}
          />
        ))}
      </div>

      {/* <div className="rubrics-sub-title">Спец. проект</div>

      {specialRubrics.map((rubric) => (
        <Radio
          key={rubric._id}
          label={rubric.name}
          checked={currentRubric._id === rubric._id}
          className="radio long"
          onChange={() => setRubric(rubric)}
        />
      ))} */}
    </RubricsBox>
  )
}
