import React from 'react'
import { renderRoutes } from 'react-router-config'

import { Login } from 'pages/Login'
import { App } from 'pages/App'

const routes = [
  {
    path: '/login',
    component: Login,
    exact: true
  },
  {
    path: '/',
    component: App
  }
]

export const rootRoutes = () => <React.Fragment>{renderRoutes(routes)}</React.Fragment>
