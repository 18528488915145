import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'

import { Icon } from 'ui/atoms'
import { Confirm } from 'ui/molecules'
import { EditorBox } from './editor.style'

export const Save = ({
  active, onSave, withLettersCounter, lettersCount,
}) => (
  active && (
  <div className="done">
    {withLettersCounter && <div className="letters-count">{lettersCount}</div>}
    <Icon type="check" onClick={onSave} />
  </div>
  )
)

export const withEditorContainer = (Component, defaultType) => ({
  className = '',
  type = defaultType,
  withDeleteIcon = false,
  onDelete = () => true,
  toolbarOptions,
  onChangeStart = () => true,
  onChangeEnd = () => true,
  onSave,
  opened = false,
  ...props
}) => {
  const [active, setActiveState] = useState(opened)
  const [error, setError] = useState(false)
  const [confirmOpened, setConfirmState] = useState(false)

  const editorRef = useRef()

  const _onSave = (...params) => {
    onChangeEnd()

    onSave(...params)
  }

  function startChanging() {
    setActiveState(true)
    onChangeStart()

    if (editorRef.current) {
      editorRef.current.focus()
    }
  }

  useEffect(() => {
    if (active) {
      startChanging()
    }
    // eslint-disable-next-line
  }, [active])

  return (
    <EditorBox
      className={classNames(className, type, {
        active,
        'with-delete-icon': withDeleteIcon,
        'with-options': type === 'text' || type === 'quote' || type === 'h2' || type === 'links',
        error,
      })}
    >
      {!active && (
        <div
          className="start-changing"
          onClick={startChanging}
        />
      )}

      {active && withDeleteIcon && (
        <div className="delete" onClick={() => setConfirmState(true)}>
          <Icon type="cross" />
        </div>
      )}

      <Confirm
        okText="Удалить"
        cancelText="Отмена"
        question="Вы уверены, что хотите удалить блок?"
        open={confirmOpened}
        onOk={onDelete}
        onCancel={() => setConfirmState(false)}
      />

      <Component
        active={active}
        editorRef={editorRef}
        toolbarOptions={toolbarOptions}
        deactivate={() => setActiveState(false)}
        setError={setError}
        onSave={_onSave}
        type={type}
        {...props}
      />
    </EditorBox>
  )
}
