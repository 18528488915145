import React from 'react'
import styled from 'styled-components/macro'

const IconLinkBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }

  .cls-2 {
    fill: #fff;
  }
`

export const IconLink = ({ className = '', ...props }) => (
  <IconLinkBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="baseline-insert_link-24px"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_518" d="M0 0h24v24H0z" className="cls-1" data-name="Path 518" />
      <path
        id="Path_519"
        d="M3.9 12A3.1 3.1 0 0 1 7 8.9h4V7H7a5 5 0 0 0 0 10h4v-1.9H7A3.1 3.1 0 0 1 3.9 12zM8 13h8v-2H8zm9-6h-4v1.9h4a3.1 3.1 0 1 1 0 6.2h-4V17h4a5 5 0 0 0 0-10z"
        className="cls-2"
        data-name="Path 519"
      />
    </svg>
  </IconLinkBox>
)
