import React from 'react'
import styled from 'styled-components/macro'

const IconArticlePreviewBox = styled.div`
  width: 24px;
  height: 24px;

  .cls-1 {
    fill: none;
  }
  .cls-2 {
    fill: #fff;
  }
`

export const IconArticlePreview = ({ className = '', ...props }) => (
  <IconArticlePreviewBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="_24px_11_"
      width="24"
      height="24"
      data-name="24px (11)"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_560"
        d="M-74 9.039h11.755V24H-74zM-55.878 0H-50v7.481h-5.878zm0 0H-50v7.481h-5.878z"
        className="cls-1"
        data-name="Path 560"
        transform="translate(74)"
      />
      <path
        id="Path_561"
        d="M13 12h7v1.5h-7zm0-2.5h7V11h-7zm0 5h7V16h-7zM21 4H3a2.006 2.006 0 0 0-2 2v13a2.006 2.006 0 0 0 2 2h18a2.006 2.006 0 0 0 2-2V6a2.006 2.006 0 0 0-2-2zm0 15h-9V6h9z"
        className="cls-2"
        data-name="Path 561"
      />
    </svg>
  </IconArticlePreviewBox>
)
