import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { Droppable } from 'react-beautiful-dnd'

import { FONTS } from 'constants/fonts'

const ColumnBox = styled.div`
  margin-right: 30px;
  width: 300px;

  &:last-of-type {
    margin-right: 0;
  }

  &.main {
    margin-right: 90px;
  }

  .hot-news-placeholder {
    margin-bottom: 30px;
    width: 300px;
    height: 200px;
  }

  .column-name {
    margin-bottom: 50px;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    background-color: #484b50;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${FONTS.oswald};
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.69;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
  }
`

export const Column = ({
  name, main = false, droppableId, children,
}) => (
  <Droppable droppableId={String(droppableId)} isDropDisabled={false} type={`column${droppableId}`}>
    {(provided) => (
      <ColumnBox className={classNames({ main })} ref={provided.innerRef}>
        <div className="column-name">{name}</div>

        {children}
        {provided.placeholder}
      </ColumnBox>
    )}
  </Droppable>
)
