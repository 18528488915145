import React from 'react'
import styled from 'styled-components'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'

const ControlButtonsBox = styled.div`
  margin-top: 50px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .revert {
    margin-right: 24px;
    height: 36px;
    border-radius: 21px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 0 16px;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: -0.25px;
    color: #484b50;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .apply {
    height: 36px;
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #25a6b6;
    padding: 0 16px 0 10px;
    cursor: pointer;
    color: white;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: -0.25px;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &:hover {
      background-color: #51b8c5;
    }

    .icon-check {
      margin-right: 10px;
      border: 1px solid white;
      border-radius: 50%;
      width: 22px;
      height: 22px;

      .cls-2 {
        fill: white;
      }
    }
  }
`

export const ControlButtons = ({ onCancel }) => (
  <ControlButtonsBox>
    <div className="revert" onClick={onCancel}>
        Отменить
    </div>

    <div className="apply">
      <Icon type="check" />
        Применить
    </div>
  </ControlButtonsBox>
)
