import React from 'react'
import styled from 'styled-components/macro'

const IconCrossBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1,
  .cls-2 {
    fill: none;
  }

  .cls-2 {
    stroke: #897b6a;
    stroke-width: 2px;
  }
`

export const IconCross = ({ className = '', ...props }) => (
  <IconCrossBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="baseline-format_bold-24px"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_513" d="M0 0h24v24H0z" className="cls-1" data-name="Path 513" />
      <path
        id="Line_152"
        d="M0 0v14"
        className="cls-2"
        data-name="Line 152"
        transform="rotate(45 -.034 23.984)"
      />
      <path
        id="Line_153"
        d="M0 0v14"
        className="cls-2"
        data-name="Line 153"
        transform="rotate(135 4.964 11.984)"
      />
    </svg>
  </IconCrossBox>
)
