import React from 'react'
import styled from 'styled-components/macro'

const IconCalendarBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #484b50;
  }
  .cls-2 {
    fill: none;
  }
`

export const IconCalendar = ({ className = '', ...props }) => (
  <IconCalendarBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="calendar"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_560"
        d="M20 3h-1V1h-2v2H7V1H5v2H4a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zm0 18H4V8h16z"
        className="cls-1"
        data-name="Path 560"
      />
      <path id="Path_561" d="M0 0h24v24H0z" className="cls-2" data-name="Path 561" />
    </svg>
  </IconCalendarBox>
)
