import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { Icon } from '../Icon'

const CheckboxBox = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
  box-sizing: border-box;

  &.disabled {
    cursor: initial;
  }

  .checkbox-empty {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #d5d5d8;
  }

  .icon-check {
    width: 14px;
    height: 14px;

    svg {
      width: 14px;
      height: 14px;
    }

    .cls-2 {
      fill: #bf3b3b;
    }
  }

  .hidden-input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .icon-wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Checkbox = ({
  checked, disabled, onChange, name, className, ...props
}) => (
  <CheckboxBox
    className={classNames(className, { disabled, checked })}
    {...props}
  >
    <input
      type="checkbox"
      checked={checked}
      name={name}
      onChange={onChange}
      className="hidden-input"
    />

    <div className="icon-wrapper">
      {!checked ? (
        <div className="checkbox-empty" />
      ) : (
        <Icon type="check" className={classNames('checkbox-icon', { checked, disabled })} />
      )}
    </div>
  </CheckboxBox>
)
