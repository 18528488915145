import { POSTS } from 'api/posts'

export const SET_ACTIVE_NEWS = 'SET_ACTIVE_NEWS'
export const SET_AUTHORS_LIST = 'SET_AUTHORS_LIST'
export const SET_RUBRICS_LIST = 'SET_RUBRICS_LIST'
export const SET_TAGS_LIST = 'SET_TAGS_LIST'
export const SET_PSEUDONYMS_LIST = 'SET_PSEUDONYMS_LIST'

export function setActiveNews(news) {
  return {
    type: SET_ACTIVE_NEWS,
    payload: news,
  }
}

export function loadAuthorsList() {
  return async (dispatch) => {
    const response = await POSTS.getAuthors()

    dispatch({ type: SET_AUTHORS_LIST, payload: response.data })
  }
}

export function loadPseudonyms() {
  return async (dispatch) => {
    const response = await POSTS.getPesudonyms()

    dispatch({ type: SET_PSEUDONYMS_LIST, payload: response.data.aliases })
  }
}

export function loadRubricsList() {
  return async (dispatch) => {
    const response = await POSTS.getRubrics()

    dispatch({ type: SET_RUBRICS_LIST, payload: response.data })
  }
}

export function loadTagsList() {
  return async (dispatch) => {
    const response = await POSTS.getTags()

    dispatch({ type: SET_TAGS_LIST, payload: response.data })
  }
}
