import React from 'react'
import styled from 'styled-components/macro'

const IconWrappedHomeBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #d5d5d8;
  }
  .cls-2 {
    fill: #fff;
  }
`

export const IconWrappedHome = ({ className = '', ...props }) => (
  <IconWrappedHomeBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Ic_home" width="24" height="24" viewBox="0 0 24 24">
      <rect
        id="Rectangle_1202"
        width="24"
        height="24"
        className="cls-1"
        data-name="Rectangle 1202"
        rx="4"
      />
      <path
        id="Icon_open-home"
        d="M9 0L0 6.75h2.25v9h4.5v-4.5h4.5v4.5h4.5V6.682L18 6.75z"
        className="cls-2"
        data-name="Icon open-home"
        transform="translate(3 3.999)"
      />
    </svg>
  </IconWrappedHomeBox>
)
