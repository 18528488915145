import React, { useState, useRef } from 'react'
import {
  Editor, EditorState, RichUtils, CompositeDecorator,
} from 'draft-js'
import 'draft-js/dist/Draft.css'
import classNames from 'classnames'

// import { convertFromHTML } from 'draft-convert'
import { Icon } from 'ui/atoms/Icon/index'
import styled from 'styled-components'
import { Radio } from 'ui/atoms/Radio/index'
import plusImg from 'assets/images/plus-in-circle.png'
import { useOnClickOutside } from 'hooks/index'
import { EditorToolbar } from './EditorToolbar'
import { withEditorContainer, Save } from './EditorContainer'
import { StyledLink } from './editor.style'
import { convertToHtml, convertFromHtml } from './convert'

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()

    return entityKey !== null && (contentState.getEntity(entityKey).getType() === 'LINK'
    || contentState.getEntity(entityKey).getType() === 'LINK-BLANK'
    || contentState.getEntity(entityKey).getType() === 'LINK-BLANK-NOFOLLOW'
    || contentState.getEntity(entityKey).getType() === 'LINK-NOFOLLOW'
    || contentState.getEntity(entityKey).getType() === 'LINK-CLEAR')
  }, callback)
}

function formatHtml(html = '') {
  const htmlWithReplacedAmpersands = html.replace(/ +&amp; +/g, ' and ')

  let countOfDoubleQuotes = 0
  let htmlWithReplacedQuotes = htmlWithReplacedAmpersands

  while (htmlWithReplacedQuotes.includes('&quot;')) {
    if (countOfDoubleQuotes % 2 === 0) {
      htmlWithReplacedQuotes = htmlWithReplacedQuotes.replace('&quot;', '«')
    } else {
      htmlWithReplacedQuotes = htmlWithReplacedQuotes.replace('&quot;', '»')
    }

    countOfDoubleQuotes += 1
  }

  return htmlWithReplacedQuotes
}

const Link = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData()

  return (
    <StyledLink href={url} title={url} className="ed-link">
      {children}
    </StyledLink>
  )
}

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
])

const TextEditor = ({
  withToolbar = false,
  // readOnly = false,
  type,
  // className = '',
  placeholder,
  active,
  blockTypeIcon,
  editorRef,
  toolbarOptions,
  initialState,
  deactivate,
  maxLineCount = Infinity,
  setError,
  onSave,
  insertsAvailable,
  withLettersCounter,
  saveOnClickOutside,
}) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(convertFromHtml(initialState.html), decorator),
  )

  const [withInserts, toggleWithInserts] = useState(initialState?.params?.withInserts || false)
  const [insertsPosition, setInsertsPosition] = useState(initialState?.params?.insertsPosition || 'right')
  const [insertWithPhoto, toggleIsertWithPhoto] = useState(initialState?.params?.withPhoto || false)
  const [insertsCount, setInsertsCount] = useState(initialState?.params?.insertsCount || 1)

  const containerRef = useRef()

  const onEditorChange = (state) => {
    const blockArray = state.getCurrentContent().getBlocksAsArray()

    if (blockArray && blockArray.length > maxLineCount) {
      setError(true)
    } else {
      setError(false)
    }

    setEditorState(state)
  }

  const toggleInlineStyle = (inlineStyle) => {
    onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  const handleInsertsToggleClick = () => {
    toggleWithInserts(!withInserts)
  }

  const handleInsertsPositionChange = (position) => {
    setInsertsPosition(position)
  }

  const handleAddSecondInsertState = () => {
    setInsertsCount(2)
  }

  const handleFirstInsertPhotoToggle = (withPhoto) => {
    if (withPhoto) {
      setInsertsCount(1)
    }

    toggleIsertWithPhoto(withPhoto)
  }

  const setLink = (url, type) => {
    if (!url) {
      return
    }

    const contentState = editorState.getCurrentContent()

    const contentStateWithEntity = contentState.createEntity(type, 'MUTABLE', { url })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })

    setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey))
  }

  // const checkLink = () => {
  //   const startKey = editorState.getSelection().getStartKey()
  //   const content = editorState
  //     .getCurrentContent()

  //   console.log(findLinkEntities(content))
  // }

  const _onSave = () => {
    deactivate()

    const params = {
      lettersCount: editorState.getCurrentContent().getPlainText('').length,
      text: editorState.getCurrentContent().getPlainText(''),
    }

    if (withInserts) {
      params.withInserts = true
      params.insertsPosition = insertsPosition
      params.insertsCount = insertsCount
      params.withPhoto = insertWithPhoto
    }

    const html = formatHtml(convertToHtml(editorState.getCurrentContent()))

    setEditorState(EditorState.createWithContent(convertFromHtml(html), decorator))

    onSave({
      html,
      params,
    })
  }

  const lettersCount = editorState.getCurrentContent().getPlainText('').length

  const editorToolbarCustomContent = type === 'text' && insertsAvailable ? (
    <AdditionalToolbar>
      <div
        className={classNames('option main', { active: withInserts })}
        onClick={handleInsertsToggleClick}
      >
        <Icon type="layout" />
      </div>

      <div
        className={classNames('option', { active: withInserts && insertsPosition === 'left' })}
        onClick={() => handleInsertsPositionChange('left')}
      >
        <Icon type="left-side" />
      </div>

      <div
        className={classNames('option', { active: withInserts && insertsPosition === 'right' })}
        onClick={() => handleInsertsPositionChange('right')}
      >
        <Icon type="right-side" />
      </div>
    </AdditionalToolbar>
  ) : null

  const handleSaveOnClickOutside = () => {
    if (saveOnClickOutside) {
      _onSave()
    }
  }

  useOnClickOutside(containerRef, handleSaveOnClickOutside)

  return (
    <EditorBox className={classNames({ reverse: insertsPosition === 'left', active, 'with-inserts': withInserts })} ref={containerRef}>
      <Editor
        editorState={editorState}
        onChange={onEditorChange}
        readOnly={!active}
        placeholder={!active ? placeholder : null}
        ref={editorRef}
        spellCheck
      />

      {(!!blockTypeIcon || withToolbar) && active && (
        <EditorToolbar
          editorState={editorState}
          onToggle={toggleInlineStyle}
          blockTypeIcon={blockTypeIcon}
          availableOptions={toolbarOptions}
          setLink={setLink}
          // checkLink={checkLink}
          containerRef={containerRef}
          customContent={editorToolbarCustomContent}
        />
      )}

      {withInserts
      && (
        <>
          <InsertsBox>
            <Insert>
              <Tag>Тэг</Tag>
              <Title>Заголовок</Title>
              <RadioRow>
                <Radio
                  single
                  checked={insertWithPhoto}
                  onChange={() => handleFirstInsertPhotoToggle(!insertWithPhoto)}
                />
                <span>
                  Отобразить фотографию статьи
                </span>
              </RadioRow>
            </Insert>

            {active && !insertWithPhoto && insertsCount !== 2
            && (
            <Insert className="plus" onClick={handleAddSecondInsertState}>
              <PlusImg src={plusImg} />
            </Insert>
            )}

            {insertsCount === 2
            && (
            <Insert>
              <Tag>Тэг</Tag>
              <Title>Заголовок</Title>
            </Insert>
            )}
          </InsertsBox>
        </>
      )}

      <Save onSave={_onSave} active={active} withLettersCounter={withLettersCounter} lettersCount={lettersCount} />
    </EditorBox>
  )
}

const EditorBox = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;

  &.reverse {
    flex-direction: row-reverse;
  }

  &.active {
    padding-bottom: 40px;
  }

  &.with-inserts {
    .DraftEditor-root {
      max-width: 540px;
    }
  }
`

const AdditionalToolbar = styled.div`
  width: 120px;
  height: 36px;
  position: absolute;
  right: -5px;
  background: #1D1F24;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25098);
  border-radius: 4px 4px 0px 0px;
  transform: translateX(100%);
  display: flex;
  align-items: center;
  padding: 0 6px;
  justify-content: space-between;

  .option.main {
    &.active {
      background: white;

      svg {
        .cls-1 {
          fill: black;
        }
      }
    }
  }
`

const InsertsBox = styled.div`
  margin: 0 20px;
  padding: 20px 0;
  min-width: 300px;
  width: 300px;
`

const Insert = styled.div`
  margin-bottom: 20px;
  background: #EBE2D5;
  border: 1px dashed #897B6A;
  box-shadow: -3.06162e-16px 5px 15px rgba(0, 0, 0, 0.0509804);
  border-radius: 8px;
  padding: 12px;

  &.plus {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`

const Tag = styled.p`
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: black;
  mix-blend-mode: normal;
  opacity: 0.7;
`

const Title = styled.p`
  margin-top: 5px;
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  font-family: Oswald;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.1px;
  color: black;
`

const RadioRow = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.21875px;

  color: black;
`

const PlusImg = styled.img`
  width: 66px;
  height: 66px;
`

const enhanced = withEditorContainer(TextEditor)

export { enhanced as TextEditor }
