import React from 'react'
import styled from 'styled-components/macro'

import { FONTS } from 'constants/fonts'

const TooltipBox = styled.div`
  position: relative;
  z-index: 30;

  &:hover {
    .tooltip {
      display: flex;
    }
  }

  .tooltip {
    position: absolute;
    left: 120%;
    top: 50%;
    height: 24px;
    width: max-content;
    padding: 0 12px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: black;
    display: none;
    align-items: center;
    font-family: ${FONTS.roboto};
    font-size: 12px;
    line-height: 1.83;
    letter-spacing: -0.19px;
    text-align: left;
    color: #ffffff;
  }
`

export const Tooltip = ({ children, text }) => (
  <TooltipBox>
    {children}
    <div className="tooltip">{text}</div>
  </TooltipBox>
)
