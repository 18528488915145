import React from 'react'
import styled from 'styled-components/macro'

const IconEditBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #1d1f24;
  }
  .cls-2 {
    fill: none;
  }
`

export const IconEdit = ({ className = '', ...props }) => (
  <IconEditBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="_24px_8_"
      width="24"
      height="24"
      data-name="24px (8)"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_553"
        d="M3 15.665V19h3.333l9.83-9.83-3.333-3.335zM18.74 6.59a.885.885 0 0 0 0-1.253l-2.08-2.08a.885.885 0 0 0-1.253 0l-1.626 1.627 3.333 3.333L18.74 6.59z"
        className="cls-1"
        data-name="Path 553"
        transform="translate(1 1)"
      />
      <path id="Path_554" d="M0 0h24v24H0z" className="cls-2" data-name="Path 554" />
    </svg>
  </IconEditBox>
)
