import React from 'react'

import { ICONS } from './icons'

export const Icon = ({ type, className = '', ...props }) => {
  const Component = ICONS[type]

  if (!Component) {
    return null
  }

  return <Component className={`icon-${type} ${className}`} {...props} />
}
