import React from 'react'
import styled from 'styled-components/macro'

const IconAddImgBox = styled.div`
  height: 19px;
  width: 19px;
  display: flex;

  .cls-1 {
    fill: #fff;
  }
`

export const IconAddImg = ({ className = '', ...props }) => (
  <IconAddImgBox className={className} {...props}>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4167 5.41732V8.15815C15.4167 8.15815 13.5925 8.16732 13.5833 8.15815V5.41732H10.8333C10.8333 5.41732
      10.8425 3.59315 10.8333 3.58398H13.5833V0.833984H15.4167V3.58398H18.1667V5.41732H15.4167ZM12.6667
      9.08398V6.33398H9.91667V3.58398H2.58333C1.575 3.58398 0.75 4.40898 0.75 5.41732V16.4173C0.75 17.4256 1.575
      18.2507 2.58333 18.2507H13.5833C14.5917 18.2507 15.4167 17.4256 15.4167 16.4173V9.08398H12.6667ZM2.58333
      16.4173L5.33333 12.7507L7.16667 15.5007L9.91667 11.834L13.5833 16.4173H2.58333Z"
        fill="white"
        className="cls-1"
      />
    </svg>
  </IconAddImgBox>
)
