import React from 'react'
import styled from 'styled-components/macro'

const IconArrowBox = styled.div`
  width: 24px;
  height: 24px;

  .cls-1 {
    fill: #1d1f24;
  }
  .cls-2 {
    fill: none;
  }
`

export const IconArrow = ({ className = '', ...props }) => (
  <IconArrowBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="_24px_7_"
      width="24"
      height="24"
      data-name="24px (7)"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_551"
        d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z"
        className="cls-1"
        data-name="Path 551"
      />
      <path id="Path_552" d="M0 0h24v24H0z" className="cls-2" data-name="Path 552" />
    </svg>
  </IconArrowBox>
)
