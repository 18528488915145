import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import moment from 'moment'

import { Icon, Datetime } from 'ui/atoms'
import { FONTS } from 'constants/fonts'
import { useOnClickOutside } from 'hooks'

const VisibilityTimeBox = styled.div`
  .icon-wrapped-eye {
    cursor: pointer;

    &:hover {
      .cls-1 {
        fill: #c0c0c1;
      }
    }
  }

  .time-wrapper {
    width: 63px;
    height: 24px;
    border-radius: 4px;
    background-color: #7c8784;
    display: flex;
    align-items: center;
    font-family: ${FONTS.roboto};
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: -0.1px;
    text-align: left;
    color: #ffffff;
    position: relative;

    .time {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: ${FONTS.roboto};
      font-size: 11px;

      &.empty {
        margin-top: -2px;
        font-size: 15px;
        font-family: ${FONTS.lato};
      }
    }

    .calendar {
      position: absolute;
      left: 0;
      top: 22px;

      font-family: ${FONTS.roboto};

      .rdtCounter .rdtCount {
        font-size: 18px;
      }

      .rdtBtn {
        color: black;
      }

      .rdtCount {
        color: black;
      }

      .rdtCounterSeparator {
        color: black;
      }
    }
  }
`

export const VisibilityTime = ({ className, onChangeVisibilityTime, date }) => {
  const [datepickerOpened, toggleDatepicker] = useState(false)
  const [opened, toggleStatus] = useState(false)

  const datepickerRef = useRef()

  useOnClickOutside(datepickerRef, () => toggleDatepicker(false))

  const dateMoreThenNow = moment(date).valueOf() > moment().valueOf()

  const showTime = moment(date).format('HH:mm') !== '00:00' && dateMoreThenNow

  return (
    <VisibilityTimeBox className={className}>
      {!opened && !dateMoreThenNow && <Icon type="wrapped-eye" onClick={() => toggleStatus(true)} />}

      {!opened && dateMoreThenNow && <Icon type="eye-crossed" onClick={() => toggleStatus(true)} />}

      {opened && (
        <div className="time-wrapper">
          <Icon type="eye-crossed" onClick={() => toggleStatus(false)} />

          <div className={classNames('time', { empty: !showTime })} onClick={() => toggleDatepicker(!datepickerOpened)}>
            {!showTime && '--:--'}

            {showTime && moment(date).format('HH:mm')}
          </div>

          {datepickerOpened && (
            <div className="calendar">
              <Datetime
                value={date}
                viewMode="time"
                dateFormat={false}
                onChange={onChangeVisibilityTime}
                opened
                setRef={datepickerRef}
              />
            </div>
          )}
        </div>
      )}
    </VisibilityTimeBox>
  )
}
