import { createGlobalStyle } from 'styled-components/macro'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Fira Sans', sans-serif;

    &.safari {
      .left-panel {
        max-height: 100vh;
      }
    }
  }

  a {
    text-decoration: none;
    outline: none;
  }

  p {
    margin: 0;
  }

  

  @media screen and (max-width: 1649px) {
    body {
      zoom: 0.85;
    }
  }

  @media screen and (max-width: 1440px) {
    body {
      zoom: 0.70;
    }
  }
`

export { GlobalStyle }
