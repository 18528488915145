import React from 'react'
import styled from 'styled-components'
import { Icon } from 'ui/atoms/Icon/index'
import { FONTS } from 'constants/fonts'
import { Author, Pseudonym } from './Author'

const AuthorsBox = styled.div`
  margin-bottom: 100px;
  margin-left: 2px;

  .co-author {
    margin-top: 26px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: ${FONTS.roboto};
    font-size: 12px;
    color: '#1d1f24';
    font-weight: 500;
    text-transform: uppercase;

    .text {
      opacity: 0.6;
    }

    .icon-plus {
      cursor: pointer;

      .cls-1 {
        fill: #1d1f24;
        stroke: #e7e8ea;      
      }
    }

    .icon-cross {
      cursor: pointer;

      .cls-2 {
        stroke: #de4e4e;
      }
    }
  }
`

export const Authors = ({
  authors = [], setAuthor, setPseudonym, addCoAuthor, removeCoAuthor,
}) => {
  function _addCoAuthor() {
    if (!authors[authors.length - 1].authorId) {
      return
    }

    addCoAuthor()
  }

  return (
    <AuthorsBox>
      {authors.map((author, index) => (
        <React.Fragment key={author.authorId}>
          <Author authorId={author.authorId} setAuthor={(a) => setAuthor(a, index)} />
          <Pseudonym setPseudonym={(p) => setPseudonym(p, index)} pseudonym={author.author} />

          <div className="co-author">
            <span className="text">Соавтор</span>

            {authors.length - 1 > index ? <Icon type="cross" onClick={() => removeCoAuthor(index + 1)} /> : <Icon type="plus" onClick={_addCoAuthor} />}

          </div>
        </React.Fragment>
      ))}
    </AuthorsBox>
  )
}
