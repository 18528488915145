import React, { useState, useRef } from 'react'
import styled from 'styled-components/macro'
import classNames from 'classnames'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { FONTS } from 'constants/fonts'
import { DRAGGABLE_NEWS } from 'constants/app'
import { Icon } from 'ui/atoms'
import { ArticlePreview } from 'features/Preview/ArticlePreview'
import { Scrollbars } from 'react-custom-scrollbars'
import { togglePageLayoutBlur } from 'redux/actions/app'

const NewsBox = styled.div`
  .empty {
    opacity: 0.5;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: white;
    padding: 16px 24px;
  }

  .news {
    padding: 16px 24px;
    border-bottom: 1px solid #d5d5d8;
    cursor: pointer;
    position: relative;

    &:hover {
      background: #63666a;
    }

    &:active,
    &.active {
      background: white;

      .title,
      .info,
      .text {
        color: black;
      }

      .info {
        .icon-article-preview {
          .cls-2 {
            fill: black;
          }
        }
      }
    }

    .info {
      font-family: ${FONTS.roboto};
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        opacity: 0.5;
      }
    }

    .title {
      margin-top: 8px;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: -0.25px;
      text-align: left;
      color: #ffffff;
    }

    .text {
      margin-top: 16px;
      font-family: ${FONTS.roboto};
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #d5d5d8;
    }
  }
`

export const FoundNews = ({ news }) => {
  const [previewOpened, togglePreviewVisibility] = useState(false)
  const [activeNewsIndex, setActiveNewsIndex] = useState(null)

  const clickTimeout = useRef(null)
  const clickCount = useRef(0)

  const dispatch = useDispatch()

  function showPreview(index) {
    clickCount.current += 1

    clickTimeout.current = setTimeout(() => { clickCount.current = 0 }, 300)

    if (clickCount.current <= 1) {
      return
    }

    togglePreviewVisibility(true)
    setActiveNewsIndex(index)
    dispatch(togglePageLayoutBlur(true))
  }

  function closePreview() {
    togglePreviewVisibility(false)
    setActiveNewsIndex(null)
    dispatch(togglePageLayoutBlur(false))
  }

  const onDragStart = (news) => (event) => {
    event.dataTransfer.setData('text', JSON.stringify({ ...news, date: moment(news.date).format('DD MMMM'), type: DRAGGABLE_NEWS }))
  }

  if (!news.length) {
    return (
      <NewsBox>
        <div className="empty">Ничего не найдено</div>
      </NewsBox>
    )
  }

  return (
    <NewsBox>
      <Scrollbars autoHeight autoHeightMax="calc(100vh - 110px)" renderTrackHorizontal={() => <div />}>
        {news.map((n, index) => (
          <div
            className={classNames('news', { active: index === activeNewsIndex })}
            draggable
            onDragStart={onDragStart(n)}
            key={n._id}
          >
            <div className="info">
              <span>
                {`${moment(n.date).format('DD MMMM YYYY')} `}
                -
                {' '}
                {n?.rubric?.name}
              </span>
              <Icon
                type="article-preview"
                onClick={() => showPreview(index)}
              />
            </div>

            <div className="title">{n.title}</div>

            <div className="text">{n.lead}</div>
          </div>
        ))}
      </Scrollbars>

      {previewOpened && (
        <ArticlePreview
          id={news[activeNewsIndex]._id}
          date={news[activeNewsIndex].date}
          type={news[activeNewsIndex].type}
          requiredBlocks={news[activeNewsIndex].requiredBlocks}
          optionalBlocks={news[activeNewsIndex].optionalBlocks}
          // onClose={() => togglePreview(false)}
          rubricName={news[activeNewsIndex]?.rubric?.name}
          lettersCount={news[activeNewsIndex].lettersCount}
          author={news[activeNewsIndex].author}
          // date={date}
          selectedTags={[]}
          withEdit
          isModal
          onClose={closePreview}
          validationEnabled={news[activeNewsIndex].validationEnabled}
        />
      )}
    </NewsBox>
  )
}
