import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'

const TabsBox = styled.div`
  display: flex;

  .tab {
    margin-right: 24px;
    height: 36px;
    border-radius: 21px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 0 16px;
    cursor: pointer;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: -0.25px;
    text-align: left;
    color: #484b50;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #ce914a;
      color: white;
    }
  }
`

export const Tabs = ({ tabs = [], activeTab = 'rubrics', onTabSelect }) => {
  return (
    <TabsBox>
      {tabs.map(tab => (
        <div
          className={classNames('tab', { active: activeTab === tab.value })}
          onClick={() => onTabSelect(tab.value)}
          key={tab.name}
        >
          {tab.name}
        </div>
      ))}
    </TabsBox>
  )
}
