export const articleMock = {
  type: 'article',
  id: 1,
  date: new Date(),
  lettersCount: 220,
  category: {
    name: 'Политика',
    id: 2
  },
  requiredBlocks: [
    {
      type: 'title',
      html: '<p>Почему Навального так быстро выписали из больницы</p>',
      params: { lettersCount: 9 }
    },
    {
      type: 'lead',
      html:
        '<p>Главврачи городских больниц, как и судьи, не видят оснований не доверять полиции. Алексея выписали спустя сутки его пребывания в московской 64-й городской больницe</p>',
      params: { lettersCount: 3 }
    },
    {
      type: 'main-photo',
      html: '',
      params: {
        photoUrl: 'http://192.162.241.70:33280/images/Image%201000.e2017605.png',
        source: 'Фотобанк Русской планеты'
      }
    },
    {
      type: 'text',
      html:
        'Ранее официальный представитель <strong class="bold">МИД России Мария Захарова</strong> заявила, что некоторые члены делегации Российской Федерации не получили визы для участия в неделе высокого уровня 74-й сессии <em class="italic">Генассамблеи ООН</em>, которая проходит с 24 по 30 сентября в Нью-Йорке. Ссылки на то, что ситуация с невыдачей виз носит технический характер, она назвала &quot;несостоятельными&quot;. Всего визу не получили десять членов делегации, в том числе глава международного комитета Совета Федерации Константин Косачев и глава <a class="link" href="https://google.com" target="_blank" rel="noopener noreferrer">комитета Госдумы</a> по международным делам Леонид Слуцкий.',
      params: { lettersCount: 544 }
    }
  ],
  optionalBlocks: [
    {
      type: 'h2',
      html: '<p>Оппозиционер был признан виновным</p>',
      params: { lettersCount: 12 }
    },
    {
      type: 'text',
      html: '',
      params: {}
    },
    {
      type: 'quote',
      html:
        '\n      <div class="quote-text"><p>Политика Алексея Навального выписали из московской 64-й городской больницы, куда он был доставлен из спецприемника. Об этом в эфире «Дождя» сообщила его лечащий врач Анастасия Васильева. Она добавила, что Навального под конвоем отправили обратно в спецприемник.</p></div>\n    ',
      params: {
        source: 'Источник'
      }
    },
    {
      type: 'image',
      html:
        '<img className="image-full-width src="http://192.162.241.70:33280/images/Image%201000.e2017605.png"><div className="image-description">источник</div>',
      params: {
        photoUrl: 'http://192.162.241.70:33280/images/Image%201000.e2017605.png',
        source: 'источник'
      }
    },
    {
      type: 'links',
      html: null,
      params: {
        links: [
          {
            id: 2,
            date: '23 авг 19',
            category: 'Политика',
            title: 'Ходорковский открестился от Навального после провала незаконных митингов в Москве',
            imageURL: '',
            text:
              '... такие нерентабельные проекты, как «Навальный и Ко». Экс-кандидаты ... от прежнего курса. Теперь Навальный и его команда призывают ... на некую совместную работу Навального и Кремля. Вполне очевидно, ... стране. По всей видимости, Навальный и его «ФБК» рискуют ... ',
            type: 'DRAGGABLE_NEWS'
          },
          {
            id: 1,
            date: '26 авг 19',
            category: 'Политика',
            title: 'А ты подал иск против Навального?',
            imageURL: '',
            text:
              '... набор претензий «Автодора», то  Алексею Навальному, Любови Соболь, Георгию Албурову, Илье ... несладко. В соцсетях лица, сочувствующие Навальному и его команде, рассказали миру ... ',
            type: 'DRAGGABLE_NEWS'
          }
        ]
      }
    }
  ]
}

export const emptyArticleMock = {
  type: 'article',
  id: 1,
  lettersCount: 0,
  category: null,
  requiredBlocks: [
    { type: 'title', html: '', params: { lettersCount: 0 } },
    { type: 'lead', html: '', params: { lettersCount: 0 } },
    {
      type: 'main-photo',
      html: '',
      params: {
        photoUrl: '',
        source: ''
      }
    },
    {
      type: 'text',
      html: '',
      params: { lettersCount: 0 }
    }
  ],
  optionalBlocks: []
}

export const emptyLiveMock = {
  type: 'live',
  id: 1,
  lettersCount: 0,
  category: null,
  requiredBlocks: [
    { type: 'title', html: '', params: { lettersCount: 0 } },
    {
      type: 'main-photo',
      html: '',
      params: {
        photoUrl: '',
        source: ''
      }
    },
    {
      type: 'live',
      html: '',
      params: { time: '' }
    }
  ],
  optionalBlocks: []
}
