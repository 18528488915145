import React from 'react'
import { convertToHTML, convertFromHTML } from 'draft-convert'

const styleToHTML = (style) => {
  switch (style) {
    case 'ITALIC':
      return <em className="italic" />
    case 'BOLD':
      return <strong className="bold" />
    default:
      return null
  }
}

const entityToHTML = (entity, text) => {
  if (entity.type === 'LINK') {
    return (
      <a className="link" href={entity.data.url} rel="noopener noreferrer nofollow" target="_blank">
        {text}
      </a>
    )
  }

  if (entity.type === 'LINK-CLEAR') {
    return (
      <a className="link" href={entity.data.url} rel="noopener noreferrer">
        {text}
      </a>
    )
  }

  if (entity.type === 'LINK-BLANK') {
    return (
      <a className="link" href={entity.data.url} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    )
  }

  if (entity.type === 'LINK-NOFOLLOW') {
    return (
      <a className="link" href={entity.data.url} rel="noopener noreferrer nofollow">
        {text}
      </a>
    )
  }

  if (entity.type === 'LINK-BLANK-NOFOLLOW') {
    return (
      <a className="link" href={entity.data.url} target="_blank" rel="noopener noreferrer nofollow">
        {text}
      </a>
    )
  }

  return text
}

const htmToStyle = (nodeName, node, currentStyle) => {
  if (nodeName === 'em') {
    currentStyle.add('ITALIC')

    return
  }

  if (nodeName === 'strong') {
    currentStyle.add('BOLD')

    return
  }

  // eslint-disable-next-line
  return currentStyle
}

const htmlToEntity = (nodeName, node, createEntity) => {
  if (nodeName === 'a') {
    let type = 'LINK'

    if (node.target === '_blank') {
      type += '-BLANK'
    }

    if (node.rel && node.rel.includes('nofollow')) {
      type += '-NOFOLLOW'
    }

    if (type === 'LINK-BLANK-NOFOLLOW') {
      type = 'LINK'
    }

    if (type === 'LINK') {
      type = 'LINK-CLEAR'
    }

    return createEntity(type, 'MUTABLE', {
      url: node.href,
    })
  }
}

const converterToHtml = convertToHTML({ styleToHTML, entityToHTML })

const converterFromHtml = convertFromHTML({ htmToStyle, htmlToEntity })

export const convertToHtml = (state) => converterToHtml(state)

export const convertFromHtml = (html) => converterFromHtml(html)
