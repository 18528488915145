import React from 'react'
import styled from 'styled-components'

import { Radio } from 'ui/atoms'

const PublicationTypesBox = styled.div`
  margin-top: 12px;

  .radio {
    width: 142px;
    margin-bottom: 8px;
    margin-right: 8px;
    box-sizing: border-box;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .options-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
`

const types = [
  { name: 'Новость', value: 'news' }, { name: 'Статья', value: 'article' },
]

export const PublicationTypes = ({ currentTypes = [], onSelectType }) => (
  <PublicationTypesBox>
    <div className="options-wrapper">
      {types.map((type) => (
        <Radio
          key={type.value}
          label={type.name}
          checked={currentTypes.includes(type.value)}
          className="radio"
          onChange={() => onSelectType(type.value)}
        />
      ))}
    </div>
  </PublicationTypesBox>
)
