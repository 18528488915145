import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'

const NewsListBox = styled.div`
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;

  .controls {
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    align-items: center;
    z-index: 2;

    .take {
      width: 79px;
      height: 24px;
      border-radius: 2px;
      background-color: #efefef;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.78;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: -0.25px;
      text-align: left;
      color: #454545;
      cursor: pointer;
    }

    .icon-open {
      margin-left: 8px;
      cursor: pointer;
    }
  }

  .article {
    margin-right: 50px;
    margin-bottom: 20px;
    width: 586px;
    border-radius: 4px;
    border: solid 1px #ce914a;
    background-color: white;
    padding: 16px;
    box-sizing: border-box;
    position: relative;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.05);
      background-color: #f5f5f5;
    }

    .date {
      opacity: 0.7;
      font-family: ${FONTS.lato};
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: black;

      .source {
        font-weight: bold;
      }
    }

    .title {
      margin-top: 9px;
      font-family: ${FONTS.oswald};
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3;
      letter-spacing: -0.1px;
      text-align: left;
      color: black;
    }

    .text {
      margin-top: 8px;
      text-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
      font-family: ${FONTS.lato};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: black;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`

export const NewsList = ({ news, onSelect }) => (
  <NewsListBox>
    {news.map((article) => (
      <div className="article" key={article.id}>
        <div className="controls">
          <div className="take">В работу</div>

          <Icon type="open" onClick={() => onSelect(article.id)} />
        </div>

        <div className="date">
          {moment(article.date).format('DD MMMM YY - HH:mm')}
          {' '}
          -
          <span className="source">{article.source}</span>
        </div>
        <div className="title">{article.title}</div>
        <div className="text">{article.text}</div>
      </div>
    ))}
  </NewsListBox>
)
