import React from 'react'
import styled from 'styled-components/macro'

import { Icon } from 'ui/atoms'

const TimeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 224px;

  .controls-wrapper {
    width: 148px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .control-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .arrow-wrapper {
        width: 60px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #626469;
        border-radius: 4px;
      }

      .icon-arrow {
        &.up {
          transform: rotate(180deg);
        }

        .cls-1 {
          fill: white;
        }
      }

      .time-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Roboto;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #c3b29b;
      }
    }
  }
`

export function Time({ time, onChange }) {
  let [hours, minutes] = time.split(':')

  hours = Number(hours)
  minutes = Number(minutes)

  let sHours = String(hours)
  let sMinutes = String(minutes)

  if (sHours.length < 2) {
    sHours = `0${sHours}`
  }

  if (sMinutes.length < 2) {
    sMinutes = `0${sMinutes}`
  }

  function _onChange(hours, minutes) {
    let sHours = String(hours)
    let sMinutes = String(minutes)

    if (sHours.length < 2) {
      sHours = `0${sHours}`
    }

    if (sMinutes.length < 2) {
      sMinutes = `0${sMinutes}`
    }

    onChange(`${sHours}:${sMinutes}`)
  }

  function onHoursChange(hour) {
    if (hour >= 23) {
      _onChange(0, minutes)

      return
    }

    if (hour < 0) {
      _onChange(23, minutes)

      return
    }

    _onChange(hour, minutes)
  }

  function onMinutesChange(minutes) {
    if (minutes >= 59) {
      _onChange(hours, 0)

      return
    }

    if (minutes < 0) {
      _onChange(hours, 59)

      return
    }

    _onChange(hours, minutes)
  }

  return (
    <>
      {/* <div className="month-controls">
        <Icon type="arrow" className="arrow-left" />
        <div className="month">
          {monthLabel}
        </div>
        <Icon type="arrow" className="arrow-right" />
      </div> */}

      <TimeBox>
        <div className="controls-wrapper">
          <div className="control-wrapper">
            <div className="arrow-wrapper">
              <Icon type="arrow" className="up" onClick={() => onHoursChange(hours + 1)} />
            </div>
            <div className="time-wrapper">
              {sHours}
            </div>
            <div className="arrow-wrapper" onClick={() => onHoursChange(hours - 1)}>
              <Icon type="arrow" />
            </div>
          </div>

          <div className="control-wrapper">
            <div className="arrow-wrapper">
              <Icon type="arrow" className="up" onClick={() => onMinutesChange(minutes + 1)} />
            </div>
            <div className="time-wrapper">
              {sMinutes}
            </div>
            <div className="arrow-wrapper" onClick={() => onMinutesChange(minutes - 1)}>
              <Icon type="arrow" />
            </div>
          </div>
        </div>
      </TimeBox>
    </>
  )
}
