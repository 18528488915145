import React from 'react'
import styled from 'styled-components/macro'

const IconOpenBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #efefef;
  }

  .cls-2,
  .cls-3 {
    fill: #484b50;
  }

  .cls-2 {
    opacity: 0.3;
  }
`

export const IconOpen = ({ className = '', ...props }) => (
  <IconOpenBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" id="open_in_new-24px" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_1261" width="24" height="24" className="cls-1" data-name="Rectangle 1261" rx="2" />
      <path
        id="Path_577"
        d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2v-7h-2z"
        className="cls-2"
        data-name="Path 577"
      />
      <path
        id="Path_580"
        d="M14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3z"
        className="cls-3"
        data-name="Path 580"
      />
    </svg>
  </IconOpenBox>
)
