import React from 'react'
import styled from 'styled-components/macro'

const IconQuestionBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: white;
  }
`

export const IconQuestion = ({ className = '', ...props }) => (
  <IconQuestionBox className={className} {...props}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM9.01 15C8.31 15 7.75 14.44 7.75 13.74C7.75 13.03 8.31 12.49 9.01 12.49C9.72 12.49 10.26 13.03 10.26 13.74C10.25 14.43 9.72 15 9.01 15ZM12.02 7.6C11.26 8.71 10.54 9.06 10.15 9.77C9.99 10.06 9.93 10.25 9.93 11.18H8.11C8.11 10.69 8.03 9.89 8.42 9.2C8.91 8.33 9.84 7.81 10.38 7.04C10.95 6.23 10.63 4.71 9.01 4.71C7.95 4.71 7.43 5.51 7.21 6.19L5.56 5.49C6.01 4.15 7.22 3 8.99 3C10.47 3 11.48 3.67 12 4.52C12.44 5.24 12.7 6.59 12.02 7.6Z" className="cls-1" />
    </svg>
  </IconQuestionBox>
)
