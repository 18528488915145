import React from 'react'
import styled from 'styled-components/macro'

const IconEnterBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }
  .cls-2 {
    fill: #fff;
  }
`

export const IconEnter = ({ className = '', ...props }) => (
  <IconEnterBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="_24px_3_"
      width="24"
      height="24"
      data-name="24px (3)"
      viewBox="0 0 24 24"
    >
      <path id="Path_545" d="M0 0h24v24H0z" className="cls-1" data-name="Path 545" />
      <path
        id="Path_546"
        d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z"
        className="cls-2"
        data-name="Path 546"
      />
    </svg>
  </IconEnterBox>
)
