import React from 'react'
import styled from 'styled-components/macro'

const IconFeatherBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }

  .cls-2 {
    clip-path: url(#clip-path-feather);
  }

  .cls-3 {
    fill: #3c3c3c;
  }
`

export const IconFeather = ({ className = '', ...props }) => (
  <IconFeatherBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
      <defs>
        <clipPath id="clip-path-feather">
          <path id="Rectangle_1224" d="M0 0h100v100H0z" className="cls-1" data-name="Rectangle 1224" />
        </clipPath>
      </defs>
      <g id="ic_feather" className="cls-2">
        <path id="Rectangle_533" d="M0 0h100v100H0z" className="cls-1" data-name="Rectangle 533" />
        <g id="bird-quill-or-leaf-shape" transform="translate(16.663 16.671)">
          <path
            id="Union_1"
            d="M-2396.167-224.336v-4.164h12.5v4.167zm-33.333 0v-4.164h25v4.167zm-12.5 0l10.417-16.664c2-9.167 10.162-31.667 18.7-40.187 9.659-9.662 31.3-8.775 37.546-9.808-1.034 6.25.1 16.354-9.563 26.017a62.979 62.979 0 0 1-2.646 2.5c-8.5-1.975-12.125-.346-16.958 4.425a20.514 20.514 0 0 1 10.913.908 107.654 107.654 0 0 1-33.829 18.213l-8.333 14.583zm16.25-20.713a8.829 8.829 0 0 0-.9 1.367s.317-.5.9-1.367c6.121-8.071 37.216-36.775 39.979-39.879-23.929 10.721-36.158 34.208-39.979 39.879z"
            className="cls-3"
            data-name="Union 1"
            transform="translate(2442 290.999)"
          />
        </g>
      </g>
    </svg>
  </IconFeatherBox>
)
