import React from 'react'
import styled from 'styled-components/macro'

const IconSearchBox = styled.div`
  .cls-1 {
    fill: none;
  }
`

export const IconSearch = ({ className = '', ...props }) => (
  <IconSearchBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="outline-search-24px"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path id="Path_245" d="M0 0h24v24H0z" className="cls-1" data-name="Path 245" />
      <path
        id="Path_246"
        d="M15.5 14h-.79l-.28-.27a6.51 6.51 0 1 0-.7.7l.27.28v.79l5 4.99L20.49 19zm-6 0A4.5 4.5 0 1 1 14 9.5 4.494 4.494 0 0 1 9.5 14z"
        data-name="Path 246"
        className="cls-2"
      />
    </svg>
  </IconSearchBox>
)
