import React from 'react'
import styled from 'styled-components/macro'

const IconPlusBox = styled.div`
  height: 15px;
  width: 15px;
  display: flex;

  .cls-1 {
    fill: #fff;
    stroke: #484b50;
  }
`

export const IconPlus = ({ className = '', ...props }) => (
  <IconPlusBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <path
        id="Path_531"
        d="M12.4 8H9.6v5.6H4v2.8h5.6V22h2.8v-5.6H18v-2.8h-5.6z"
        className="cls-1"
        data-name="Path 531"
        transform="translate(-3.5 -7.5)"
      />
    </svg>
  </IconPlusBox>
)
