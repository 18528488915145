import React from 'react'
import styled from 'styled-components/macro'

const IconLongreadBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #3C3C3C;
  }
`

export const IconLongread = ({ className = '', ...props }) => (
  <IconLongreadBox className={className} {...props}>
    <svg width="100" height="100" viewBox="0 0 90 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M26.3684 9.1579C25.6579 9.1579 25.2237 9.86842 25.2237 10.5C25.2237 11.1316 25.6579 11.8421 26.3684 11.8421H79.6579C80.3684 11.8421 80.8026 11.1316 80.8026 10.5C80.8026 9.86842 80.3684 9.1579 79.6579 9.1579H26.3684ZM26.3684 18.3947C25.6579 18.3947 25.2237 19.1053 25.2237 19.7368C25.2237 20.3684 25.6579 21.0789 26.3684 21.0789H79.6579C80.3684 21.0789 80.8026 20.3684 80.8026 19.7368C80.8026 19.1053 80.3684 18.3947 79.6579 18.3947H26.3684ZM26.3684 27.5526C25.6579 27.5526 25.2237 28.2632 25.2237 28.8947C25.2237 29.5263 25.6579 30.2368 26.3684 30.2368H79.6579C80.3684 30.2368 80.8026 29.5263 80.8026 28.8947C80.8026 28.2632 80.3684 27.5526 79.6579 27.5526H26.3684ZM90 4.14474C90 4.10526 90 4.06579 90 4.02632V3.98684C90 1.77632 88.2237 0 86.0132 0H33.5526L24.3553 0.118421C18.0395 0.197368 13.7368 4.22368 13.7368 10.5789V31.2237C13.7368 31.6974 13.3421 32.0921 12.8684 32.0921H3.98684C1.77632 32.0921 0 33.9079 0 36.1184V72.2763C0.0394737 74.4474 1.77632 76.2237 3.94737 76.2632H54.1579C58.7368 76.2632 62.4474 72.5132 62.4474 67.9737V45.0395C62.4474 44.5658 62.8421 44.1711 63.3158 44.1711H86.0132C88.2237 44.1711 89.9605 42.3553 89.9605 40.1447V4.22368L90 4.14474ZM84.5526 41.25H24.3553C22.1447 41.25 19.7763 42.0395 18 43.3816C17.7237 43.5789 17.4079 43.6184 17.0921 43.4605C16.7763 43.3026 16.6184 43.0263 16.6184 42.6711V10.5789C16.6184 6.19737 19.9737 2.84211 24.3553 2.84211H84.5526C85.9737 2.84211 87.1579 3.98684 87.1579 5.44737V38.9605V39C87 40.3026 85.8947 41.25 84.5921 41.25H84.5526ZM5.44737 34.9342H12.9079C13.3816 34.9342 13.7763 35.3289 13.7763 35.8026V51.8684C13.7763 57.7895 18.4342 62.4474 24.3553 62.4474H54.1579C57.1579 62.4474 59.6053 64.8947 59.6053 67.8947C59.6053 70.8947 57.1579 73.3421 54.1579 73.3421H5.44737C4.02632 73.3421 2.84211 72.1974 2.84211 70.7368V37.5C2.84211 36.0789 3.98684 34.8947 5.44737 34.8947V34.9342Z" fill="#3C3C3C" />
    </svg>

  </IconLongreadBox>
)
