import React, { useState } from 'react'
import styled from 'styled-components/macro'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'

const AccordionBox = styled.div`
  &.opened {
    .toggle {
      .icon-arrow {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .accordion-toggle {
    width: 100%;
    height: 40px;
    padding: 0 24px;
    box-sizing: border-box;
    background-color: #d5d5d8;
    font-family: ${FONTS.roboto};
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
`

export const Accordion = ({ title, children, className = '' }) => {
  const [opened, toggle] = useState(true)

  return (
    <AccordionBox className={classNames(className, { opened })}>
      <div className="accordion-toggle" onClick={() => toggle(!opened)}>
        {title}
        <Icon type="arrow" />
      </div>

      {opened && <div className="accordion-content">{children}</div>}
    </AccordionBox>
  )
}
