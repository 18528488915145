import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import {
  withRouter, Link, Route, Switch,
} from 'react-router-dom'
import classNames from 'classnames'

import { Icon } from 'ui/atoms'
import logoImg from 'assets/images/logo.png'
import profileImg from 'assets/images/profile-preview.png'
import { ProfileLeftPanel } from 'features/Authorization/ProfileLeftPanel'
import { FONTS } from 'constants/fonts'
import { Search } from './Search'
import { Tooltip } from './Tooltip'

const LeftPanelBox = styled.div`
  height: 100vh;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10000;

  .profile-left-panel {
    position: absolute;
    left: 0;
    height: 100vh;
    width: 507px;
    z-index: 222;
  }

  @media screen and (max-width: 1649px) {
    height: calc(100vh * 1.45);

    .profile-left-panel {
      height: calc(100vh * 1.45);
    }
  }
`

const Controls = styled.div`
  width: 108px;
  padding-top: 24px;
  box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.25);
  background-color: #b96d3e;
  display: flex;
  align-items: center;
  flex-direction: column;

  .logo {
    width: 60px;
    height: 60px;
    background: black;
    border-radius: 50%;
  }

  .photo {
    margin-top: 24px;
    width: 60px;
    height: 60px;
    background: pink;
    border-radius: 50%;
    cursor: pointer;
  }

  .icon-home,
  .icon-grid {
    background: #995d39;

    &:hover {
      background: #c78a65;
    }

    .cls-2 {
      fill: black;
    }
  }

  .icon-search,
  .icon-home,
  .icon-grid {
    margin-top: 32px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    background: #995d39;

    &:hover {
      background: #c78a65;
    }

    &.active {
      background: black;

      .cls-2 {
        fill: white;
      }
    }
  }

  .icon-library {
    margin-top: 24px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    background: #995d39;

    &:hover {
      background: #c78a65;
    }

    &:not(.active) {
      .cls-3 {
        fill: #995d39;
      }

      .cls-2 {
        fill: black;
      }
    }

    &.active {
      background: black;
    }
  }

  .icon-create-article {
    margin-top: 24px;
    cursor: pointer;

    &:hover {

      &:not(.active) {
        .cls-3 {
          fill: #c78a65;
        }
      }
    }

    &:not(.active) {
      .cls-3 {
        fill: #995d39;
      }

      .cls-2 {
        fill: black;
      }
    }
  }
`

const Content = styled.div`
  width: 399px;
  background-color: #484b50;

  .toggle {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 35px;
    right: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #897b6a;
    cursor: pointer;

    .icon-arrow {
      svg {
        transform: rotate(90deg);

        .cls-1 {
          fill: white;
        }
      }
    }
  }
`

const SearchControl = styled.div`
  margin-right: 140px;
  width: 114px;
  padding-left: 29px;
  padding-top: 35px;
  height: 30px;
  border-radius: 16px;
  position: relative;
  cursor: pointer;

  .icon-zoom {
    position: absolute;
    cursor: pointer;
  }

  .search-input {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    background-color: #e7e8ea;
    outline: none;
    padding-left: 40px;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: black;
    box-sizing: border-box;
    border: none;

    &::placeholder {
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }
  }
`

export const LeftPanel = withRouter(({ location }) => {
  const [profilePanelOpened, setProfilePanelStatus] = useState(false)
  const [searchOpened, setSearchPanelStatus] = useState(false)

  const userInfo = useSelector((state) => state.user.info)

  const isRedactor = location.pathname.startsWith('/redactor')
  // const isNews = location.pathname.startsWith('/news')
  const isLibrary = location.pathname.startsWith('/library')
  const isGrid = location.pathname.startsWith('/grid')
  // const isHome = !isRedactor && !isNews && !isLibrary && !isGrid

  return (
    <LeftPanelBox className="left-panel">
      <Controls className="controls">
        <img className="logo" src={logoImg} alt="logo" />
        <Tooltip text="Выйти">
          <div onClick={() => setProfilePanelStatus(true)}>
            <img className="photo" src={profileImg} alt="profile" />
          </div>
        </Tooltip>

        {/* <Link to="/">
          <Icon type="home" className={classNames({ active: isHome })} />
        </Link> */}

        {/* <Link to="/news">
          <Icon type="search" className={classNames({ active: isNews })} />
        </Link> */}

        <Tooltip text="Редактор">
          <Link to="/redactor">
            <Icon type="create-article" className={classNames({ active: isRedactor })} />
          </Link>
        </Tooltip>

        <Tooltip text="База материалов">
          <Link to="/library">
            <Icon type="library" className={classNames({ active: isLibrary })} />
          </Link>
        </Tooltip>

        {process.env.REACT_APP_IS_PROD && userInfo.email === 'd.stepnov@rusplt.ru'
        && (
        <Link to="/grid">
          <Icon type="grid" className={classNames({ active: isGrid })} />
        </Link>
        )}

        {!process.env.REACT_APP_IS_PROD
        && (
        <Link to="/grid">
          <Icon type="grid" className={classNames({ active: isGrid })} />
        </Link>
        )}
      </Controls>

      <Switch>
        <Route path="/redactor/news">
          {searchOpened && (
          <Content className="content">
            <Search onClose={() => setSearchPanelStatus(false)} />
          </Content>
          )}
          {!searchOpened && (
          <SearchControl onClick={() => setSearchPanelStatus(true)}>
            <Icon type="zoom" />
            <input
              placeholder="Поиск..."
              className="search-input"
            />
          </SearchControl>
          )}
        </Route>
        <Route path="/redactor/article">
          {searchOpened && (
          <Content className="content">
            <Search onClose={() => setSearchPanelStatus(false)} />
          </Content>
          )}
          {!searchOpened && (
          <SearchControl onClick={() => setSearchPanelStatus(true)}>
            <Icon type="zoom" />
            <input
              placeholder="Поиск..."
              className="search-input"
            />
          </SearchControl>
          )}
        </Route>
        <Route path="/redactor/live">
          {searchOpened && (
          <Content className="content">
            <Search onClose={() => setSearchPanelStatus(false)} />
          </Content>
          )}
          {!searchOpened && (
          <SearchControl onClick={() => setSearchPanelStatus(true)}>
            <Icon type="zoom" />
            <input
              placeholder="Поиск..."
              className="search-input"
            />
          </SearchControl>
          )}
        </Route>
        <Route path="/redactor/longread">
          {searchOpened && (
          <Content className="content">
            <Search onClose={() => setSearchPanelStatus(false)} />
          </Content>
          )}
          {!searchOpened && (
          <SearchControl onClick={() => setSearchPanelStatus(true)}>
            <Icon type="zoom" />
            <input
              placeholder="Поиск..."
              className="search-input"
            />
          </SearchControl>
          )}
        </Route>
      </Switch>

      {profilePanelOpened && <ProfileLeftPanel className="profile-left-panel" onClose={() => setProfilePanelStatus(false)} />}
    </LeftPanelBox>
  )
})

export const withLeftPanel = (Component) => (props) => (
  <>
    <LeftPanel />
    <Component {...props} />
  </>
)
