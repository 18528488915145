import React from 'react'
import styled from 'styled-components/macro'

const IconPlayBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #fff;
  }

  .cls-2 {
    fill: none;
  }
`

export const IconPlay = ({ className = '', ...props }) => (
  <IconPlayBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="_24px_10_" data-name="24px (10)">
        <path
          id="Path_557"
          d="M8 5v11.667l9.167-5.833z"
          className="cls-1"
          data-name="Path 557"
          transform="translate(-1.333 -.833)"
        />
        <path id="Path_558" d="M0 0h20v20H0z" className="cls-2" data-name="Path 558" />
      </g>
    </svg>
  </IconPlayBox>
)
