import React from 'react'
import styled from 'styled-components/macro'

const IconEllipsisBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #897b6a;
  }
`

export const IconEllipsis = ({ className = '', ...props }) => (
  <IconEllipsisBox className={className} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Component_39_3"
      width="4"
      height="16"
      data-name="Component 39 – 3"
      viewBox="0 0 4 16"
    >
      <path
        id="Path_573"
        d="M12 8a2 2 0 1 0-2-2 2.006 2.006 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2.006 2.006 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2.006 2.006 0 0 0-2-2z"
        className="cls-1"
        data-name="Path 573"
        transform="translate(-10 -4)"
      />
    </svg>
  </IconEllipsisBox>
)
