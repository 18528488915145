import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { compose } from 'recompose'

import { withPageLayout } from 'ui/templates'

import { LeftPanel } from 'features/LeftPanel'
import {
  loadAuthorsList, loadRubricsList, loadTagsList, loadPseudonyms,
} from 'redux/actions/news'
import { appRoutes } from './routes'

const AppBox = styled.div`
  display: flex;
  flex-grow: 1;
`

const App = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.info)


  useEffect(() => {
    if (user.token) {
      dispatch(loadAuthorsList())
      dispatch(loadRubricsList())
      dispatch(loadTagsList())
      dispatch(loadPseudonyms())
    }
  }, [dispatch, user.token])

  return (
    <AppBox>
      <LeftPanel />
      {appRoutes()}
    </AppBox>
  )
}

const enhanced = compose(withPageLayout)(App)

export { enhanced as App }
