import React from 'react'
import styled from 'styled-components/macro'

const IconFireBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }
  .cls-2 {
    opacity: 0.9;
    clip-path: url(#clip-path);
  }
  .cls-3 {
    fill: #c13b3b;
  }
  .cls-4 {
    fill: #fff;
  }
`

export const IconFire = ({ className = '', ...props }) => (
  <IconFireBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <path d="M0 0h24v24H0z" className="cls-1" />
        </clipPath>
      </defs>
      <g id="Fire" className="cls-2">
        <circle id="Ellipse_285" cx="12" cy="12" r="12" className="cls-3" data-name="Ellipse 285" />
        <g id="Group_378" data-name="Group 378" transform="translate(4 2)">
          <path
            id="Path_255"
            d="M78.957 11.71a11.788 11.788 0 0 0-2.843-6.5C74.98 3.681 74 2.363 74 .417a.417.417 0 0 0-.226-.371.413.413 0 0 0-.433.032 10.6 10.6 0 0 0-3.912 5.653A18.453 18.453 0 0 0 69 9.957c-1.7-.363-2.084-2.9-2.088-2.932a.417.417 0 0 0-.594-.316c-.089.043-2.187 1.109-2.309 5.365-.009.142-.009.284-.009.426a7.508 7.508 0 0 0 7.5 7.5.058.058 0 0 0 .017 0h.005A7.509 7.509 0 0 0 79 12.5c0-.208-.043-.79-.043-.79zM71.5 19.166A2.588 2.588 0 0 1 69 16.5v-.162a3.378 3.378 0 0 1 .263-1.323 1.522 1.522 0 0 0 1.376 1 .416.416 0 0 0 .417-.417 8.335 8.335 0 0 1 .16-1.9 4.029 4.029 0 0 1 .844-1.6 5.369 5.369 0 0 0 .859 1.576 4.747 4.747 0 0 1 1.068 2.6c.005.072.011.144.011.221a2.588 2.588 0 0 1-2.498 2.671z"
            className="cls-4"
            data-name="Path 255"
            transform="translate(-64)"
          />
        </g>
      </g>
    </svg>
  </IconFireBox>
)
