import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useDatepicker, START_DATE, useMonth } from 'assets/datepicker/index.cjs'
import { format, parse } from 'date-fns'
import { ru } from 'date-fns/locale'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { useOnClickOutside } from 'hooks'
import { Icon } from 'ui/atoms'
import { Month } from './Month'
import { Time } from './Time'
import { DatepickerContext } from './context'

const DatepickerBox = styled.div`
  margin-top: 12px;
  border-radius: 4px;
  overflow: hidden;
  width: 292px;
  height: 267px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  background-color: #484b50;
  box-sizing: border-box;

  &.single {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .top-controls {
    display: flex;
    align-items: center;
    height: 43px;
    width: 100%;
  }

  .time-control {
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${FONTS.roboto};
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #c3b29b;
    background-color: #626469;
    cursor: pointer;

    &.active {
      background-color: #484b50;
    }
  }

  .month-controls {
    display: flex;
    align-items: center;
    width: 100%;
    height: 43px;
    background-color: #626469;
    justify-content: space-between;
    cursor: pointer;

    &.active {
      background-color: #484b50;

      .icon-arrow {
        pointer-events: initial;
      }
    }

    .icon-arrow {
      pointer-events: none;
      cursor: pointer;

      .cls-1 {
        fill: white;
      }
    }

    .arrow-left {
      transform: rotate(90deg)
    }

    .arrow-right {
      transform: rotate(-90deg)
    }

    .month {
      font-family: ${FONTS.roboto};
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #c3b29b;
    }
  }

  .week-days {
    height: 34px;
    border-top: 1px solid #515358;
    border-bottom: 1px solid #515358;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    align-items: center;

    .day {
      font-family: ${FONTS.roboto};
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: normal;
      text-align: center;
      color: #999999;
      text-transform: uppercase;
    }
  }

  .days-numbers-wrapper {
    display: grid;
    padding-top: 5px;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
  }
  
  .day-number {
    margin-bottom: 7px;
    width: 23.4px;
    height: 23.3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${FONTS.roboto};
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: white;
    align-self: center;
    justify-self: center;
    cursor: pointer;
    outline: none;

    &:hover {
      background: #626469;
    }

    &.selected {
      background: #ce914a;
    }
  }
`

const DatepickerToggle = styled.div`
  margin-top: 12px;
  width: 292px;
  height: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #484b50;
  font-family: ${FONTS.roboto};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;

`

export function Datepicker({
  onSelect, initialStartDate, initialEndDate, withRange, withTime, withoutToggle, opened: openedFromProps, onClose,
}) {
  const [datepickerState, setDatepickerState] = useState({
    startDate: initialStartDate || new Date(),
    endDate: !withRange ? initialStartDate : (initialEndDate || new Date()),
    focusedInput: START_DATE,
  })

  const [state, setState] = useState({
    opened: openedFromProps || false,
    time: initialStartDate ? format(initialStartDate, 'HH:mm') : format(new Date(), 'HH:mm'),
    activeTab: 'month',
  })

  const datepickerRef = useRef()

  useOnClickOutside(datepickerRef, () => {
    setState({ ...state, opened: false })

    if (withoutToggle && onClose) {
      onClose()

      return
    }

    if (withRange) {
      onSelect([datepickerState.startDate, datepickerState.startDate === datepickerState.endDate ? null : datepickerState.endDate])

      return
    }

    const dateFormatted = `${format(datepickerState.startDate, 'MM/dd/yyyy')} ${state.time}`

    onSelect(parse(dateFormatted, 'MM/dd/yyyy HH:mm', new Date()))

    if (onClose) {
      onClose()
    }
  }, [])

  useEffect(() => {
    setState({ ...state, opened: openedFromProps })
  }, [openedFromProps])

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: datepickerState.startDate,
    endDate: datepickerState.endDate,
    focusedInput: datepickerState.focusedInput,
    // eslint-disable-next-line
    onDatesChange: handleDateChange,
    numberOfMonths: 1,
  })

  function handleDateChange(data) {
    if (!withRange) {
      setDatepickerState({
        ...datepickerState, ...data, endDate: data.startDate, focusedInput: START_DATE,
      })

      if (withoutToggle && onClose) {
        const dateFormatted = `${format(data.startDate, 'MM/dd/yyyy')} ${state.time}`

        onSelect(parse(dateFormatted, 'MM/dd/yyyy HH:mm', new Date()))
        onClose()
      }

      return
    }

    if (!data.focusedInput) {
      setDatepickerState({ ...datepickerState, ...data, focusedInput: START_DATE })
    } else {
      setDatepickerState(data)
    }
  }

  function onTimeChange(time) {
    setState({ ...state, time })
  }

  const activeMonth = activeMonths[0]

  const { monthLabel } = useMonth({
    year: activeMonth.year,
    month: activeMonth.month,
    firstDayOfWeek,
  })

  if (!state.opened && !withoutToggle) {
    return (
      <DatepickerToggle onClick={() => setState({ ...state, opened: true })}>
        {format(datepickerState.startDate, 'dd MMMMMM, yyyy', { locale: ru })}

        {datepickerState.endDate
        && (new Date(datepickerState.endDate)).getDate() !== (new Date(datepickerState.startDate)).getDate()
        && ` - ${format(datepickerState.endDate, 'dd MMMM, yyyy', { locale: ru })}`}

        {withTime && ` ${state.time}`}
      </DatepickerToggle>
    )
  }

  if (withoutToggle && !state.opened) {
    return null
  }

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      <DatepickerBox ref={datepickerRef} className={classNames({ single: withoutToggle, showed: withoutToggle && state.opened })}>
        <div className="top-controls">
          {withTime && (
          <div className={classNames('time-control', { active: state.activeTab === 'time' })} onClick={() => setState({ ...state, activeTab: 'time' })}>
            {state.time}
          </div>
          )}
          <div
            className={classNames('month-controls', { active: state.activeTab === 'month' })}
            onClick={() => setState({ ...state, activeTab: 'month' })}
          >
            <Icon type="arrow" onClick={goToPreviousMonths} className="arrow-left" />
            <div className="month">
              {/* eslint-disable-next-line */}
              {monthLabel.split('').reduce((r, m, i) => (r += i === 0 ? m.toUpperCase() : m), '')}
            </div>
            <Icon type="arrow" onClick={goToNextMonths} className="arrow-right" />
          </div>
        </div>


        {withTime && state.activeTab === 'time' && (
          <Time
            time={state.time}
            onChange={onTimeChange}
          />
        )}

        {(state.activeTab === 'month' || !withTime) && activeMonths.map((month) => (
          <Month
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            firstDayOfWeek={firstDayOfWeek}
            goToNextMonths={goToNextMonths}
            goToPreviousMonths={goToPreviousMonths}
          />
        ))}
      </DatepickerBox>
    </DatepickerContext.Provider>
  )
}
