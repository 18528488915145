import React from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

const PageLayoutBox = styled.div`
  display: flex;
  width: 100%;
  max-width: calc(100vw - 108px);

  &.blured {
    filter: blur(6px);
  }

  @media screen and (max-width: 1649px) {
    max-width: initial;
  }
`

const PageLayout = ({ children }) => {
  const blured = useSelector((state) => state.app.pageLayoutBlured)

  return <PageLayoutBox className={classNames({ blured })}>{children}</PageLayoutBox>
}

export const withPageLayout = (Component) => (props) => (
  <PageLayout>
    <Component {...props} />
  </PageLayout>
)
