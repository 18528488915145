import React, { useState } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'
import { Confirm } from 'ui/molecules'
import { convertLettersCountToMinutes } from 'helpers'

const BottomControlsBox = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 352px;
  backdrop-filter: blur(2.7px);
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #484b50;

  .letters-row {
    background: #6d6f73;
  }

  .letters {
    width: 352px;
    position: relative;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 0 18px 0 8px;
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: -0.25px;
    text-align: left;
    color: #ffffff;

    .time {
      width: 100px;
      position: absolute;
      top: 0;
      right: 17px;
      height: 24px;
      border-radius: 12px;
      padding: 0 8px;
      background-color: #a4a5a7;
      color: black;
      display: flex;
      align-items: center;
      box-sizing: border-box;

      .icon-timer {
        margin-right: 4px;
        width: 18px;
        height: 18px;

        svg {
          width: 100%;
          height: 100%;
        }

        .cls-2 {
          fill: #1d1f24;
        }
      }
    }
  }

  .bottom-buttons {
    height: 155px;
    padding: 18px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .show-preview {
      margin-right: 16px;
      width: 111px;
      height: 32px;
      border-radius: 21px;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
      text-transform: uppercase;
      font-family: ${FONTS.roboto};
      font-size: 14px;
      text-align: left;
      color: black;
      display: flex;
      align-items: center;
      padding: 0 2px;
      cursor: pointer;

      &.disabled {
        box-shadow: none;

        .play {
          .icon-play {
            .cls-1 {
              fill: #454545;
            }
          }
        }
      }

      .play {
        margin-right: 7px;
        width: 30px;
        height: 30px;
        border: solid 3px #454545;
        background-color: #6c6c6c;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        .icon-play {
          width: 20px;
          height: 20px;
        }
      }
    }

    .check-uniq {
      margin-top: 12px;
      width: 298px;
      height: 32px;
      border-radius: 21px;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
      text-transform: uppercase;
      font-family: ${FONTS.roboto};
      font-size: 14px;
      text-align: left;
      color: black;
      display: flex;
      align-items: center;
      padding: 0 2px;
      cursor: pointer;

      .uniq-icon-wrapper {
        margin-right: 7px;
        width: 30px;
        height: 30px;
        border: solid 3px #454545;
        background-color: #6c6c6c;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
      }

      &.active {
        background: #CE914A;
        color: #1D1F24;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .publish {
      width: 167px;
      height: 32px;
      border-radius: 21px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      background-color: #ce914a;
      text-transform: uppercase;
      font-family: ${FONTS.roboto};
      font-size: 14px;
      text-align: left;
      color: black;
      display: flex;
      align-items: center;
      padding: 0 2px;
      cursor: pointer;

      &.disabled {
        box-shadow: none;

        .check {
          .icon-check {
            .cls-2 {
              fill: #454545;
            }
          }
        }
      }

      &.pointer-events-disabled {
        pointer-events: none;
      }

      .fa-spin {
        margin-left: 8px;
        margin-right: 8px;
      }

      .check {
        margin-right: 8px;
        width: 30px;
        height: 30px;
        border: solid 3px #454545;
        background-color: #6c6c6c;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        .icon-check {
          width: 16px;
          height: 16px;

          svg {
            width: 16px;
            height: 16px;

            .cls-2 {
              fill: white;
            }
          }
        }
      }
    }

    .unpublish {
      margin-top: 12px;
      width: 298px;
      height: 32px;
      border-radius: 21px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      background-color: #de4e4e;
      display: flex;
      align-items: center;
      padding: 0 2px;
      font-family: ${FONTS.roboto};
      font-size: 14px;
      line-height: 1.57;
      color: white;
      cursor: pointer;
      text-transform: uppercase;

      .eye-wrapper {
        margin-right: 36px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #c13b3b;
        border-radius: 50%;
      }

      .icon-eye-crossed {
        border-radius: 50%;

        svg {
          border-radius: 50%;
        }

        .cls-1 {
          fill: #c13b3b;
        }
      }
    }
  }
`

const UpdateButton = ({
  publishDisabled, changed, update, validationEnabled,
}) => {
  const [loading, setLoading] = useState(false)

  async function _onApply() {
    setLoading(true)

    await update()

    setLoading(false)
  }

  return (
    <div
      className={classNames('publish', { disabled: publishDisabled && validationEnabled, 'pointer-events-disabled': !changed || loading || (publishDisabled && validationEnabled), loading })}
      onClick={_onApply}
    >
      {loading ? <i className="fa fa-spinner fa-spin" /> : (
        <div className="check">
          <Icon type="check" />
        </div>
      )}

      Обновить
    </div>
  )
}

export const BottomControls = ({
  lettersCount,
  published,
  onShowPreview,
  previewDisabled,
  publishDisabled,
  unpublish,
  changed,
  showRequiredNotification,
  update,
  publish,
  validationEnabled,
  uniqPercent,
  onUniqCheck,
}) => {
  const [confirmOpened, setConfirmState] = useState(false)
  const [uniqBtnDisabled, toggleUniqBtn] = useState(false)

  function _unpublish() {
    unpublish()
    setConfirmState(false)
  }

  function handlePublish() {
    if (!validationEnabled) {
      publish()

      return
    }

    if (publishDisabled) {
      showRequiredNotification()
    } else {
      onShowPreview()
    }
  }

  async function handleUniqClick() {
    toggleUniqBtn(true)

    await onUniqCheck()

    toggleUniqBtn(false)
  }

  return (
    <BottomControlsBox>
      <div className="letters-row">
        <div className="letters">
          Знаков:
          {' '}
          {lettersCount}
          <div className="time">
            <Icon type="timer" />
            {' '}
            {convertLettersCountToMinutes(lettersCount)}
            {' '}
            мин
          </div>
        </div>
      </div>

      <div className="bottom-buttons">
        {/* {published && (
        <div className="link-to-publication">
            Материал доступен по ссылке:
          {' '}
          <a href={url}>{url}</a>
        </div>
      )} */}

        <div
          className={classNames('show-preview', { disabled: previewDisabled })}
          onClick={previewDisabled && validationEnabled ? showRequiredNotification : onShowPreview}
        >
          <div className="play">
            <Icon type="play" />
          </div>
          превью
        </div>

        {published ? (
          <UpdateButton publishDisabled={publishDisabled || previewDisabled} changed={changed} update={update} validationEnabled={validationEnabled} />
        ) : (
          <div
            className={classNames('publish', { disabled: publishDisabled && validationEnabled })}
            onClick={handlePublish}
          >
            <div className="check">
              <Icon type="check" />
            </div>
            Опубликовать
          </div>
        )}

        <div
          onClick={handleUniqClick}
          className={classNames('check-uniq', {
            active: !!uniqPercent,
            disabled: uniqBtnDisabled,
          })}
        >
          <div className="uniq-icon-wrapper">
            <Icon type="uniq" />
          </div>
          {uniqPercent ? `Уникальность: ${uniqPercent}%` : 'Проверить на уникальность'}
        </div>

        {published && (
        <div className="unpublish" onClick={() => setConfirmState(true)}>
          <div className="eye-wrapper">
            <Icon type="eye-crossed" />
          </div>
          Снять с публикации
        </div>
        )}
      </div>

      <Confirm
        okText="Снять"
        cancelText="Отмена"
        question="Вы уверены, что хотите снять материал с публикации?"
        open={confirmOpened}
        onOk={_unpublish}
        onCancel={() => setConfirmState(false)}
      />
    </BottomControlsBox>
  )
}
