import React from 'react'
import styled from 'styled-components/macro'

const IconCheckBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: none;
  }
  .cls-2 {
    fill: #1d1f24;
  }
`

export const IconCheck = ({ className = '', ...props }) => (
  <IconCheckBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="_24px" data-name="24">
        <path id="Path_535" d="M0 0h24v24H0z" className="cls-1" data-name="Path 535" />
        <path
          id="Path_536"
          d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
          className="cls-2"
          data-name="Path 536"
        />
      </g>
    </svg>
  </IconCheckBox>
)
