import React from 'react'
import styled from 'styled-components/macro'

const IconUndoBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1{fill:none}.cls-2{fill:#484b50}
`

export const IconUndo = ({ className = '', ...props }) => (
  <IconUndoBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" id="undo-arrow-of-circular-shape-with-half-broken-line" width="24" height="24" viewBox="0 0 24 24">
      <path id="Rectangle_1210" d="M0 0h24v24H0z" className="cls-1" data-name="Rectangle 1210" />
      <g id="Group_477" data-name="Group 477" transform="translate(3 3)">
        <path id="Path_563" d="M134 349.971a8.606 8.606 0 0 0 3.079.828v-2.707a5.948 5.948 0 0 1-1.739-.442z" className="cls-2" data-name="Path 563" transform="translate(-128.403 -333.456)" />
        <path id="Path_564" d="M15.293 15.888H18a8.68 8.68 0 0 0-15.5-4.68L.005 9.765 0 18.029 7.159 13.9l-2.3-1.331a5.98 5.98 0 0 1 10.435 3.318z" className="cls-2" data-name="Path 564" transform="translate(0 -7.868)" />
        <path id="Path_565" d="M54.573 301.024l-2.338 1.35a8.766 8.766 0 0 0 2.256 2.257l1.35-2.339a6.06 6.06 0 0 1-1.268-1.268z" className="cls-2" data-name="Path 565" transform="translate(-50.053 -288.779)" />
        <path id="Path_566" d="M239.735 348.091v2.709a8.6 8.6 0 0 0 3.078-.828l-1.339-2.322a5.976 5.976 0 0 1-1.739.441z" className="cls-2" data-name="Path 566" transform="translate(-229.721 -333.456)" />
        <path id="Path_567" d="M308.859 302.292l1.35 2.339a8.775 8.775 0 0 0 2.258-2.257l-2.339-1.35a6.023 6.023 0 0 1-1.269 1.268z" className="cls-2" data-name="Path 567" transform="translate(-295.958 -288.78)" />
        <path id="Path_568" d="M355.547 233.6l2.321 1.341a8.6 8.6 0 0 0 .828-3.078h-2.707a6.016 6.016 0 0 1-.442 1.737z" className="cls-2" data-name="Path 568" transform="translate(-340.695 -222.51)" />
      </g>
    </svg>
  </IconUndoBox>
)
