import React, { useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { Icon, NotSelected } from 'ui/atoms'
import { POSTS } from 'api/posts'
import { useOnClickOutside } from 'hooks'

const TagsBox = styled.div`
  margin-top: 12px;

  .title-row {
    margin-bottom: 8px;
    min-height: 16px;
    display: flex;
    align-items:center;
  }

  .title {
    font-family: ${FONTS.roboto};
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    opacity: 0.6;
    text-align: left;
    color: black;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .tags-input-wrapper {
    position: relative;

    input {
      z-index: 2;
      width: 100%;
      height: 36px;
      border-radius: 4px;
      border: solid 2px #c3b29b;
      background-color: #ffffff;
      outline: none;
      padding: 0 8px;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: black;
      box-sizing: border-box;

      &:hover,
      &:active,
      &:focus {
        border-color: #ce914a;
      }
    }

    .enter {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 40px;
      height: 26px;
      border-radius: 4px;
      background-color: black;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .options-dropdown {
      position: absolute;
      left: 0;
      top: 36px;
      width: 100%;
      /* max-height: 200px; */
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      background-color: #c3b29b;
      padding: 4px 0;
      box-sizing: border-box;
      z-index: 2;

      .option {
        width: 100%;
        height: 30px;
        cursor: pointer;
        color: black;
        display: flex;
        align-items: center;
        padding: 0 6px;
        font-family: ${FONTS.roboto};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: black;
        box-sizing: border-box;

        &.selected {
          background: rgba(255, 255, 255, 0.4);
        }

        &:hover {
          background: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .tag-placeholder {
    margin-bottom: 10px;
    margin-right: 8px;
    width: 44.7px;
    height: 24px;
    border-radius: 4px;
    border: solid 1px #707070;

    &.red {
      border: solid 1px #d56c45;
      background-color: rgba(213, 108, 69, 0.3);
    }
  }

  .selected-tags {
    margin-top: 9px;
    display: flex;
    flex-wrap: wrap;

    .selected-tag {
      margin-bottom: 10px;
      margin-right: 16px;
      height: 24px;
      border-radius: 4px;
      background-color: #f5f5f5;
      padding: 0 8px;
      display: flex;
      align-items: center;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: -0.25px;
      text-align: left;
      color: black;

      .delete {
        margin-left: 8px;
        width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: black;
        border-radius: 50%;
        cursor: pointer;

        .icon-cross {
          width: 15px;
          height: 15px;
        }

        svg {
          width: 15px;
          height: 15px;

          .cls-2 {
            stroke: white;
          }
        }
      }
    }
  }
`

export const Tags = ({ onSelect, onDelete, selectedTags }) => {
  const [tags, setTags] = useState([])
  const [selectedTagIdx, setSelectedTagIdx] = useState(0)
  const [inputValue, setInputValue] = useState('')

  const dropdownRef = useRef()

  useOnClickOutside(dropdownRef, () => setTags([]))

  const getSuggestions = useCallback(async (value) => {
    setInputValue(value)
    setSelectedTagIdx(0)

    const response = await POSTS.searchTags({ name: value })

    setTags(response.data)
  }, [])

  const onTagSelect = useCallback(
    (tag) => {
      if (selectedTags.length === 6) {
        return
      }

      setSelectedTagIdx(0)
      onSelect(tag)
      setTags([])
      setInputValue('')
    },
    [onSelect, selectedTags.length],
  )

  const onEnterPress = useCallback(() => {
    if (!inputValue) {
      return
    }

    if (tags.length) {
      onTagSelect(tags[selectedTagIdx])

      return
    }

    onTagSelect({ name: inputValue, _id: Math.random(), new: true })
  }, [inputValue, onTagSelect, tags, selectedTagIdx])

  const generateTipText = () => {
    let tipText = 'Добавьте минимум 3 тэга'

    if (selectedTags.length === 1) {
      tipText = 'Добавьте еще 2 тэга'
    }

    if (selectedTags.length === 2) {
      tipText = 'Добавьте еще 1 тэг'
    }

    if (selectedTags.length > 2) {
      tipText = 'Максимум 6 тэгов'
    }

    return tipText
  }

  const handleInputKeydown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onEnterPress()
    }

    if (event.keyCode === 40 && tags.length) {
      event.preventDefault()

      setSelectedTagIdx((currentIndex) => {
        if (currentIndex === tags.length - 1) {
          return 0
        }

        return currentIndex + 1
      })
    }

    if (event.keyCode === 38 && tags.length) {
      event.preventDefault()

      setSelectedTagIdx((currentIndex) => {
        if (currentIndex === 0) {
          return tags.length - 1
        }

        return currentIndex - 1
      })
    }
  }

  const onFocus = () => {
    getSuggestions()
  }

  return (
    <TagsBox>
      <div className="title-row">
        <div className="title">Тэги</div>
        <NotSelected text={generateTipText()} />
      </div>

      <div className="tags-input-wrapper">
        <input
          placeholder="Тэги 1-2 слова"
          onChange={(e) => getSuggestions(e.target.value)}
          value={inputValue}
          onKeyDown={handleInputKeydown}
          onFocus={onFocus}
        />

        <div className="enter" onClick={onEnterPress}>
          <Icon type="enter" />
        </div>

        {!!tags.length && (
          <div className="options-dropdown" ref={dropdownRef}>
            <Scrollbars autoHeight autoHeightMax="400px" renderTrackHorizontal={() => <div />}>
              {tags.map((tag, index) => (
                <div key={tag._id} onClick={() => onTagSelect(tag)} className={classNames('option', { selected: index === selectedTagIdx })}>
                  {tag.name}
                </div>
              ))}
            </Scrollbars>
          </div>
        )}
      </div>

      <div className="selected-tags">
        {selectedTags.map((tag) => (
          <div className="selected-tag" key={tag._id}>
            {tag.name}
            <div className="delete" onClick={() => onDelete(tag)}>
              <Icon type="cross" />
            </div>
          </div>
        ))}

        {selectedTags.length < 6 && Array.from({ length: 6 - selectedTags.length }).map((_, index) => (
          <div
            className={classNames('tag-placeholder', { red: 5 - selectedTags.length - index >= 3 })}
            key={index}
          />
        ))}
      </div>
    </TagsBox>
  )
}
