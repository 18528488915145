import React from 'react'
import styled from 'styled-components/macro'

import { FONTS } from 'constants/fonts'

const IconTooltipBox = styled.div`
  position: relative;
  z-index: 30;

  &:hover {
    z-index: 31;

    .tooltip {
      display: flex;
    }
  }

  .tooltip {
    position: absolute;
    top: -20px;
    left: 0;
    height: 18px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    background-color: #484b50;
    width: max-content;
    padding: 0 12px;
    box-sizing: border-box;
    display: none;
    align-items: center;
    font-family: ${FONTS.roboto};
    font-size: 11px;
    line-height: 1.83;
    letter-spacing: -0.19px;
    text-align: left;
    color: #ffffff;
  }
`

export const IconTooltip = ({ children, text, className }) => (
  <IconTooltipBox className={className}>
    {children}
    <div className="tooltip">{text}</div>
  </IconTooltipBox>
)
