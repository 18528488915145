import React from 'react'
import ReactDatetime from 'react-datetime'
import moment from 'moment'
import 'moment/locale/ru'

import { DatetimeBox } from './datetime.style'

export const Datetime = ({
  opened, onChange, setRef, onlyFuture = false, ...props
}) => {
  const isValidDate = (current) => {
    if (onlyFuture) {
      const now = moment()

      return now.diff(current, 'days') <= 0
    }

    return true
  }

  return (
    <DatetimeBox ref={setRef}>
      <ReactDatetime
        {...props}
        open={opened}
        onChange={onChange}
        isValidDate={isValidDate}
        locale="ru"
      />
    </DatetimeBox>
  )
}
