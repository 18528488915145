export const REDACTOR_CONFIG = {
  article: {
    requiredBlocks: [
      {
        type: 'title',
        placeholder: 'Главный заголовок статьи/новости, в одну или две строки',
        className: 'title-editor',
        withToolbar: false,
        withLettersCounter: true,
      },
      {
        type: 'lead',
        placeholder: 'Лид. Не более двух предложений',
        className: 'lead-editor',
        withToolbar: false,
      },
      {
        type: 'main-photo',
        placeholder: null,
        className: 'main-photo-editor',
        withToolbar: false,
      },
      {
        type: 'text',
        placeholder: 'Текстовый блок статьи/новости. Можно использовать жирный, курсив и жирный курсив (но лучше так не делать). Так же можно использовать ссылки, обязательно с текстом. ',
        className: 'text-editor',
        withToolbar: true,
        insertsAvailable: true,
        toolbarOptions: ['bold', 'italic', 'link'],
      },
    ],
    optionalBlocks: [
      {
        type: 'h2',
        iconType: 'h2',
        placeholder: 'Подзаголовок',
        className: 'h2-editor',
        withToolbar: false,
        toolbarOptions: [],
      },
      {
        type: 'text',
        iconType: 'text',
        placeholder: 'Текстовый блок статьи/новости. Можно использовать жирный, курсив и жирный курсив (но лучше так не делать). Так же можно использовать ссылки, обязательно с текстом.',
        className: 'text-editor',
        withToolbar: true,
        insertsAvailable: true,
        toolbarOptions: ['bold', 'italic', 'link'],
      },
      {
        type: 'quote',
        iconType: 'quote',
        placeholder: 'Цитата',
        className: 'quote-editor',
        withToolbar: true,
      },
      {
        type: 'image',
        iconType: 'image',
        placeholder: null,
        className: 'photo-editor',
        withToolbar: false,
      },
      {
        type: 'embedded',
        iconType: 'embed-link',
        placeholder: null,
        className: 'text-editor',
        withToolbar: false,
      },
      {
        type: 'main-in-text',
        iconType: 'main-in-text',
        placeholder: 'Главное в тексте',
        className: 'quote-editor',
        withToolbar: true,
      },
      {
        type: 'previously-by-theme',
        iconType: 'previous',
        className: 'previously-by-theme-editor',
        withToolbar: true,
      },
      {
        type: 'question',
        iconType: 'question',
        className: 'question',
        withToolbar: true,
      },
    ],
  },

  longread: {
    requiredBlocks: [
      {
        type: 'title',
        placeholder: 'Главный заголовок статьи/новости, в одну или две строки',
        className: 'title-editor',
        withToolbar: false,
        withLettersCounter: true,
      },
      {
        type: 'lead',
        placeholder: 'Лид. Не более двух предложений',
        className: 'lead-editor',
        withToolbar: false,
      },
      {
        type: 'main-photo',
        placeholder: null,
        className: 'main-photo-editor',
        withToolbar: false,
      },
    ],
    optionalBlocks: [
      {
        type: 'h2',
        iconType: 'h2',
        placeholder: 'Подзаголовок',
        className: 'h2-editor',
        withToolbar: false,
        toolbarOptions: [],
      },
      {
        type: 'text',
        iconType: 'text',
        placeholder: 'Текстовый блок статьи/новости. Можно использовать жирный, курсив и жирный курсив (но лучше так не делать). Так же можно использовать ссылки, обязательно с текстом.',
        className: 'text-editor',
        withToolbar: true,
        insertsAvailable: false,
        toolbarOptions: ['bold', 'italic', 'link'],
      },
      {
        type: 'quote',
        iconType: 'quote',
        placeholder: 'Цитата',
        className: 'quote-editor',
        withToolbar: true,
      },
      {
        type: 'image',
        iconType: 'image',
        placeholder: null,
        className: 'photo-editor',
        withToolbar: false,
      },
      {
        type: 'embedded',
        iconType: 'embed-link',
        placeholder: null,
        className: 'text-editor',
        withToolbar: false,
      },
      {
        type: 'main-in-text',
        iconType: 'main-in-text',
        placeholder: 'Главное в тексте',
        className: 'quote-editor',
        withToolbar: true,
      },
    ],
  },

  news: {
    requiredBlocks: [
      {
        type: 'title',
        placeholder: 'Главный заголовок статьи/новости, в одну или две строки',
        className: 'title-editor',
        withToolbar: false,
        withLettersCounter: true,
      },
      {
        type: 'main-photo',
        placeholder: null,
        className: 'main-photo-editor',
        withToolbar: false,
      },
      {
        type: 'text',
        placeholder: 'Текстовый блок статьи/новости. Можно использовать жирный, курсив и жирный курсив (но лучше так не делать). Так же можно использовать ссылки, обязательно с текстом.',
        className: 'text-editor',
        withToolbar: true,
        insertsAvailable: true,
        toolbarOptions: ['bold', 'italic', 'link'],
      },
    ],
    optionalBlocks: [
      {
        type: 'h2',
        iconType: 'h2',
        placeholder: 'Подзаголовок',
        className: 'h2-editor',
        withToolbar: false,
        toolbarOptions: [],
      },
      {
        type: 'text',
        iconType: 'text',
        placeholder: 'Текстовый блок статьи/новости. Можно использовать жирный, курсив и жирный курсив (но лучше так не делать). Так же можно использовать ссылки, обязательно с текстом.',
        className: 'text-editor',
        withToolbar: true,
        insertsAvailable: true,
        toolbarOptions: ['bold', 'italic', 'link'],
      },
      {
        type: 'quote',
        iconType: 'quote',
        placeholder: 'Цитата',
        className: 'quote-editor',
        withToolbar: true,
      },
      {
        type: 'image',
        iconType: 'image',
        placeholder: null,
        className: 'photo-editor',
        withToolbar: false,
      },
      {
        type: 'embedded',
        iconType: 'embed-link',
        placeholder: null,
        className: 'text-editor',
        withToolbar: false,
      },
      {
        type: 'main-in-text',
        iconType: 'main-in-text',
        placeholder: 'Главное в тексте',
        className: 'quote-editor',
        withToolbar: true,
      },
      {
        type: 'previously-by-theme',
        iconType: 'previous',
        className: 'previously-by-theme-editor',
        withToolbar: true,
      },
      {
        type: 'question',
        iconType: 'question',
        className: 'question',
        withToolbar: true,
      },
    ],
  },

  live: {
    requiredBlocks: [
      {
        type: 'title',
        placeholder: 'Главный заголовок статьи/новости, в одну или две строки',
        className: 'title-editor',
        withToolbar: false,
        withLettersCounter: true,
      },
      {
        type: 'main-photo',
        placeholder: null,
        className: 'main-photo-editor',
        withToolbar: false,
      },
      {
        type: 'live',
        placeholder: 'Текстовый блок лайв новости. Можно использовать жирный, курсив и жирный курсив (но лучше так не делать).',
        className: 'text-editor',
        withToolbar: true,
        toolbarOptions: ['bold', 'italic', 'link'],
      },
    ],
    optionalBlocks: [
      {
        type: 'h2',
        iconType: 'h2',
        placeholder: 'Подзаголовок',
        className: 'h2-editor',
        withToolbar: false,
        toolbarOptions: [],
      },
      {
        type: 'image',
        iconType: 'image',
        placeholder: null,
        className: 'photo-editor',
        withToolbar: false,
      },
      {
        type: 'live',
        iconType: 'text',
        placeholder: 'Текстовый блок статьи/новости. Можно использовать жирный, курсив и жирный курсив (но лучше так не делать)',
        className: 'text-editor',
        withToolbar: true,
        toolbarOptions: ['bold', 'italic', 'link'],
      },
      {
        type: 'main-in-text',
        iconType: 'main-in-text',
        placeholder: 'Главное в тексте',
        className: 'quote-editor',
        withToolbar: true,
      },
      {
        type: 'previously-by-theme',
        iconType: 'previous',
        className: 'previously-by-theme-editor',
        withToolbar: true,
      },
    ],
  },
}
