import React from 'react'
import styled from 'styled-components/macro'

const IconMoveBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;

  .cls-1 {
    fill: #d5d5d8;
  }
  .cls-2 {
    fill: #fff;
  }
`

export const IconMove = ({ className = '', ...props }) => (
  <IconMoveBox className={className} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Move_down" data-name="Move down" transform="rotate(180 12 12)">
        <rect id="Rectangle_1203" width="24" height="24" className="cls-1" data-name="Rectangle 1203" rx="4" />
        <g id="Group_475" data-name="Group 475" transform="translate(3.49)">
          <path
            id="Icon_awesome-long-arrow-alt-down"
            d="M5.7 11.53V2.6a.355.355 0 0 0-.36-.35H3.685a.355.355 0 0 0-.355.35v8.93H1.969a.709.709 0 0 0-.5 1.211l2.544 2.544a.709.709 0 0 0 1 0l2.544-2.544a.709.709 0 0 0-.5-1.211H5.7z"
            className="cls-2"
            data-name="Icon awesome-long-arrow-alt-down"
            transform="translate(3.742 1.749)"
          />
          <rect
            id="Rectangle_1201"
            width="16.5"
            height="2"
            className="cls-2"
            data-name="Rectangle 1201"
            rx="1"
            transform="translate(0 18)"
          />
        </g>
      </g>
    </svg>
  </IconMoveBox>
)
