import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { store } from 'redux/store'
import { GlobalStyle } from './style.global'
import { rootRoutes } from './routes'

function App() {
  useEffect(() => {
    if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
      document.querySelector('body').classList.add('safari')
    }
  }, [])

  return (
    <Provider store={store}>
      <BrowserRouter>{rootRoutes()}</BrowserRouter>
      <GlobalStyle />
    </Provider>
  )
}

export default App
