import { SET_USER_INFO } from '../actions/user'

const initialState = {
  info: JSON.parse(localStorage.getItem('userInfo')) || {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, info: action.payload }
    default:
      return state
  }
}
