import React from 'react'
import styled from 'styled-components'

import { FONTS } from 'constants/fonts'
import { PublicationDate } from 'features/MaterialOptions/PublicationDate'
import { Options } from 'ui/atoms'
import { ControlButtons } from './ControlButtons'

const locations = [
  {
    name: 'Мировые новости',
    id: 1,
  },
  {
    name: 'Россия',
    id: 2,
  },
  {
    name: 'Европа',
    id: 3,
  },
  {
    name: 'США',
    id: 4,
  },
  {
    name: 'Азия',
    id: 5,
  },
  {
    name: 'Ближний Восток',
    id: 6,
  },
]

const sources = [
  {
    name: 'ТОП-10 источников',
    id: 1,
  },
]

const NewsFiltersBox = styled.div`
  width: 400px;
  min-width: 400px;
  background: #e7e8ea;
  padding: 40px 36px;
  box-sizing: border-box;

  .title {
    font-family: ${FONTS.roboto};
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.92;
    letter-spacing: -0.38px;
    color: black;
    text-transform: uppercase;
  }

  .sub-title {
    margin-top: 32px;
    font-family: ${FONTS.roboto};
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: -0.31px;
    color: black;
  }
`

export const NewsFilters = ({
  date,
  setDate,
  onSelectLocation,
  activeLocations,
  onSelectSource,
  activeSources,
  onSelectPeriod,
  activePeriod,
  onCancel,
  stateChanged,
}) => (
  <NewsFiltersBox>
    <div className="title">Парсер новостей</div>

    <div className="sub-title">Дата публикации</div>

    <PublicationDate date={date} setDate={setDate} timeFormat={false} />

    <Options
      onSelect={onSelectPeriod}
      activeOptions={activePeriod}
      options={[
        { name: '24 часа', id: 1 },
        { name: 'Вчера', id: 2 },
        { name: 'Неделя', id: 3 },
        { name: 'Месяц', id: 4 },
      ]}
    />

    <div className="sub-title">География</div>

    <Options onSelect={onSelectLocation} activeOptions={activeLocations} options={locations} />

    <div className="sub-title">Источники</div>

    <Options onSelect={onSelectSource} activeOptions={activeSources} options={sources} />

    {stateChanged && <ControlButtons onCancel={onCancel} />}
  </NewsFiltersBox>
)
