import React from 'react'
import styled from 'styled-components/macro'

const IconAuthorBox = styled.div`
  height: 16px;
  width: 22px;
  display: flex;

  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: none;
  }
`

export const IconAuthor = ({ className = '', ...props }) => (
  <IconAuthorBox className={className} {...props}>
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.916 4.33268C11.916 2.30685 10.2752 0.666016 8.24935 0.666016C6.22352 0.666016 4.58268 2.30685 4.58268
      4.33268C4.58268 6.35852 6.22352 7.99935 8.24935 7.99935C10.2752 7.99935 11.916 6.35852 11.916 4.33268ZM13.7493
      6.16602V7.99935H16.4993V10.7493H18.3327V7.99935H21.0827V6.16602H18.3327V3.41602H16.4993V6.16602H13.7493ZM0.916016
      13.4993V15.3327H15.5827V13.4993C15.5827 11.061 10.6968 9.83268 8.24935 9.83268C5.80185 9.83268 0.916016 11.061 0.916016 13.4993Z"
        fill="white"
      />
    </svg>
  </IconAuthorBox>
)
