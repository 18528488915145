import styled from 'styled-components'

import { FONTS } from 'constants/fonts'

export const RightPanelBox = styled.div`
  z-index: 4;
  position: fixed;
  right: -352px;
  top: 0;
  width: 352px;
  height: 100vh;
  background-color: #e7e8ea;
  padding: 34px 0 180px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: right 0.2s linear;

  .attributes-wrapper {
    width: 292px;
    min-height: calc(100vh - 100px);
    padding-right: 30px;

    @media screen and (max-width: 1649px) {
      height: calc(100vh * 1.45);
    }
  }

  @media screen and (max-width: 1649px) {
    height: calc(100vh * 1.45);
  }

  &.active {
    right: 0;

    .toggle {
      left: -15px;

      .icon-arrow {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .toggle {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 35px;
    left: -30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #897b6a;
    cursor: pointer;

    .icon-arrow {
      svg {
        transform: rotate(90deg);

        .cls-1 {
          fill: white;
        }
      }
    }
  }

  .title {
    font-family: ${FONTS.roboto};
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.92;
    letter-spacing: -0.38px;
    text-align: left;
    color: black;
    text-transform: uppercase;
  }

  .sub-title {
    margin-top: 12px;
    font-family: ${FONTS.roboto};
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    opacity: 0.6;
    text-align: left;
    color: black;
    text-transform: uppercase;
  }

  .divider {
    position: relative;
    left: -30px;
    margin-top: 16px;
    width: calc(100% + 60px);
    height: 1px;
    background-color: #d7d9da;
  }

  .top-buttons {
    margin-top: 28px;
    display: flex;
    flex-wrap: wrap;

    .hot, .flash {
      margin-right: 8px;
      height: 28px;
      width: 121px;
      border-radius: 4px;
      background-color: #d5d4d9;
      display: flex;
      align-items: center;
      padding: 0 2px;
      cursor: pointer;
      color: black;

      &:hover {
        background-color: #ceccd5;
      }

      &.active {
        background-color: #de4e4e;

        .text {
          color: white;
        }

        .icon-fire {
          .cls-3 {
            fill: #de4e4e;
          }

          .cls-4 {
            fill: white;
          }
        }

        .icon-flash {
          .cls-1 {
            fill: #C13B3B;
          }
        }
      }

      .icon-fire {
        margin-right: 4px;

        .cls-3 {
          fill: #d5d4d9;
        }

        .cls-4 {
          fill: black;
        }
      }

      .text {
        margin-top: -2px;
        font-family: ${FONTS.roboto};
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: black;
      }
    }

    .flash {
      .text {
        font-size: 18px;
      }

      &.active {
        .text {
          color: black;
        }
      }
    }

    .add-to-grid {
      height: 28px;
      width: 155px;
      border-radius: 4px;
      background-color: #d5d4d9;
      display: flex;
      align-items: center;
      padding: 0 2px;
      cursor: pointer;
      
      &:hover {
        background-color: #ceccd5;
      }

      &.active {
        background-color: #484b50;

        .text {
          color: white;
        }

        .icon-grid {
          .cls-2 {
            fill: white;
          }
        }
      }

      .icon-grid {
        margin-right: 4px;

        .cls-2 {
          fill: black;
        }
      }

      .text {
        margin-top: -2px;
        font-family: ${FONTS.roboto};
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: black;
      }
    }

    .access-by-link {
      margin-top: 8px;
      width: 100%;
      height: 28px;
      border-radius: 4px;
      background-color: #d5d4d9;
      display: flex;
      align-items: center;
      padding: 0 2px;
      cursor: pointer;

      &:hover {
        background-color: #ceccd5;
      }

      &.active {
        background-color: #484b50;

        .text {
          color: white;
        }

        .icon-link {
          .cls-2 {
            fill: white;
          }
        }
      }

      .icon-link {
        transform: rotate(90deg);

        .cls-2 {
          fill: black;
        }
      }

      .text {
        margin-top: -2px;
        font-family: ${FONTS.roboto};
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: black;
      }
    }
  }

  .bottom-buttons {
    margin-top: auto;

    .link-to-publication {
      font-family: ${FONTS.roboto};
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
`
