import React, { useState } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { withEditorContainer, Save } from './EditorContainer'

const EmbeddedEditorBox = styled.div`
  width: 100%;
  height: 100%;

  .embedded-title {
    font-family: ${FONTS.lato};
    font-size: 22px;
  }

  .html-textarea {
    width: 90%;
    min-height: 36px;
    box-sizing: border-box;
    margin-top: 8px;
    display: block;
    outline: none;
    border-radius: 4px;
    border: solid 2px #d5d5d8;
    background-color: white;
    font-family: ${FONTS.lato};
    font-size: 14px;

    &::placeholder {
      font-family: ${FONTS.lato};
      font-size: 14px;
      opacity: 0.7;
    }
  }

  .html {
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0.7;
    font-family: ${FONTS.lato};
    font-size: 18px;
  }
`

const EmbeddedEditor = ({
  active, onSave, deactivate, initialState,
}) => {
  const [text, setText] = useState(initialState)

  const _onSave = () => {
    onSave({
      html: text,
    })

    deactivate()
  }

  return (
    <>
      <EmbeddedEditorBox className={classNames({ active })}>
        {active && <div className="embedded-title">Embed HTML</div>}

        {active ? (
          <div className="input-wrapper">
            <textarea
              className="html-textarea"
              placeholder="<embed src=“images/mouse.swf” width=“400” height=“300” type=“application/x-shockwave-flas pluginspage=“http://www.macromedia.com/go/getflashplayer”>"
              onChange={(e) => setText(e.target.value)}
              value={text}
            />
          </div>
        ) : (
          <div className="html">
            {
            text || '<embed src=“images/mouse.swf” width=“400” height=“300” type=“application/x-shockwave-flas pluginspage=“http://www.macromedia.com/go/getflashplayer”>'
}
          </div>
        )}
      </EmbeddedEditorBox>

      <Save onSave={_onSave} active={active} />
    </>
  )
}

const enhanced = withEditorContainer(EmbeddedEditor)

export { enhanced as EmbeddedEditor }
